export enum CustodyCardRowsEnum {
  OpeningBalance = 'openingBalance',
  NetTransactions = 'netTransactions',
  ClosingBalance = 'closingBalance',
}
export const CustodyCardRowsLabels: Record<CustodyCardRowsEnum, string> = {
  [CustodyCardRowsEnum.OpeningBalance]: 'Opening balance',
  [CustodyCardRowsEnum.NetTransactions]: 'Net Transactions',
  [CustodyCardRowsEnum.ClosingBalance]: 'Closing Balance',
};
