import { Stack, Typography } from '@mui/material';

interface TooltipContentProps {
  title?: string;
  description: string;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ title, description }) => {
  // Split description by line breaks and map each line to its own Typography component
  const descriptionLines = description.split('\n');

  return (
    <Stack gap={1}>
      {title && (
        <Typography variant="labelMedium" color="#fff">
          {title}
        </Typography>
      )}
      {descriptionLines.map((line, index) => (
        <Typography key={index} variant="labelSmall" color="#fff">
          {line}
        </Typography>
      ))}
    </Stack>
  );
};

export default TooltipContent;
