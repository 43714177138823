import { ParamsType } from 'hooks/useUrlParams';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { PaginationProps, PaginationResponse } from 'utils/types';

export const useBackendPagination = (
  pagination: PaginationResponse | null | undefined,
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>
): PaginationProps => {
  return {
    itemsPerPage: pagination?.pageSize ? Number(pagination.pageSize) : DEFAULT_ITEMS_PER_PAGE,
    page: pagination?.page ?? DEFAULT_PAGE,
    onChangeItemsPerPage: (itemsPerPage: number) =>
      setUrlParams((params) => ({ ...params, pageSize: itemsPerPage, page: DEFAULT_PAGE })),
    onChangePage: (page: number) => setUrlParams((params) => ({ ...params, page })),
    totalItems: pagination?.total ?? DEFAULT_PAGE,
  };
};
