import InputComponent from 'components/Input';
import { Box } from '@mui/material';
import { ActionButtonsWrapper } from './ActionButtonsWrapper';
import { useState } from 'react';

interface InputWithCustomButtonsProps {
  value: string | number;
  label?: string;
  onConfirm: (value?: string | number) => void;
  onDecline: () => void;
}

export const InputWithCustomButtons = ({
  value,
  label = '',
  onConfirm,
  onDecline,
  ...props
}: InputWithCustomButtonsProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        height: '100%',
        alignItems: 'center',
      }}
      {...props}
    >
      <InputComponent
        size="small"
        value={currentValue}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onConfirm(currentValue);
          } else if (event.key === 'Escape') {
            onDecline();
          }
        }}
        onChange={({ target }) => {
          setCurrentValue(target.value);
        }}
        type="number"
      />
      <ActionButtonsWrapper onConfirm={() => onConfirm(currentValue)} onDecline={onDecline} />
    </Box>
  );
};
