import { ReactNode } from 'react';
import { MuiStyledDialog, MuiStyledDialogContent } from './Modal.styles';
import { ModalProps } from 'styled-react-modal';

export interface CustomModalProps extends Omit<ModalProps, 'isOpen'> {
  children: ReactNode;
  onCloseModal?: () => void;
  customwidth?: string;
  open: boolean;
  sx?: { [key: string]: unknown };
  sxContent?: { [key: string]: unknown };
}

export const CustomModal = ({ children, onCloseModal, open, ...props }: CustomModalProps) => {
  return (
    <MuiStyledDialog onClose={onCloseModal} open={open} customwidth={props.customwidth} {...props}>
      <MuiStyledDialogContent sx={{ ...props.sxContent }}>{children}</MuiStyledDialogContent>
    </MuiStyledDialog>
  );
};
