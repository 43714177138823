import { TableHeaderColumn } from 'components/Table/Table.types';

export const NAVReviewTableColumnsConfig: TableHeaderColumn[] = [
  { label: 'Product', propName: 'product.name', propType: 'string', width: '15%' },
  { label: 'Constituent', propName: 'ticker', propType: 'string', width: '20%' },
  {
    label: 'Onyx',
    propName: 'onyxNav',
    propType: 'string',
    sort: false,
    width: '18%',
  },
  {
    label: 'Fund Accountant',
    propName: 'accountantNav',
    propType: 'date',
    sort: false,
    width: '18%',
  },
  { label: 'Custody', propName: 'custody', propType: 'string', width: '18%', sort: false },
  { label: 'State', propName: 'status', propType: 'string', width: '15%' },
];

export const NAVReviewTableColumnsConfigFA = [
  { label: 'Product', propName: 'product.name', propType: 'string', width: '15%' },
  { label: 'Constituent', propName: 'ticker', propType: 'string', width: '20%' },
  {
    label: 'Fund Accountant',
    propName: 'accountantNav',
    propType: 'date',
    sort: false,
    width: '18%',
  },
  { label: 'State', propName: 'status', propType: 'string', width: '15%' },
];
