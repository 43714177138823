import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import ProductIcon from 'components/ProductIcon';
import { IconButton, Stack } from '@mui/material';

type RegisterTableProps = {
  register: SecuritiesRegister;
  editAction: () => void;
  tableColumns: TableHeaderColumn[];
  onDonwloadPdf: (singleRegister: SecuritiesRegister) => void;
  onDonwloadXLS: (singleRegister: SecuritiesRegister) => void;
};

export const RegisterTableRow = ({
  register,
  editAction,
  onDonwloadPdf,
  onDonwloadXLS,
}: RegisterTableProps) => {
  return (
    <TableRow
      data-qa-id={`${register.product.name}-row`}
      key={register.product._id}
      onClick={editAction}
    >
      <TableCell data-qa-id={`${register.product.name}-ticker`}>
        <Stack direction="row" spacing={2}>
          <ProductIcon iconUrl={register.product.fundIcon} ticker={register.product.ticker} />
          <span>{register.product.ticker}</span>
        </Stack>
      </TableCell>
      <TableCell data-qa-id={`${register.product.name}-totalUnitsOutstanding`} align="right">
        {register.product.totalUnitsOutstanding}
      </TableCell>
      <TableCell data-qa-id={`${register.product.name}-downloadPDF`} align="right">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDonwloadPdf(register);
          }}
        >
          <DownloadIcon />
        </IconButton>
      </TableCell>
      <TableCell data-qa-id={`${register.product.name}-downloadXSL`} align="right">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            onDonwloadXLS(register);
          }}
        >
          <DownloadIcon />
        </IconButton>
      </TableCell>
      <TableCell data-qa-id="actionBtn" />
    </TableRow>
  );
};
