import useAppDispatch from 'hooks/useAppDispatch';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { getNavReviewForValuationDate } from 'utils/api/portfolio-composition';
import { getNavReviewItemQuery } from 'utils/constants/reactQueries';

export const useNAVReviewForValuationDate = (
  portfolioId: string,
  constituentTicker: string,
  valuationdate: string
) => {
  const dispatch = useAppDispatch();
  return useQuery(
    [getNavReviewItemQuery, portfolioId, valuationdate],
    async () => {
      try {
        const res = await getNavReviewForValuationDate(
          portfolioId,
          constituentTicker,
          valuationdate
        );
        return res;
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
      }
    },
    {
      enabled: Boolean(portfolioId && valuationdate && constituentTicker),
    }
  );
};
