import { useEffect, useState } from 'react';
import Search from 'components/Search';
import { ParamsType } from 'hooks/useUrlParams';
import { CONTACT_LIST_TYPE, CONTACT_LIST_TYPE_LABEL } from 'utils/types/contacts';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { CONTACTS_MANAGEMENT_TAB_VALUE } from 'utils/constants/contacts';
import { Box } from '@mui/material';
import MultiSelect from 'components/MultiSelect/MultiSelect';

type Props = {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
};

const getTableTitle = (tab: CONTACTS_MANAGEMENT_TAB_VALUE | string) => {
  if (tab === CONTACTS_MANAGEMENT_TAB_VALUE.LISTS) return 'Distribution Lists';
  return 'Contacts';
};

const ContactsListToolbar = ({ setUrlParams, urlParams }: Props) => {
  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
    }));
  }, [search, setUrlParams]);

  return (
    <Box sx={{ width: '100%', gap: '8px', '.table-title-wrapper': { borderBottom: 0 } }}>
      <TableTitleWithActions
        title={getTableTitle(String(urlParams?.tab) ?? '')}
        actions={[
          <Box display="flex" gap={1}>
            <Search
              variant="short"
              placeholder="Search"
              value={searchInputValue}
              onChange={(event) => setSearchInputValue(event.target.value)}
              onBlur={() => setSearch(searchInputValue)}
              onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
              onClear={() => setSearch('')}
              style={{ width: '240px' }}
            />
            <MultiSelect
              options={[
                { label: CONTACT_LIST_TYPE_LABEL.ORDER, value: CONTACT_LIST_TYPE.ORDER },
                { label: CONTACT_LIST_TYPE_LABEL.PRODUCT, value: CONTACT_LIST_TYPE.PRODUCT },
                {
                  label: CONTACT_LIST_TYPE_LABEL.NON_PRODUCT,
                  value: CONTACT_LIST_TYPE.NON_PRODUCT,
                },
                { label: CONTACT_LIST_TYPE_LABEL.PCF, value: CONTACT_LIST_TYPE.PCF },
                {
                  label: CONTACT_LIST_TYPE_LABEL.CONSOLIDATED_PCFS,
                  value: CONTACT_LIST_TYPE.CONSOLIDATED_PCFS,
                },
                {
                  label: CONTACT_LIST_TYPE_LABEL.BLOOMBERG_HOLDINGS_FILE,
                  value: CONTACT_LIST_TYPE.BLOOMBERG_HOLDINGS_FILE,
                },
                {
                  label: CONTACT_LIST_TYPE_LABEL.BLOOMBERG_NAV_FILE,
                  value: CONTACT_LIST_TYPE.BLOOMBERG_NAV_FILE,
                },
                {
                  label: CONTACT_LIST_TYPE_LABEL.FINAL_TERMS_AND_ISS,
                  value: CONTACT_LIST_TYPE.FINAL_TERMS_AND_ISS,
                },
              ]}
              placeholder="All Types"
              onChange={(_, values) =>
                setUrlParams((prevState) => ({ ...prevState, type: (values as string[]) ?? [] }))
              }
              value={urlParams.type as string[]}
              size="small"
              minWidth={152}
              dropdownVariant="small"
              alignment="right"
              renderTagsAsString
              showMaxValues={2}
            />
          </Box>,
        ]}
      />
    </Box>
  );
};

export default ContactsListToolbar;
