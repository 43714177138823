import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import Pagination from 'components/Pagination';
import ProductIcon from 'components/ProductIcon';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import useAppModal from 'hooks/useAppModal';
import { Alert, Box, Typography } from '@mui/material';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { DateTime } from 'utils/datetime';
import { FEES_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { FeesDetailsPageHeader } from './components/FeesDetailsPageHeader';
import { FeesDetailsTable } from 'shared/Tables/Fees/FeesDetailsTable/FeesDetailsTable';
import { ParamsType } from 'hooks/useUrlParams';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { TransactionType } from 'utils/types/nav';
import { downloadFeesCsv } from 'utils/fees/downloadFeesCsv';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useFeesDetailsQuery } from 'pages/Fees/hooks/useFeesDetailsQuery';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';

export const FeesDetailsPage = () => {
  const params = useParams();
  const openModal = useAppModal();
  const permissions = useUserPermissions();

  const [urlParams, setUrlParams] = useState<ParamsType>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
    type: null,
  });

  const {
    data: fee,
    refetch,
    isLoading,
  } = useFeesDetailsQuery(params.productId!, params.constituentTicker!, urlParams);

  const pagination = useBackendPagination(
    {
      page: fee?.transactions.page ?? DEFAULT_PAGE,
      pageSize: fee?.transactions.pageSize ?? 10,
      total: fee?.transactions.total ?? 0,
    },
    setUrlParams
  );

  const openFeesDetailsModal = (type: MODAL_ACTIONS) => {
    openModal(
      {
        modalName: FEES_MODAL,
        modalData: {
          type: type,
          data: fee ? [fee] : [],
        },
      },
      {
        onCloseModalAction: () => {
          refetch();
        },
      }
    );
  };

  const ActionButton = (
    <Button
      variant="primary"
      onClick={() => openFeesDetailsModal(MODAL_ACTIONS.CUSTOM)}
      disabled={fee?.totalUnrealizedAccruedFee === '0' || !permissions?.canWithdrawManagementFee}
    >
      Withdraw
    </Button>
  );

  const titleAreaContent = fee ? (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <ProductIcon
        className="product-icon"
        iconUrl={fee?.product.fundIcon}
        ticker={fee?.product.ticker}
        size="40px"
      />
      <PageTitle>
        {fee?.product.ticker} / {fee?.constituentAsset.ticker} Fees
      </PageTitle>
    </Box>
  ) : null;

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <TitleArea title={titleAreaContent} showBackButton={true} actionButton={ActionButton} />
      <>
        <FeesDetailsPageHeader
          fee={fee}
          onTypeChange={(type) => setUrlParams((prev) => ({ ...prev, type: type as string }))}
          selectedType={urlParams.type as TransactionType}
          downloadCSV={() => downloadFeesCsv(fee)}
        />
        <Card
          headerDivider={false}
          footerDivider={false}
          header={
            <TableTitleWithActions
              title={
                <Box display={'flex'} gap="16px" alignContent={'center'}>
                  <Typography variant="subheadingMedium" alignContent={'center'}>
                    Unrealized Accrued Fees
                  </Typography>
                  {fee?.lastWithdrawTransaction && (
                    <Alert severity="info">{`Last withdrawal amount  ${
                      fee.lastWithdrawTransaction?.amount
                    } at ${new DateTime(
                      fee.lastWithdrawTransaction?.createdAt
                    ).toDateString()}`}</Alert>
                  )}
                </Box>
              }
              actions={[
                <Typography variant="subheadingMedium" alignContent={'center'}>
                  {fee?.totalUnrealizedAccruedFee} {fee?.constituentAsset.ticker}
                </Typography>,
              ]}
            />
          }
          body={
            <FeesDetailsTable fees={fee?.transactions.data ?? []} setUrlParams={setUrlParams} />
          }
          footer={<Pagination {...pagination} />}
        />
      </>
    </>
  );
};
