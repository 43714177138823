import { TextField } from '@mui/material';
import TableCell from 'components/Table/TableCell';
import { styled as MuiStyled } from '@mui/material/styles';

export const StyledTextField = MuiStyled(TextField)`
  background-color: ${({ theme }) => `${theme.palette.tertiary.lighter}`};
  border: 2px #9dc3fb solid;
  & input[type=number]::-webkit-outer-spin-button,
  & input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  & input[type=number] {
    -moz-appearance: textfield;
  }
  .MuiInputBase-input {
    padding: 11.5px 14px;
  }
`;

export const StyledInputTableCell = MuiStyled(TableCell)`
  padding: 0px;
`;

export const StyledCalculating = MuiStyled('span')`
  padding-inline: 16px;
`;
