import ContactsTableToolbar from 'pages/Admin/ContactsPage/components/ContactsToolbar';
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  UNLIMITED_ITEMS_PER_PAGE,
} from 'shared/Tables/table.utils';
import useAppModal from 'hooks/useAppModal';
import { COMPANY_CONTACT_TYPE, Contact } from 'utils/types/contacts';
import { CONTACTS_TAB_VALUE } from 'utils/constants/contacts';
import { CONTACT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ParamsType } from 'hooks/useUrlParams';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Button from 'components/Button';
import { YourContactsTable } from 'shared/Tables/ContactsTable/components/YourContactsTable/YourContactsTable';
import { fetchAllContacts } from 'utils/api/contacts';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useQuery } from 'react-query';
import { useRequestCompaniesMappedQuery } from 'pages/Admin/ContactsPage/hooks/api/useRequestCompaniesMappedQuery';
import { useContactsListsQuery } from 'pages/Admin/ContactsPage/hooks/api/useContactsListsQuery';
import { TitleArea } from 'components/TitleArea/TitleArea';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { Card } from 'components/Card/Card';

interface YourContactsTabProps {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

export const YourContactsTab = ({ urlParams, setUrlParams }: YourContactsTabProps) => {
  const openModal = useAppModal();
  const yourContactsQueryProps = useQuery(['yourContacts', urlParams], () =>
    fetchAllContacts().then(({ data }) => data)
  );

  const { data: partnerOptions, isLoading: isLoadingPartners } = useRequestCompaniesMappedQuery(
    { page: DEFAULT_PAGE, pageSize: UNLIMITED_ITEMS_PER_PAGE },
    urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER
  );

  const { data: contactsListData, isLoading: isLoadingLists } = useContactsListsQuery(
    {
      pageSize: UNLIMITED_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
    },
    true
  );
  const contactsList = contactsListData?.data;
  const listsOptions = contactsList?.map((list) => ({ label: list.name, value: list._id })) ?? [];

  const ContactsToolbar = (
    <ContactsTableToolbar
      setUrlParams={setUrlParams}
      urlParams={urlParams}
      partnerOptions={partnerOptions}
      listsOptions={listsOptions}
      loading={isLoadingPartners || isLoadingLists}
    />
  );

  const pagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
      total: yourContactsQueryProps.data?.contacts.length ?? 0,
    },
    setUrlParams
  );

  const actions = yourContactsQueryProps.data?._actions;

  const openContactModal = (
    type: MODAL_ACTIONS,
    apiUri: string,
    contact?: Contact,
    contactTypeFlow?: COMPANY_CONTACT_TYPE
  ) => {
    openModal(
      {
        modalName: CONTACT_MODAL,
        modalData: {
          data: {
            contact,
            contactTypeFlow: contactTypeFlow ?? COMPANY_CONTACT_TYPE.UNAFFILIATED,
            apiUri: apiUri,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: () => yourContactsQueryProps.refetch(),
      }
    );
  };

  const createContactButton = (
    <Button
      type="button"
      data-qa-id="newContactButton"
      size="medium"
      key="toolbar-button"
      disabled={!Boolean(actions?.create)}
      onClick={() => openContactModal(MODAL_ACTIONS.CREATE, actions?.create?.uri ?? '')}
    >
      <span>New Contact</span>
      <PlusIcon />
    </Button>
  );

  return (
    <>
      <TitleArea title={'Contacts'} actionButton={createContactButton} />
      <Card
        header={<TableTitleWithActions title="Contacts" actions={[ContactsToolbar]} />}
        headerDivider={false}
        body={
          <YourContactsTable
            setUrlParams={setUrlParams}
            urlParams={urlParams}
            editAction={(contact) =>
              openContactModal(MODAL_ACTIONS.EDIT, contact?._actions?.update?.uri ?? '', contact)
            }
            deleteAction={(contact) =>
              openContactModal(MODAL_ACTIONS.DELETE, contact?._actions?.delete?.uri ?? '', contact)
            }
            contacts={yourContactsQueryProps.data?.contacts ?? []}
            isLoading={yourContactsQueryProps.isLoading}
            pagination={pagination}
          />
        }
      />
    </>
  );
};
