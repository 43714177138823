import { ReactComponent as ChevronLeft } from 'assets/chevron-left.svg';
import { useMemo } from 'react';
import { To, useLocation, useNavigate, Location } from 'react-router-dom';
import { privateRoutesUrls, privateWeakRoutesUrls, publicRoutesUrls } from 'router/constants';
import { useHistoryContext } from 'utils/providers/historyProvider';
import { Button } from 'components/Button/Button';

const findMatchingSegment = (pathname: string): To | undefined => {
  return [
    ...Object.values(privateRoutesUrls.dashboardRoutes),
    ...Object.values(publicRoutesUrls),
    ...Object.values(privateWeakRoutesUrls),
  ]
    .filter((segment) => segment !== '/')
    .find((segment) => pathname.startsWith(segment));
};

const findMatchingSearchParams = (pathname: string, history: Location[]): To => {
  const matchedSegment = findMatchingSegment(pathname);
  const historyEntry = [...history]
    .reverse()
    .find((location) => location.pathname === matchedSegment);

  if (historyEntry) {
    return `${historyEntry.pathname}${historyEntry.search || ''}`;
  }

  return matchedSegment || privateRoutesUrls.dashboardRoutes.rootPath;
};

export const BackButton = ({ ...props }) => {
  const navigate = useNavigate();
  const history = useHistoryContext();
  const location = useLocation();
  const previousRoute = location.state?.previousRoute;

  const toUrl: To = useMemo(
    () => previousRoute ?? findMatchingSearchParams(location.pathname, history),
    [history, location.pathname]
  );

  return (
    <Button variant="secondary" emphasis="medium" onClick={() => navigate(toUrl)} {...props}>
      <ChevronLeft style={{ fill: '#fff' }} />
    </Button>
  );
};
