import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { NAVReviewItemWithProduct } from 'utils/types/nav';
import { ParamsType } from 'hooks/useUrlParams';
import { SortingOrder } from 'utils/types';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { format } from 'date-fns';
import { useUserPermissions } from 'store/user/selectors';
import { NAVTableRow } from './NAVTableRow';
import { NAVReviewTableColumnsConfig, NAVReviewTableColumnsConfigFA } from './tableColumnsConfig';

interface NAVTableProps {
  loading: boolean;
  queryParams: ParamsType;
  setQueryParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  navReviewItems: NAVReviewItemWithProduct[];
  editAction?: (partner: NAVReviewItemWithProduct) => void;
}

function sortItems(
  navReviewItems: NAVReviewItemWithProduct[],
  sort: NonNullable<ParamsType['sort']>
) {
  const sortArray = typeof sort === 'string' ? [sort] : sort;
  const sortOptions = sortArray.reduce((sortFieldsAndOrders, sortOption) => {
    const [field, sortOrder] = sortOption.split(':');
    var fieldName: string;
    if (field === 'product.name') {
      fieldName = 'product.ticker';
    } else if (field === 'difference') {
      fieldName = 'rawDifference';
    } else {
      fieldName = field;
    }
    sortFieldsAndOrders[fieldName] = sortOrder as SortingOrder;

    return sortFieldsAndOrders;
  }, {} as Record<string, SortingOrder>);

  return orderBy(navReviewItems, Object.keys(sortOptions), Object.values(sortOptions));
}

export const NAVTable = ({ loading, navReviewItems, editAction, queryParams }: NAVTableProps) => {
  const user = useUserPermissions();
  const [sort, setSort] = useState<ParamsType['sort']>([]);
  const sortedItems = !Boolean(sort) ? navReviewItems : sortItems(navReviewItems, sort!);
  return (
    <Table noPadding>
      <TableHeaderWithMultiSort
        defaultSortedColumns={sort || []}
        columns={user?.isFundAccount ? NAVReviewTableColumnsConfigFA : NAVReviewTableColumnsConfig}
        onColumnSort={setSort}
        columnAlignment={{ accountantNav: 'right', onyxNav: 'right', custody: 'right' }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loading)}
        hasContent={!isEmpty(sortedItems)}
        noContentLabel={
          queryParams.valuationDate === format(new Date(), DEFAULT_DATE_FORMAT) ? (
            <>
              No NAV files have been uploaded yet for this day. <br />
              Please check back later.
            </>
          ) : (
            <>There is no content available for this day.</>
          )
        }
      >
        {sortedItems?.map((navReviewItem) => (
          <NAVTableRow
            key={navReviewItem._id}
            navReviewItem={navReviewItem}
            editAction={() => {
              if (editAction) editAction(navReviewItem);
            }}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};
