import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { RebalanceDetailsTableRow } from './RebalanceDetailsTableRow';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { isEmpty } from 'lodash';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { RebalanceDetailsTotalsTableRow } from './RebalanceDetailsTotalsTableRow';
import { renderSaving } from 'pages/Instruments/components/SaveProgress';
import { StyledCalculating } from './EditableTableCell/EditableTableCell.styles';
import { RebalanceTimeTooltip } from './components/RebalanceTimeTooltip/RebalanceTimeTooltip';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import {
  IndexVsRealTimePriceSwitch,
  StyledTooltipTypography,
} from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage.styles';
import { ReactComponent as InformationIcon } from 'assets/information.svg';
import Tooltip from 'components/Tooltip';

interface RebalanceDetailsTableProps {
  rebalance?: RebalanceWithDetails;
  initialRebalance?: RebalanceWithDetails;
  isLoading?: boolean;
  editRowData: (data: RebalanceDetails) => void;
  isCalculating?: boolean;
}

const renderCalculating = (isCalculating?: boolean) => {
  return isCalculating ? <StyledCalculating>{renderSaving('')}</StyledCalculating> : null;
};

export const RebalanceDetailsTable = ({
  isLoading = false,
  editRowData,
  rebalance,
  initialRebalance,
  isCalculating,
}: RebalanceDetailsTableProps) => {
  const [useIndexPrices, setUseIndexPrices] = useState(true);

  const handlePriceToggle = () => {
    setUseIndexPrices((prev) => !prev);
  };

  const toolTipContent = (
    <RebalanceTimeTooltip rebalance={rebalance} useIndexPrices={useIndexPrices} />
  );

  const isRealTimeDataAvailable = Boolean(
    rebalance?.constituentsWithRealTimeData &&
      rebalance?.realTimePriceAt &&
      rebalance?.realTimePriceProvider
  );

  const switchComponent = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="labelMedium">Real-time Prices</Typography>
      {isRealTimeDataAvailable ? (
        <IndexVsRealTimePriceSwitch
          checked={useIndexPrices}
          onChange={handlePriceToggle}
          inputProps={{ 'aria-label': 'price source switch' }}
        />
      ) : (
        <Tooltip
          title={
            <>
              <StyledTooltipTypography>
                Since {rebalance?.product?.ticker} prices are quoted in{' '}
                {rebalance?.product?.quoteCurrency} and this data is unavailable from CryptoCompare,
                it is disabled
              </StyledTooltipTypography>
            </>
          }
          placement="top"
          arrow
          bgColor="black"
        >
          <Box>
            <IndexVsRealTimePriceSwitch
              checked={useIndexPrices}
              onChange={handlePriceToggle}
              inputProps={{ 'aria-label': 'price source switch' }}
              disabled
            />
          </Box>
        </Tooltip>
      )}
      <Typography variant="labelMedium">Index Prices</Typography>
      <Tooltip
        title={
          <>
            <StyledTooltipTypography>
              Index provider prices are updated at 5pm daily
            </StyledTooltipTypography>
            <StyledTooltipTypography>
              Intraday prices from Crypto Compare are updated hourly
            </StyledTooltipTypography>
          </>
        }
        placement="top"
        arrow
        bgColor="black"
      >
        <Box sx={{ height: 'inherit', width: '12px', marginLeft: 0.5 }}>
          <InformationIcon />
        </Box>
      </Tooltip>
    </Box>
  );

  const RebalanceDetailsTableConfig = [
    { label: 'Asset', propName: 'ticker', propType: 'string', minWidth: '140px', sort: false },
    {
      label: 'Balance (Pre)',
      propName: 'preBalance',
      propType: 'string',
      minWidth: '140px',
      sort: false,
    },
    {
      label: 'Price',
      propName: 'price',
      propType: 'string',
      minWidth: '180px',
      sort: false,
      tooltip: toolTipContent,
    },
    { label: 'Value (Pre)', propName: 'preValue', propType: 'date', width: '18%', sort: false },
    {
      label: ' Weight (Pre)',
      propName: 'preWeight',
      propType: 'string',
      width: '10%',
      sort: false,
    },
    {
      label: 'Target Weights',
      propName: 'targetWeight',
      propType: 'date',
      minWidth: '180px',
      sort: false,
    },
    {
      label: 'Difference (Pre)',
      propName: 'preDifference',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Gross Trade (Cash)',
      propName: 'tradeCash',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Gross Trade (Coin)',
      propName: 'tradeCoin',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Balance (Post)',
      propName: 'postBalance',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    { label: 'Value (Post)', propName: 'postValue', propType: 'date', width: '18%', sort: false },
    {
      label: 'Weight (Post)',
      propName: 'postWeight',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Difference (Post)',
      propName: 'postDifference',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    { label: 'Action', propName: 'action', propType: 'date', width: '18%', sort: false },
    {
      label: 'Net Trade (Coin)',
      propName: 'actualTrade',
      propType: 'string',
      width: '18%',
      sort: false,
    },
    {
      label: 'Net Trade (Cash)',
      propName: 'actualTradeCash',
      propType: 'string',
      width: '18%',
      sort: false,
    },
    {
      label: 'Act Balance (Post)',
      propName: 'actualBalance',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Act Value (Post)',
      propName: 'actualPostValue',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Act Weight (Post)',
      propName: 'actualPostWeight',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Act Difference (Post)',
      propName: 'actualPostWeightDifference',
      propType: 'date',
      width: '18%',
      sort: false,
    },
    {
      label: 'Currency',
      propName: 'rebalance.product.quoteCurrency',
      propType: 'string',
      width: '18%',
      sort: false,
    },
    {
      label: 'Fee (bps) ',
      minWidth: '180px',
      propName: 'fee',
      propType: 'string',
      sort: false,
    },
  ];

  return (
    <Table
      titleComponent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <>Rebalance Details {renderCalculating(isCalculating)}</>
          {switchComponent}
        </Box>
      }
      noPadding
      titleComponentWidth={'100%'}
    >
      <TableHeaderWithMultiSort
        showActionsCell={false}
        columns={RebalanceDetailsTableConfig}
        columnAlignment={{
          fee: 'right',
          price: 'right',
          balancePre: 'right',
          targetWeight: 'right',
        }}
        toolTipBgColor="white"
      />
      <TableBodyWithStates
        loadingData={Boolean(isLoading)}
        hasContent={!isEmpty(rebalance?.details)}
        noContentLabel="There is no Rebalance details at the moment."
      >
        {useIndexPrices &&
          rebalance?.details.map((details, index) => (
            <RebalanceDetailsTableRow
              key={index}
              rebalanceDetails={details}
              updateRow={editRowData}
              initialRebalanceDetails={initialRebalance?.details[index]}
              rebalance={rebalance}
            />
          ))}
        {!useIndexPrices &&
          rebalance?.detailsWithRealTimeData.map((details, index) => (
            <RebalanceDetailsTableRow
              key={index}
              rebalanceDetails={details}
              updateRow={editRowData}
              initialRebalanceDetails={initialRebalance?.details[index]}
              rebalance={rebalance}
            />
          ))}
        {useIndexPrices && rebalance?.detailsSum && (
          <RebalanceDetailsTotalsTableRow
            rebalanceDetails={rebalance?.detailsSum}
            rebalance={rebalance}
          />
        )}
        {!useIndexPrices && rebalance?.detailsSumWithRealTimeData && (
          <RebalanceDetailsTotalsTableRow
            rebalanceDetails={rebalance?.detailsSumWithRealTimeData}
            rebalance={rebalance}
          />
        )}
      </TableBodyWithStates>
    </Table>
  );
};
