import { keyBy } from 'lodash';
import { PortfolioComposition } from 'utils/types/pcfs';
import { renderValueOrDash } from 'utils/formatting';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { PCFDetailsListColumnConfig } from 'pages/Ledger/PCF/PCFDetails/PCFDetailsAP/tableColumnsConfig';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { ProductConstituentAsset } from 'utils/types';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
interface PCFCompositionProps {
  pcf?: PortfolioComposition & { constituentAssets?: ProductConstituentAsset[] };
  isLoading?: boolean;
}

function PCFComposition({ pcf, isLoading }: PCFCompositionProps) {
  const constituents = Boolean(pcf?.official?.constituents)
    ? pcf?.official?.constituents
    : pcf?.synthetic?.constituents;

  const tickers = constituents?.map((constituent) => constituent.ticker);
  const productConstituents = keyBy(pcf?.constituentAssets, 'pairAsset');

  return pcf?.official?.constituents.length ? (
    <Card
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            Portfolio Composition
          </Typography>
        </Box>
      }
      headerDivider={false}
      body={tickers?.map((ticker) => {
        const productConstituent = productConstituents[ticker];
        return (
          <Table
            title={productConstituent?.isLeveraged ? `${productConstituent?.pairAsset}` : ticker}
            noPadding
          >
            <TableHeaderWithMultiSort columns={PCFDetailsListColumnConfig} />
            <TableBodyWithStates
              loadingData={isLoading}
              hasContent={Boolean(pcf)}
              noContentLabel="No PCF data."
            >
              <PCFCompositionRow pcf={pcf} ticker={ticker} />
            </TableBodyWithStates>
          </Table>
        );
      })}
    />
  ) : null;
}

export default PCFComposition;

const PCFCompositionRow = ({ pcf, ticker }: { pcf?: PortfolioComposition; ticker: string }) => {
  const officialConstituents = keyBy(pcf?.official?.constituents, 'ticker');
  const syntheticConstituents = keyBy(pcf?.synthetic?.constituents, 'ticker');

  return (
    <>
      <TableRow>
        <TableCell>Price ( {pcf?.product.baseCurrency} )</TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'price' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(syntheticConstituents[ticker]?.priceInBaseCurrency)}
        </TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'price' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(officialConstituents[ticker]?.price)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Weight</TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'weight' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(syntheticConstituents[ticker]?.weight)}
        </TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'weight' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(officialConstituents[ticker]?.weight)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Deliverables per Creation Unit</TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'amountPerCreationUnit' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(syntheticConstituents[ticker]?.projectedNetAssetValuePerCreationUnit)}
        </TableCell>
        <TableCell
          error={
            pcf?.errors?.constituent?.errors?.find(
              (error) => error.property === 'amountPerCreationUnit' && error.ticker === ticker
            )
              ? true
              : false
          }
        >
          {renderValueOrDash(officialConstituents[ticker]?.amountPerCreationUnit)}
        </TableCell>
      </TableRow>

      {officialConstituents[ticker]?.exposure && (
        <>
          <TableRow>
            <TableCell>{officialConstituents[ticker]?.exposure?.ticker} Exposure</TableCell>
            <TableCell>{renderValueOrDash(false)}</TableCell>
            <TableCell>
              {renderValueOrDash(officialConstituents[ticker]?.exposure?.exposure)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{officialConstituents[ticker]?.exposure?.ticker} Price</TableCell>
            <TableCell>{renderValueOrDash(false)}</TableCell>
            <TableCell>
              {renderValueOrDash(officialConstituents[ticker]?.exposure?.price)}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
