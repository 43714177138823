import { THREE, millisecondsInOneHour, millisecondsInOneMinute } from 'utils/constants/numbers';
export const environment = process.env.REACT_APP_ENVIRONMENT;
export const isProductionEnvironment = environment === 'prod';
export const v1CutoffCompatibility = Boolean(
  process.env.REACT_APP_V1_CUTOFF_COMPATIBILITY === 'true' ||
    !process.env.REACT_APP_V1_CUTOFF_COMPATIBILITY
);

export const idleTime = process.env.REACT_APP_IDLE_TIMEOUT_MINUTES
  ? process.env.REACT_APP_IDLE_TIMEOUT_MINUTES * millisecondsInOneMinute
  : THREE * millisecondsInOneHour;

// Page Flags
export const hidePCF = Boolean(
  process.env.REACT_APP_HIDE_PCF === 'true' || !process.env.REACT_APP_HIDE_PCF
);

export const hideRebalance = Boolean(
  process.env.REACT_APP_HIDE_REBALANCE === 'true' || !process.env.REACT_APP_HIDE_REBALANCE
);

export const hideNAV = Boolean(
  process.env.REACT_APP_HIDE_NAV === 'true' || !process.env.REACT_APP_HIDE_NAV
);
export const hideOrders = Boolean(
  process.env.REACT_APP_HIDE_ORDERS === 'true' || !process.env.REACT_APP_HIDE_ORDERS
);
export const hideRegister = Boolean(
  process.env.REACT_APP_HIDE_REGISTER === 'true' || !process.env.REACT_APP_HIDE_REGISTER
);
export const hideAdminContacts = Boolean(
  process.env.REACT_APP_HIDE_ADMIN_CONTACTS === 'true' || !process.env.REACT_APP_HIDE_ADMIN_CONTACTS
);
export const hideTokens = Boolean(
  process.env.REACT_APP_HIDE_TOKENS === 'true' || !process.env.REACT_APP_HIDE_TOKENS
);

export const hideTokenOrders = Boolean(
  process.env.REACT_APP_HIDE_TOKEN_ORDERS === 'true' || !process.env.REACT_APP_HIDE_TOKEN_ORDERS
);

export const hideCustomBasketOrders = Boolean(
  process.env.REACT_APP_HIDE_CUSTOM_BASKET_ORDERS === 'true' ||
    !process.env.REACT_APP_HIDE_CUSTOM_BASKET_ORDERS
);
