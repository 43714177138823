import { IModalWithData } from 'shared/Modals/types';
import { EditConstituentModal } from './EditConstituentsModal/EditConstituentModal';
import { CreateConstituentModal } from './CreateConstituentModal/CreateConstituentModal';
import { ReactNode } from 'react';
import { CustomEditConstituentModal } from './CustomEditConstituentModal/CustomEditConstituentModal';

export const ConstituentModal = ({ ...props }: IModalWithData) => {
  const ConstituentModals: Record<string, ReactNode> = {
    CREATE: <CreateConstituentModal {...props} />,
    EDIT: <EditConstituentModal {...props} />,
    // We need to have custom edit modal since data hadnling within ETP is not the same as from where Custom Edit is being called
    CUSTOM: <CustomEditConstituentModal {...props} />,
  };
  return <>{ConstituentModals[props.data.type]}</>;
};
