import { useCallback, useState, useMemo } from 'react';
import {
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  DOCUMENTS_FINAL_TERMS_STEP,
  DOCUMENTS_PCF_STEP,
  EXCHANGE_STEP,
  EtpDetailsStepType,
  FACTSHEET_STEP,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
  REVIEW_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import getStepsStatuses from 'pages/Instruments/components/Form/helpers/getStepsStatus';
import { getEtpDetailsStep, getEtpDetailsMainSteps } from 'pages/Instruments/components/Form/utils';
import BenchmarkDetailsStep from 'pages/Instruments/components/Form/EtpDetails/components/BenchmarkDetailsStep';
import ConstituentStep from 'pages/Instruments/components/Form/EtpDetails/components/ConstituentStep';
import ExchangeStep from 'pages/Instruments/components/Form/EtpDetails/components/ExchangeStep';
import GeneralDetailsStep from 'pages/Instruments/components/Form/EtpDetails/components/GeneralDetailsStep/GeneralDetailsStep';
import MarketStep from 'pages/Instruments/components/Form/EtpDetails/components/MarketStep';
import ProgressIndicator from 'components/ProgressIndicator';
import ReviewStep from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep';
import { Column, Row } from 'components/Grid';
import {
  useActiveStep,
  useCurrentInstrumentFormData,
  useDirtySteps,
} from 'store/instruments/selectors';
import { InstrumentFormDataProps } from 'pages/Instruments/Instruments.types';
import { MODAL_ACTIONS, TOUCHED_FIELDS_MODAL } from 'shared/Modals/constants';
import useAppModal from 'hooks/useAppModal';
import { instrumentsActions } from 'store/instruments/slice';
import useAppDispatch from 'hooks/useAppDispatch';
import DocumentsStep from './components/DocumentsStep';

function renderCurrentStep(
  activeStep: EtpDetailsStepType,
  goBack: () => void,
  goToStep: (step: EtpDetailsStepType) => void,
  onSubmit: () => void
) {
  const steps = {
    [GENERAL_DETAILS_STEP]: GeneralDetailsStep,
    [MARKET_STEP]: MarketStep,
    [BENCHMARK_STEP]: BenchmarkDetailsStep,
    [CONSTITUENT_STEP]: ConstituentStep,
    [EXCHANGE_STEP]: ExchangeStep,
    [DOCUMENTS_FINAL_TERMS_STEP]: DocumentsStep,
    [FACTSHEET_STEP]: DocumentsStep,
    [DOCUMENTS_PCF_STEP]: DocumentsStep,
    [REVIEW_STEP]: ReviewStep,
  };

  const Step = steps[activeStep];

  return <Step onSubmit={onSubmit} goBack={goBack} goToStep={goToStep} />;
}

function ETPDetails() {
  const openModal = useAppModal();
  const dispatch = useAppDispatch();
  const activeStep = useActiveStep('ETPDetails') as EtpDetailsStepType;
  const { updateActiveStep } = instrumentsActions;
  const dirtySteps = useDirtySteps();
  const isDirtyStep = Boolean(dirtySteps[activeStep]);
  const instrumentFormsData = useCurrentInstrumentFormData() as InstrumentFormDataProps;
  const isIndex = instrumentFormsData[GENERAL_DETAILS_STEP]?.productType === 'Index';
  const [completedSteps, setCompletedSteps] = useState<EtpDetailsStepType[]>([]);

  const handleNavigateToStep = useCallback(
    (step: EtpDetailsStepType) => {
      setCompletedSteps([...completedSteps, activeStep]);
      dispatch(updateActiveStep(step));
    },
    [activeStep, completedSteps, dispatch, updateActiveStep]
  );

  const openActiveETPUpdatedModal = (step: EtpDetailsStepType) => {
    openModal(
      {
        modalName: TOUCHED_FIELDS_MODAL,
        modalData: {
          type: MODAL_ACTIONS.EDIT,
          data: { targetStep: step },
        },
      },
      {
        onCloseModalAction: (step) => handleNavigateToStep(step as EtpDetailsStepType),
      }
    );
  };

  const handleStepSubmit = () => {
    const targetStep = getEtpDetailsStep(activeStep, isIndex).nextStep ?? REVIEW_STEP;
    if (isDirtyStep) {
      openActiveETPUpdatedModal(targetStep as EtpDetailsStepType);
    } else {
      handleNavigateToStep(targetStep as EtpDetailsStepType);
    }
  };

  const goBack = () => {
    const targetStep = getEtpDetailsStep(activeStep, isIndex).previousStep ?? GENERAL_DETAILS_STEP;
    if (isDirtyStep) {
      openActiveETPUpdatedModal(targetStep as EtpDetailsStepType);
    } else {
      handleNavigateToStep(targetStep as EtpDetailsStepType);
    }
  };

  const goToStep = (step: EtpDetailsStepType) => {
    if (isDirtyStep) {
      openActiveETPUpdatedModal(step);
    } else {
      handleNavigateToStep(step);
    }
  };

  const stepStatuses = useMemo(
    () => getStepsStatuses(instrumentFormsData, activeStep, completedSteps, dirtySteps),
    [instrumentFormsData, activeStep, completedSteps, dirtySteps]
  );

  return (
    <>
      <Row>
        <Column>
          <ProgressIndicator
            onClickStep={goToStep}
            steps={getEtpDetailsMainSteps(activeStep, isIndex, stepStatuses)}
          />
        </Column>
      </Row>
      {renderCurrentStep(activeStep as EtpDetailsStepType, goBack, goToStep, handleStepSubmit)}
    </>
  );
}

export default ETPDetails;
