import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { Card } from 'components/Card/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Typography } from '@mui/material';
import { NAVTransactionsTableConfig } from './tableConfig';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { TransactionLedger } from 'utils/types/nav';
import { NAVCardRows } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { EmptyRow } from 'components/Table/EmptyTableRow';

export const NAVTransactionsModal = ({
  closeModal,
  label,
  transactionData,
}: {
  closeModal: () => void;
  label: string;
  transactionData: {
    title: NAVCardRows;
    transactions?: TransactionLedger[];
    NAVTransactionData: { onyxValue: string | number; FAValue: string | number };
  };
}) => {
  const { title, transactions, NAVTransactionData } = transactionData;

  const defaultRow = (
    <TableRow data-qa-id={'title-row'} key={'title-key'} sx={{ width: '100%' }}>
      <TableCell data-qa-id={'title'} minWidth="140px">
        <Typography>{title} </Typography>
      </TableCell>
      <TableCell data-qa-id={'onyx-row-key'} minWidth="140px">
        <Typography>{NAVTransactionData.onyxValue} </Typography>
      </TableCell>
      <TableCell data-qa-id={'fa-row-key'} minWidth="180px">
        <Typography>{NAVTransactionData.FAValue} </Typography>
      </TableCell>
    </TableRow>
  );
  return (
    <CustomModal open onCloseModal={closeModal} customwidth="1000px">
      <Card
        onClose={closeModal}
        noPadding
        header={<CardHeaderTitleWithLabel label={label} title={title} />}
        body={
          <Table>
            <TableHeaderWithMultiSort columns={NAVTransactionsTableConfig} />
            <TableBodyWithStates hasContent={true}>
              {defaultRow}
              {transactions?.length ? (
                transactions.map((navTransaction) => (
                  <>
                    <TableRow data-qa-id={`${navTransaction._id}-row`} key={navTransaction._id}>
                      <TableCell data-qa-id={`${navTransaction.onyx}`}>
                        <Typography>{navTransaction.type ?? '-'} </Typography>
                      </TableCell>{' '}
                      <TableCell data-qa-id={`${navTransaction.onyx}`}>
                        <Typography>{navTransaction.amount ?? '-'} </Typography>
                      </TableCell>{' '}
                      {/* TODO: show this once BE starts returning FA values in transactions */}
                      <TableCell data-qa-id={`${navTransaction.onyx}`}>
                        <Typography>{'-'} </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <EmptyRow>No Transactions available</EmptyRow>
              )}
            </TableBodyWithStates>
          </Table>
        }
      />
    </CustomModal>
  );
};
