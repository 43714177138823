import useAppDispatch from 'hooks/useAppDispatch';
import { ParamsType } from 'hooks/useUrlParams';
import { useQuery } from 'react-query';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { createNotification } from 'store/notifications/actions';
import { loadFeesDetails } from 'utils/api/fees';

export const useFeesDetailsQuery = (
  productId: string,
  constituentTicker: string,
  urlParams: ParamsType
) => {
  const dispatch = useAppDispatch();
  return useQuery([productId, constituentTicker, urlParams], async () => {
    try {
      const res = await loadFeesDetails(productId, constituentTicker, urlParams);

      return res;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};
