import { useEffect, useState } from 'react';
import Search from 'components/Search';
import { ParamsType } from 'hooks/useUrlParams';
import { CONTACTS_TAB_VALUE } from 'utils/constants/contacts';
import { COMPANY_CONTACT_TYPE } from 'utils/types/contacts';
import { useUserPermissions } from 'store/user/selectors';
import { OptionType } from 'components/Select/Select.types';
import { Box } from '@mui/material';
import MultiSelect from 'components/MultiSelect/MultiSelect';

type Props = {
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  partnerOptions: OptionType<string | undefined>[] | undefined;
  listsOptions: OptionType<string | undefined>[] | undefined;
  loading: boolean;
};

const ContactsTableToolbar = ({
  setUrlParams,
  urlParams,
  partnerOptions,
  listsOptions,
  loading,
}: Props) => {
  const permissions = useUserPermissions();
  const shouldShowAllContactsTabs = permissions?.isIssuer || permissions?.isSystemAdmin;

  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
    }));
  }, [search, setUrlParams]);

  return (
    <Box display="flex" gap={1}>
      <Search
        variant="short"
        placeholder="Search"
        value={searchInputValue}
        onChange={(event) => setSearchInputValue(event.target.value)}
        onBlur={() => setSearch(searchInputValue)}
        onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
        onClear={() => setSearch('')}
      />
      {shouldShowAllContactsTabs && (
        <MultiSelect
          options={listsOptions ?? []}
          disabled={loading}
          placeholder="All lists"
          onChange={(_, values) =>
            setUrlParams((prevState) => ({
              ...prevState,
              contactLists: (values as string[]) ?? [],
            }))
          }
          value={urlParams.contactLists as string[]}
          size="small"
          alignment="right"
          renderTagsAsString
          showMaxValues={2}
        />
      )}
      {urlParams.subtab === CONTACTS_TAB_VALUE.PARTNER && (
        <MultiSelect
          options={partnerOptions ?? []}
          disabled={loading}
          placeholder="All partners"
          onChange={(_, values) =>
            setUrlParams((prevState) => ({
              ...prevState,
              partners: (values as string[]) ?? [],
            }))
          }
          value={urlParams.partners as string[]}
          size="small"
          alignment="right"
          renderTagsAsString
          showMaxValues={2}
        />
      )}
      {(urlParams.subtab === CONTACTS_TAB_VALUE.YOUR || !shouldShowAllContactsTabs) && (
        <MultiSelect
          options={[
            { label: 'Affiliated contact', value: COMPANY_CONTACT_TYPE.AFFILIATED },
            { label: 'User', value: COMPANY_CONTACT_TYPE.IMPLICIT },
          ]}
          placeholder="All types"
          disabled={loading}
          onChange={(_, values) =>
            setUrlParams((prevState) => ({ ...prevState, type: (values as string[]) ?? [] }))
          }
          value={urlParams.type as string[]}
          size="small"
          alignment="right"
          renderTagsAsString
          showMaxValues={2}
        />
      )}
    </Box>
  );
};

export default ContactsTableToolbar;
