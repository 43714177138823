import { Button } from 'components/Button/Button';
import useAppModal from 'hooks/useAppModal';
import { CircularProgress } from '@mui/material';
import { HistoricalStockSplits } from './sections/HistoricalStockSplits';
import { LoadingWrapper } from 'pages/Admin/ContactsPage/ContactsPage.styles';
import { MODAL_ACTIONS, REGISTER_MODAL } from 'shared/Modals/constants';
import { ProductInformation } from './sections/ProductInformation';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { RegisterDetailsHeader } from './components/RegisterDetailsHeader';
import { RegisterInformation } from './sections/RegisterInformation';
import { RegisterPdf } from 'pages/Orders/Register/RegisterPdf/RegisterPdf';
import { StyledButtonRow } from 'pages/Orders/Register/Register.styles';
import { StyledContainer } from './RegisterDetails.styles';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { downloadPdf, downloadXLS } from 'pages/Orders/Register/utils';
import { getSingleRegisterQuery } from 'utils/constants/reactQueries';
import { queryClient } from 'utils/api/queries/queryClient';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useParams } from 'react-router-dom';
import { useSingleRegisterQuery } from 'pages/Orders/Register/hooks/useSingleRegisterDetails';
import { Card } from 'components/Card/Card';

export const RegisterDetails = () => {
  const { user } = useAuthenticatedUser();
  const { id: productId } = useParams();
  const {
    data: registerProductData,
    isLoading,
    isFetching,
  } = useSingleRegisterQuery(productId ?? '');

  const shouldShowStockSplits =
    registerProductData?.stockSplits && registerProductData?.stockSplits?.length > 0;

  const openModal = useAppModal();
  const openRegisterModal = (modalType: MODAL_ACTIONS) => {
    openModal(
      {
        modalName: REGISTER_MODAL,
        modalData: {
          type: modalType,
          data: productId,
        },
      },
      {
        onCloseModalAction: () => {
          queryClient.invalidateQueries({ queryKey: [getSingleRegisterQuery + productId] });
        },
      }
    );
  };

  const addStockSplitButton = (
    <Button
      disabled={!user?.permissions.canEditStockSplits}
      onClick={() => openRegisterModal(MODAL_ACTIONS.CREATE)}
      data-qa-id="createRegisterEntryButton"
      size="medium"
      variant="primary"
    >
      Add Stock Splits
      <PlusIcon />
    </Button>
  );

  return (
    <>
      <TitleArea
        title={<RegisterDetailsHeader registerProductData={registerProductData} />}
        showBackButton={true}
      />
      <Card
        header={
          <StyledButtonRow>
            <>
              {!shouldShowStockSplits && addStockSplitButton}
              <Button
                variant="inverse"
                onClick={() => registerProductData && downloadPdf(registerProductData)}
              >
                <span>PDF</span>
                <DownloadIcon />
              </Button>
              <Button
                variant="inverse"
                onClick={() => registerProductData && downloadXLS(registerProductData)}
              >
                <span>XLS</span>
                <DownloadIcon />
              </Button>
            </>
          </StyledButtonRow>
        }
        body={
          <StyledContainer>
            {isLoading || isFetching ? (
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            ) : (
              <>
                <ProductInformation register={registerProductData} />
                {shouldShowStockSplits && (
                  <HistoricalStockSplits
                    splits={registerProductData?.stockSplits}
                    addStockSplitButton={addStockSplitButton}
                  />
                )}
                <RegisterInformation register={registerProductData} />
              </>
            )}
          </StyledContainer>
        }
      />

      <RegisterPdf singleRegister={registerProductData} />
    </>
  );
};
