import Table, { TableRow, TableCell } from 'components/Table';
import {
  useCurrentInstrumentFormData,
  useDirtyInstrumentFormData,
  useFormDataDirtyFields,
} from 'store/instruments/selectors';
import formatFieldName from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/formatFieldName';
import { DOCUMENTS_PCF_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { TableBody, TableHead } from '@mui/material';

const formatField = (value: string | boolean): string => {
  if (typeof value === 'boolean') {
    if (value) return 'True';
    return 'False';
  }
  return value;
};

export const DocumentsPcfDifferencesSection = () => {
  const { deleteUpdatedField } = useSaveETPHook();
  const instrumentFormsData = useCurrentInstrumentFormData(DOCUMENTS_PCF_STEP);
  const dirtyInstrumentFormsData = useDirtyInstrumentFormData(DOCUMENTS_PCF_STEP);
  const formDataDirtyFields = useFormDataDirtyFields(DOCUMENTS_PCF_STEP).filter(
    (field) => field !== 'regions'
  );

  return formDataDirtyFields.length ? (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell min-width="20%">Name</TableCell>
            <TableCell width="35%">Old Value</TableCell>
            <TableCell width="35%">Proposed Value</TableCell>
            <TableCell width="32px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formDataDirtyFields?.map((selectedValue, index) => (
            <TableRow key={`${selectedValue + index}`}>
              <TableCell>{formatFieldName(selectedValue)}</TableCell>
              <TableCell>
                {formatField((instrumentFormsData as { [key: string]: string })[selectedValue])}
              </TableCell>
              <TableCell>
                {formatField(
                  (dirtyInstrumentFormsData as { [key: string]: string })[selectedValue]
                )}
              </TableCell>
              <TableCell>
                <TrashIcon
                  onClick={() => {
                    deleteUpdatedField(selectedValue);
                  }}
                  style={{ paddingLeft: 0 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : null;
};
