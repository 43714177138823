import { DefaultValues } from 'react-hook-form';
import { DOCUMENTS_PCF_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { PcfProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { instrumentPcfSchemaUrl } from 'components/Form/formSchemas';
import Form from 'components/Form/Form';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import Footer from 'pages/Instruments/components/Footer';
import Select from 'components/Select';
import booleanOptions from 'utils/select-options/booleanOptions';
import { hourOptionsWithEndOfDay } from 'utils/select-options/timeOptions';

interface PcfStepProps {
  onSubmit: () => void;
  goBack: () => void;
}

function PcfStep({ onSubmit, goBack }: PcfStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(DOCUMENTS_PCF_STEP) as PcfProps;

  const saveValues = (data: DefaultValues<PcfProps>) => {
    saveInstrumentDraft({
      [DOCUMENTS_PCF_STEP]: {
        ...data,
        publishPcfOnApproval: data.publishPcfOnApproval,
        pcfPublishTime: data.publishPcfOnApproval === 'false' ? data.pcfPublishTime : null,
        pcfPublishTimeTimezone:
          data.publishPcfOnApproval === 'false' ? data.pcfPublishTimeTimezone : null,
      } as PcfProps,
    });
  };

  return (
    <Form<PcfProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentPcfSchemaUrl}
    >
      <FormGridContainer>
        <ETPStepSaveProgress />
        <FormGridItem md={6}>
          <Select
            name="publishPcfOnApproval"
            label="Publish Pcf On Approval"
            options={booleanOptions}
          ></Select>
        </FormGridItem>
        {formData.publishPcfOnApproval === 'false' && (
          <>
            <FormGridItem md={6}>
              <Select
                data-qa-id="pcfPublishTime"
                name="pcfPublishTime"
                options={hourOptionsWithEndOfDay}
              />
            </FormGridItem>
            <FormGridItem md={6}>
              <Select
                data-qa-id="pcfPublishTimeTimezone"
                name="pcfPublishTimeTimezone"
                capitalize={false}
              />
            </FormGridItem>
          </>
        )}
      </FormGridContainer>
      <Footer goBack={goBack} />
    </Form>
  );
}

export default PcfStep;
