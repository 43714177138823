import { SecuritiesRegister } from './types';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { DateTime } from 'utils/datetime';

export const generateXlSXObject = (register: SecuritiesRegister) => {
  return {
    'Issuer Name': register.issuer.name,
    'Issuer Address': `${register.issuer.address.addressLineOne}, ${register.issuer.address.addressLineTwo}, ${register.issuer.address.city?.name}, ${register.issuer.address.country?.name}, ${register.issuer.address.postalCode}`,
    Ticker: register.product.ticker,
    Name: register.product.name,
    ISIN: register.product.isin,
    Currency: register.product.currency,
    Maturity: register.product.maturity,
    'Total Securities Outstanding': register.product.totalUnitsOutstanding,
  };
};

export const downloadXLS = (register: SecuritiesRegister) => {
  if (register) {
    const workbook = XLSX.utils.book_new();
    const infoWorksheet = XLSX.utils.json_to_sheet([generateXlSXObject(register)]);

    XLSX.utils.book_append_sheet(workbook, infoWorksheet, 'RegisterData');
    if (register.entries?.length) {
      const xlsEntries = register.entries.map((entry) => ({
        Date: entry.dealDate,
        Event: entry.orderType,
        'Order Id': entry.orderExternalId,
        'Counter Party': entry.counterParty,
        '# Securities': entry.changeInSecuritiesAmount,
        '# Adjusted Securities': entry.adjustedChangeInSecuritiesAmount,
        'Total Securities Outstanding': entry.accumulatedAmount,
      }));
      const entriesWorksheet = XLSX.utils.json_to_sheet(xlsEntries);
      XLSX.utils.book_append_sheet(workbook, entriesWorksheet, 'EntriesSheet');
    }

    if (register.stockSplits?.length) {
      const xlsEntries = register.stockSplits.map((entry) => ({
        Date: entry.date,
        'Split ratio': entry.splitRatio,
      }));
      const entriesWorksheet = XLSX.utils.json_to_sheet(xlsEntries);
      XLSX.utils.book_append_sheet(workbook, entriesWorksheet, 'Stock Splits');
    }
    XLSX.writeFile(
      workbook,
      `${register.product.ticker}-${new DateTime().format('MM-dd-yyyy')}.xlsx`
    );
  }
};

export const downloadPdf = (register: SecuritiesRegister) => {
  if (register) {
    const PDF_MARGIN = 4;
    const details = document.querySelector(`#pdf-${register.product._id}`) as HTMLElement;

    if (details) {
      html2pdf()
        .set({
          filename: `${register.product.ticker}-${new DateTime().format('MM-dd-yyyy')}.pdf`,
          margin: PDF_MARGIN,
          jsPDF: { orientation: 'landscape' },
          pagebreak: { mode: 'avoid-all' },
          enableLinks: false,
        })
        .from(details)
        .save();
    }
  }
};
