import { FC } from 'react';
import {
  StyledPasscodeInput,
  StyledRightBottomButton,
} from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import { Box, Stack, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const Passcode: FC<{ handleVerifyPasscode: (otpCode: number) => void }> = ({
  handleVerifyPasscode,
}) => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ passcodeOTPInput: number }>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<{ passcodeOTPInput: number }> = ({ passcodeOTPInput }) => {
    handleVerifyPasscode(passcodeOTPInput);
  };

  return (
    <Card
      noPadding
      header={<CardHeaderTitleWithLabel label={'2FA Setup'} title={'Security code'} />}
      body={
        <Stack padding={2} gap={1}>
          <Typography variant="bodyMedium">Enter your authenticator 6-digit passcode</Typography>
          <form id="passcodeForm" onSubmit={handleSubmit(onSubmit)}>
            <StyledPasscodeInput
              autoFocus
              {...register('passcodeOTPInput', {
                required: 'Enter otp code',
                minLength: {
                  value: 6,
                  message: 'OTP code length should be 6 digits',
                },
                maxLength: {
                  value: 6,
                  message: 'OTP code length should be 6 digits',
                },
              })}
              type="number"
            />
          </form>
        </Stack>
      }
      footer={
        <Box padding={2}>
          <MuiStyledModalFooterButtons>
            <StyledRightBottomButton
              variant="primary"
              type="submit"
              form="passcodeForm"
              disabled={!isValid}
            >
              Verify
            </StyledRightBottomButton>
          </MuiStyledModalFooterButtons>
        </Box>
      }
    />
  );
};
