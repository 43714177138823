/* eslint-disable complexity */
import Table from 'components/Table';
import { Order } from 'utils/types/orders';
import { formatToCurrency } from 'utils/formatting';
import Box from '@mui/material/Box';
import { TableBody } from '@mui/material';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';

interface DealInformationProps {
  order?: Order;
  showOrderSummary: boolean;
}

const TransactionSummary = ({ order, showOrderSummary }: DealInformationProps) => {
  const baseCurrency = order?.product?.currency || 'USD';
  const deliveryCurrency = order?.deliveryCurrency;

  return (
    <>
      <Table noPadding={true}>
        <TableBody>
          {/* Product Base Currency */}
          <TableRow background="header" borderRadiusBottom={false}>
            <TableCell>Product Base Currency</TableCell>
            <TableCell align="right">{baseCurrency}</TableCell>
          </TableRow>
          {/* Order Market Value */}
          <TableRow background="header" borderRadiusTop={false} borderRadiusBottom={true}>
            <TableCell>
              <Box display="flex" gap={1}>
                Order Market Value
                <Tooltip
                  title={
                    <TooltipContent
                      description={
                        order?.status === 'PENDING'
                          ? 'Estimated Settlement Value'
                          : 'Settlement Value'
                      }
                    />
                  }
                ></Tooltip>
              </Box>
            </TableCell>
            <TableCell align="right">
              {Boolean(order?.deliveries?.expectedTotalCash)
                ? formatToCurrency(order?.deliveries?.expectedTotalCash, baseCurrency)
                : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {showOrderSummary && (
        <Table style={{ marginTop: '32px' }} noPadding>
          <TableRow background="header">
            <TableCell>Order Summary</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableBody>
            {/* Order Amount */}
            <TableRow>
              <TableCell>Order Amount</TableCell>
              <TableCell align="right">
                {Boolean(order?.tradesTotalFromPriceProviderInDeliveryCurrency)
                  ? formatToCurrency(
                      order?.tradesTotalFromPriceProviderInDeliveryCurrency,
                      deliveryCurrency
                    )
                  : '-'}
              </TableCell>
            </TableRow>
            {/* Slippage */}
            <TableRow>
              <TableCell>Slippage</TableCell>
              <TableCell align="right">
                {Boolean(order?.tradesTotalSlippageFromTotalAndPriceProviderInDeliveryCurrency)
                  ? formatToCurrency(
                      order?.tradesTotalSlippageFromTotalAndPriceProviderInDeliveryCurrency,
                      order?.deliveryCurrency
                    )
                  : '-'}
              </TableCell>
            </TableRow>
            {/* Trade amount */}
            <TableRow background="header">
              <TableCell>
                <Box display="flex" gap={1}>
                  Trade amount
                  <Tooltip
                    title={<TooltipContent description={'The order amount including slippage'} />}
                  ></Tooltip>
                </Box>
              </TableCell>
              <TableCell align="right" nowrap={'true'}>
                {Boolean(order?.tradesTotalInDeliveryCurrency)
                  ? formatToCurrency(order?.tradesTotalInDeliveryCurrency, deliveryCurrency)
                  : '-'}
              </TableCell>
            </TableRow>
            {/* Total Fees */}
            <TableRow>
              <TableCell>Total Fees</TableCell>
              <TableCell align="right" nowrap={'true'}>
                {Boolean(order?.tradesTotalFeesInDeliveryCurrency)
                  ? formatToCurrency(order?.tradesTotalFeesInDeliveryCurrency, deliveryCurrency)
                  : '-'}
              </TableCell>
            </TableRow>
            {/* Total amount to settle */}
            <TableRow background="warning">
              <TableCell>Total amount to settle</TableCell>
              <TableCell align="right" nowrap={'true'}>
                {Boolean(order?.tradesTotalWithTotalFeeInDeliveryCurrency)
                  ? formatToCurrency(
                      order?.tradesTotalWithTotalFeeInDeliveryCurrency,
                      deliveryCurrency
                    )
                  : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TransactionSummary;
