import { Box, Typography } from '@mui/material';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { ReconciliationCardTableRow } from './ReconciliationCardTableRow';
import { ReconciliationCardTableConfig } from './tableConfig';
import { Card } from 'components/Card/Card';
import { NAVDetailsWithProduct } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { EmptyRow } from 'components/Table/EmptyTableRow';

export interface NavReconviliationInterface {
  custody: string;
  nav: string;
  deferredOrders?: string;
  accruedUnrealizedFee?: string;
  break?: string; // TODO: check if this is the right prop
}

export const ReconciliationCard = ({ navReviewItem }: { navReviewItem: NAVDetailsWithProduct }) => {
  const navReconciliation = {
    custody: navReviewItem?.custodianTotals?.balance, // TODO: check if this is the right prop
    nav: String(navReviewItem?.ledgerBalance ?? '-'), // TODO: check if this is the right prop
    deferredOrders: navReviewItem?.totals?.unsettledDeferredOrders,
    accruedUnrealizedFee: navReviewItem?.totals?.unrealizedAccruedFee,
    break: navReviewItem?.break,
  };
  return (
    <Card
      noPadding
      header={
        <Box p={3}>
          <Typography variant="subheadingMedium">Reconciliation</Typography>
        </Box>
      }
      body={
        <Box p={3}>
          <Table noPadding>
            <TableHeaderWithMultiSort columns={ReconciliationCardTableConfig} />
            <TableBodyWithStates
              loadingData={Boolean(false)}
              hasContent={true}
              noContentLabel={<EmptyRow>{'text'}</EmptyRow>}
            >
              <ReconciliationCardTableRow
                navReconciliation={navReconciliation}
                columns={ReconciliationCardTableConfig}
                navReviewItem={navReviewItem}
              />
            </TableBodyWithStates>
          </Table>
        </Box>
      }
    />
  );
};
