import CircularProgress from 'components/CircularProgress';
import EditContactsListViewToolbar from 'pages/Admin/ContactsPage/components/EditContactsListViewToolbar';
import Table, { TableCell, TableRow } from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Contact, ContactList, ContactListContacts, PartnerContact } from 'utils/types';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { EditContactListTablePartnerRow } from './EditContactListTablePartnerRow';
import { EditContactListTableRow } from './EditContactListTableRow';
import { FULL_SPAN } from 'components/Table/TableCell';
import { ParamsType } from 'hooks/useUrlParams';
import { fieldFilter, getPaginationSliceValues, searchFilter } from 'utils/filters';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { TableBody } from '@mui/material';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { EmptyRow } from 'components/Table/EmptyTableRow';

interface EditContactListTableProps {
  contactList: ContactList | undefined;
  contacts: ContactListContacts;
  deleteAction: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  isLoading?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  tableTitle: string;
  urlParams: ParamsType;
}

const EditContactListPartnerColumns: TableHeaderColumn[] = [
  {
    label: 'Name',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '50%',
    colSpan: 2,
  },
  {
    label: 'Number of associated contacts',
    propName: 'numberOfAssociatedContacts',
    propType: 'number',
    sort: false,
    width: '50%',
    colSpan: 2,
  },
  {
    label: '',
    propName: 'edit',
    propType: 'string',
    sort: false,
  },
];

// @TODO add these column headers to the table with "Contact" rows
// const EditContactListColumns: TableHeaderColumn[] = [
//   {
//     label: 'Name',
//     propName: 'name',
//     propType: 'string',
//     sort: false,
//   },
//   {
//     label: 'Type',
//     propName: 'type',
//     propType: 'string',
//     sort: false,
//   },
//   {
//     label: 'Email',
//     propName: 'email',
//     propType: 'string',
//     sort: false,
//   },
//   {
//     label: '',
//     propName: 'edit',
//     propType: 'string',
//     sort: false,
//   },
// ];

export const EditContactListTable = ({
  contactList,
  contacts,
  deleteAction,
  isLoading,
  setUrlParams,
  tableTitle,
  urlParams,
}: EditContactListTableProps) => {
  const externalContacts = contacts.external
    .filter((item) => fieldFilter(item.type, urlParams?.partner))
    .filter((item) => searchFilter(item, ['name', 'email'], urlParams?.search));

  const partnerContacts = contacts.partners
    .filter((item) => fieldFilter(item._id, urlParams?.partner))
    .filter((item) => searchFilter(item, ['name'], urlParams?.search));

  const mergedContacts = [
    ...externalContacts.map((contact) => ({ ...contact, isPartner: false })),
    ...partnerContacts.map((partner) => ({ ...partner, isPartner: true })),
  ];

  const pagination = useBackendPagination(
    {
      page: urlParams.page ?? DEFAULT_PAGE,
      pageSize: urlParams.pageSize ?? DEFAULT_ITEMS_PER_PAGE,
      total: mergedContacts.length ?? 0,
    },
    setUrlParams
  );

  const paginationValues = getPaginationSliceValues(
    pagination.page,
    pagination.itemsPerPage,
    mergedContacts.length
  );

  const mergedContactsWithPagination = mergedContacts.slice(...paginationValues);

  const toolbar = (
    <EditContactsListViewToolbar
      contactList={contactList}
      urlParams={urlParams}
      setUrlParams={setUrlParams}
      partnersOptions={
        contacts?.partners.map((item) => ({ label: item.name, value: item._id ?? item.name })) ?? []
      }
    />
  );

  return (
    <Card
      header={<TableTitleWithActions title={tableTitle} actions={[toolbar]} />}
      headerDivider={false}
      body={
        <Table pagination={pagination} noPadding>
          <TableHeaderWithMultiSort
            defaultSortedColumns={urlParams.sort ?? []}
            columns={EditContactListPartnerColumns}
            onColumnSort={(columns) => {
              setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
            }}
            showActionsCell={false}
            showChevronColumn
          />
          {isLoading ? (
            <TableBody>
              <EmptyRow>
                <CircularProgress />
              </EmptyRow>
            </TableBody>
          ) : (
            <>
              {externalContacts?.length || partnerContacts?.length ? (
                <>
                  {mergedContactsWithPagination.map((mergedContact, index) => {
                    if ((mergedContact as PartnerContact & { isPartner: boolean }).isPartner) {
                      const partner = mergedContact as PartnerContact;
                      return (
                        <TableBodyWithStates
                          loadingData={Boolean(isLoading)}
                          hasContent={!isEmpty(partner)}
                          data-qa-id={`${partner.name}-contactlist`}
                          key={index}
                        >
                          <EditContactListTablePartnerRow
                            key={index + partner.name}
                            partner={partner}
                            deleteAction={contactList?._actions?.assign && deleteAction}
                          />
                        </TableBodyWithStates>
                      );
                    } else {
                      const contact = mergedContact as Contact;
                      return (
                        <TableBodyWithStates
                          loadingData={Boolean(isLoading)}
                          hasContent={!isEmpty(contact)}
                          key={index}
                        >
                          <EditContactListTableRow
                            key={index + contact.email}
                            contact={contact}
                            deleteAction={contactList?._actions?.assign && deleteAction}
                          />
                        </TableBodyWithStates>
                      );
                    }
                  })}
                </>
              ) : (
                <TableBody>
                  <TableRow
                    key={'empty-row'}
                    style={{ height: '84px', textAlign: 'center', verticalAlign: 'center' }}
                  >
                    <TableCell colSpan={FULL_SPAN}>No contacts at the moment.</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
      }
    />
  );
};
