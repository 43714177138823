import { NavigateOptions, To } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { NAVDetailsWithProduct } from './types';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';

export const NAVNotificationData = (
  navReviewItem: NAVDetailsWithProduct,
  rebalanceNotApproved: Boolean,
  hasOpenOrders: Boolean,
  navigate: (to: To, options?: NavigateOptions | undefined) => void
): {
  action?: () => void;
  actionText?: string;
  title: string;
  message: string;
  variant: string;
} => {
  if (rebalanceNotApproved) {
    return {
      action: () => {
        navigate(`${privateRoutesUrls.dashboardRoutes.rebalance}?tab=today`);
      },
      actionText: 'Rebalance',
      title: 'Rebalance needed',
      message:
        'NAV cannot be approved on rebalance day for a fund until the rebalance is approved.',
      variant: 'error',
    };
  }
  if (hasOpenOrders) {
    return {
      action: () => {
        navigate(privateRoutesUrls.dashboardRoutes.orders);
      },
      actionText: 'Review orders',
      title: 'Unsettled orders',
      message: 'There are unsettled orders, please review them before rebooking.',
      variant: 'error',
    };
  }
  if (navReviewItem?.isSignificantBreak) {
    return {
      actionText: 'Resolve',
      title: 'Error found',
      message: 'Threshold exceeded for NAV and Custody. Auto approval is blocked.',
      variant: 'error',
    };
  }
  if (navReviewItem?.status === PortfolioCompositionStatus.PENDING) {
    return {
      title: 'Discrepancies found in NAV calculation',
      message: '',
      variant: 'error',
    };
  }
  return {
    title: 'No discrepancies found.',
    message: '',
    variant: 'success',
  };
};
