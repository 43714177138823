/* eslint-disable complexity */
import OrderDeferConfirmationModal from './Orders/OrderDeferConfirmationModal';
import { ActiveModalsType, IModalWithData } from './types';
import { CalendarEventModal } from './Calendar/CalendarEventModal';
import { CalendarModal } from './Calendar/CalendarModal';
import { ConstituentModal } from './Constituents/ConstituentsModal';
import { ContactListModal } from './ContactLists/ContactListModal';
import { ContactModal } from './Contacts/ContactModal';
import { CustodianModal } from './Custodian/CustodianModal';
import { ExchangeModal } from './Exchange/ExchangeModal';
import { OrdersModal } from './Orders/OrdersModal';
import { PartnerModal } from './Partners/PartnerModal';
import { ProductExchangeModal } from './ProductExchange/ProductExchangeModal';
import { ProductModal } from './Product/ProductModal';
import { RebalanceModal } from './Rebalance/RebalanceModal';
import { TouchedProductModal } from './TouchedProductModal/TouchedProductModal';
import { UsersModal } from './Users/UsersModal';
import { WalletsModal } from './Wallets/WalletsModal';
import { YesOrNoModal } from './YesOrNoModal/YesOrNoModal';
import { useModalContext } from 'shared/Contexts/Modal/useModalContext.hook';
import {
  CALENDAR_EVENT_MODAL,
  CALENDAR_MODAL,
  CONSTITUENT_MODAL,
  CONTACT_LIST_MODAL,
  CONTACT_MODAL,
  CUSTODIAN_MODAL,
  DEFER_ORDER_MODAL,
  ETP_MODAL,
  EXCHANGE_MODAL,
  // NAV_REVIEW_MODAL,
  ORDERS_MODAL,
  PARTNER_MODAL,
  PRODUCT_EXCHANGE_MODAL,
  REBALANCE_MODAL,
  REGISTER_MODAL,
  TOKEN_MODAL,
  TOUCHED_FIELDS_MODAL,
  USER_MODAL,
  WALLET_MODAL,
  YES_OR_NO_MODAL,
  API_KEY_MODAL,
  FEES_MODAL,
} from './constants';
import { RegisterModal } from './Register/RegisterModal';
import { ApiKeyModal } from './ApiKey/ApiKeyModal';
import { FeesModal } from './Fees/FeesModal';

interface ModalRouterProps extends IModalWithData {
  modalName?: ActiveModalsType;
}

export const ModalsList = ({ modalName, ...props }: ModalRouterProps) => {
  const { onCloseModalAction } = useModalContext();
  const updatedProps = { ...props, onCloseModalAction: onCloseModalAction };

  switch (modalName) {
    case CALENDAR_EVENT_MODAL:
      return <CalendarEventModal {...updatedProps} />;
    case CALENDAR_MODAL:
      return <CalendarModal {...updatedProps} />;
    case CONSTITUENT_MODAL:
      return <ConstituentModal {...updatedProps} />;
    case CONTACT_LIST_MODAL:
      return <ContactListModal {...updatedProps} />;
    case REGISTER_MODAL:
      return <RegisterModal {...updatedProps} />;
    case CONTACT_MODAL:
      return <ContactModal {...updatedProps} />;
    case CUSTODIAN_MODAL:
      return <CustodianModal {...updatedProps} />;
    case DEFER_ORDER_MODAL:
      return <OrderDeferConfirmationModal {...updatedProps} />;
    case ETP_MODAL:
      return <ProductModal productType="ETP" {...updatedProps} />;
    case EXCHANGE_MODAL:
      return <ExchangeModal {...updatedProps} />;
    // case NAV_REVIEW_MODAL:
    //   return <NavReviewModal {...updatedProps} />;
    case ORDERS_MODAL:
      return <OrdersModal {...updatedProps} />;
    case PARTNER_MODAL:
      return <PartnerModal {...updatedProps} />;
    case PRODUCT_EXCHANGE_MODAL:
      return <ProductExchangeModal {...updatedProps} />;
    case REBALANCE_MODAL:
      return <RebalanceModal {...updatedProps} />;
    case TOKEN_MODAL:
      return <ProductModal productType="Token" {...updatedProps} />;
    case TOUCHED_FIELDS_MODAL:
      return <TouchedProductModal {...updatedProps} />;
    case USER_MODAL:
      return <UsersModal {...updatedProps} />;
    case WALLET_MODAL:
      return <WalletsModal {...updatedProps} />;
    case YES_OR_NO_MODAL:
      return <YesOrNoModal {...updatedProps} />;
    case API_KEY_MODAL:
      return <ApiKeyModal {...updatedProps} />;
    case FEES_MODAL:
      return <FeesModal {...updatedProps} />;
    default:
      return null;
  }
};
