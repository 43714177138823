import TableCell from './TableCell';
import { ComponentPropsWithRef } from 'react';
import Tag from 'components/Tag/Tag';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { Box } from '@mui/material';

type TableShowMoreCellProps = ComponentPropsWithRef<typeof TableCell> & {
  items: string[];
  colSpan?: number;
};

export const TableShowMoreCell = ({ items, colSpan, ...props }: TableShowMoreCellProps) => {
  const [firstItem, ...restItems] = items ?? [];

  const tooltipCopy = restItems.map((item) => `${item}`).join('\n');

  return (
    <TableCell colSpan={colSpan}>
      <Box display="flex" gap={1}>
        {firstItem && <Tag label={firstItem} />}
        {Boolean(restItems.length) && (
          <Tooltip title={<TooltipContent description={tooltipCopy}></TooltipContent>}>
            <Tag label={`+ ${restItems.length}`} />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};
