import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { useNavReviewDataQuery } from './hooks/useNavReviewDataQuery';
import { useMemo } from 'react';
import { NAVReviewItemWithProduct, NAVReviewResponseDto } from 'utils/types/nav';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { NavStatusBarInfo } from 'utils/types/nav';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import { useUrlParams } from 'hooks/useUrlParams';
import { NAVReviewInforBar } from './components/NAVReviewInforBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { NAVTable } from 'shared/Tables/NAVTable/NAVTable';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

const mapNavReviewItems = (navReviewItems: NAVReviewResponseDto[]): NAVReviewItemWithProduct[] => {
  return navReviewItems
    .map((navItem) => {
      return navItem.netAssetValueComparison.map((item) => {
        return {
          ...item,
          rawDifference: new BigNumber(item?.differences?.closingBalance)?.toNumber(),
          product: navItem.product,
          portfolioId: navItem._id,
        };
      });
    })
    .flat();
};

const defaultParams = {
  productId: null,
  sort: null,
};

const getInforBarCountData = (navReviewItems: NAVReviewItemWithProduct[]): NavStatusBarInfo => ({
  total: navReviewItems.length,
  totalApproved: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.APPROVED
  ).length,
  totalWithError: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.PENDING
  ).length,
});

export const NAVReviewLandingPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const valuationDate = searchParams.get('valuationDate');
  const { urlParams, setUrlParams } = useUrlParams(
    { ...defaultParams, valuationDate: valuationDate ?? format(new Date(), DEFAULT_DATE_FORMAT) },
    privateRoutesUrls.dashboardRoutes.nav
  );

  const {
    data: navPaginatedData,
    isLoading: loading,
    isFetching: fetching,
  } = useNavReviewDataQuery({ ...urlParams, pageSize: 1000 });
  const navReviewItems = useMemo(
    () => mapNavReviewItems(navPaginatedData?.data ?? []),
    [navPaginatedData?.data]
  );

  const reviewBarInfoData = useMemo(
    () => getInforBarCountData(navReviewItems ?? []),
    [navReviewItems]
  );

  return (
    <>
      <TitleArea title="NAV Review" />
      <Card
        header={
          <TableTitleWithActions
            title={'NAV Review'}
            actions={[
              <NAVReviewInforBar
                setQueryParams={setUrlParams}
                queryParams={urlParams}
                overviewBarInfoData={navReviewItems.length ? reviewBarInfoData : null}
              />,
            ]}
          />
        }
        headerDivider={false}
        body={
          <NAVTable
            navReviewItems={navReviewItems ?? []}
            editAction={(navReviewItem) =>
              navigate(
                `/nav/pcf/${navReviewItem.product._id}/nav/constituent/${navReviewItem.ticker}/valuationDate/${urlParams.valuationDate}`
              )
            }
            queryParams={urlParams}
            setQueryParams={setUrlParams}
            loading={loading || fetching}
          />
        }
      />
    </>
  );
};
