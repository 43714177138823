import { NAVReviewItem } from 'utils/types/nav';
import { useEffect, useMemo, useState } from 'react';
import { mapNavReviewDetailsTransactions } from 'pages/Ledger/NAV/NAVDetailsPage/utils/mapper';
import { useNAVReviewForValuationDate } from './useNavReviewForValuationDate';

export const useNAVReviewItemDetailsQuery = ({
  constituentTicker,
  valuationdate,
  productId,
}: {
  constituentTicker: string;
  valuationdate: string;
  productId: string;
}) => {
  const [navReviewItem, setNavReviewItem] = useState<NAVReviewItem>();
  const [reviewNAVItemOldValue, setreviewNAVItemOldValue] = useState<NAVReviewItem>();

  const {
    data: navReviewItemRes,
    isLoading,
    isFetching,
    refetch,
  } = useNAVReviewForValuationDate(productId, constituentTicker, valuationdate);

  useEffect(() => {
    if (!reviewNAVItemOldValue) setreviewNAVItemOldValue(structuredClone(navReviewItemRes));
    setNavReviewItem(structuredClone(navReviewItemRes));
  }, [navReviewItemRes, isFetching, isLoading, refetch]);
  const mappedNavDetailsTransactions = useMemo(() => {
    return mapNavReviewDetailsTransactions(navReviewItem);
  }, [navReviewItem]);

  return {
    reviewNAVItemOldValue,
    mappedNavDetailsTransactions,
    navReviewItem,
    isLoading: isLoading || isFetching,
    updateDeltaNavReviewItem: (item: NAVReviewItem) => {
      setNavReviewItem({
        ...item,
        product: item.product ?? navReviewItem?.product,
        ...(navReviewItem?.custodianBalances && {
          custodianBalances: item.custodianBalances ?? navReviewItem?.custodianBalances,
        }),
        ...(navReviewItem?.custodianTotals && {
          custodianTotals: item.custodianTotals ?? navReviewItem?.custodianTotals,
        }),
      }); // TODO: we should receive this from BE
    },
    refetch: refetch,
  };
};
