import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { Card } from 'components/Card/Card';
import { CustomModal } from 'shared/Modals/Modal';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Box, Stack, Typography } from '@mui/material';
import {
  NAVDetailsWithProduct,
  OverrideFields,
  RebookDto,
} from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import InputComponent from 'components/Input';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Button } from 'components/Button/Button';
import { NAVBalanceOverrideTableConfig } from './NAVBalanceOverrideTableConfig';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { EmptyRow } from 'components/Table/EmptyTableRow';
import { OverrideModalRowsLabels } from './utils';
import { useState } from 'react';
import { postRebook } from 'utils/api/portfolio-composition';
import { useMutation } from 'react-query';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { createEntityApiCall } from 'utils/api/crudActions';
import { NAVReviewItem } from 'utils/types/nav';

export const NAVBalanceOverrideModal = ({
  closeModal,
  overrideData,
  onCloseModalAction,
}: {
  onCloseModalAction: () => void;
  closeModal: () => void;
  overrideData: {
    reviewNAVItemOldValue?: NAVReviewItem;
    overrideBalance: RebookDto;
    navReviewItem: NAVDetailsWithProduct;
  };
}) => {
  const dispatch = useAppDispatch();
  const { navReviewItem, overrideBalance, reviewNAVItemOldValue } = overrideData;

  const tableRows = overrideBalance && Object.keys(overrideBalance);
  const [rebookData, setRebookData] = useState(overrideBalance);

  const rebookMutation = useMutation({
    mutationFn: (uri: string) => {
      return Object.keys(rebookData).length
        ? postRebook(uri, rebookData)
        : createEntityApiCall(null, navReviewItem?._actions?.approve?.uri ?? '');
    },
    onSuccess: () => {
      dispatch(
        createNotification({
          message: 'NAV is succesfully rebooked.',
          title: 'NAV rebooked',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'NAV refresh error',
          type: 'error',
        })
      );
    },
  });

  const onSubmitHandler = () => {
    navReviewItem._links?.self.uri && rebookMutation.mutate(navReviewItem._links?.self.uri);
  };

  const Footer = (
    <MuiStyledModalFooterButtons padding={'16px 24px'}>
      <Button variant="secondary" onClick={closeModal} type="button">
        Cancel
      </Button>
      <Button type="submit" variant="primary" onClick={onSubmitHandler}>
        Confirm
      </Button>
    </MuiStyledModalFooterButtons>
  );
  const getOldValue = (overrideField: OverrideFields) => {
    if (overrideField === 'overrideBalance') {
      return reviewNAVItemOldValue?.ledgerBalance;
    }
    if (overrideField === 'overrideNetOrders') {
      return reviewNAVItemOldValue?.totals?.netOrders;
    }
    if (overrideField === 'overrideStakingRewards') {
      return reviewNAVItemOldValue?.totals?.stakingRewards;
    }
  };

  return (
    <CustomModal open onCloseModal={closeModal} customwidth="850px">
      <Card
        noPadding
        onClose={closeModal}
        header={<CardHeaderTitleWithLabel label={'NAV'} title={'NAV Override'} />}
        body={
          <Stack gap={2} padding={2}>
            <Box sx={{ backgroundColor: '#F9FBFD', padding: '24px', borderRadius: '8px' }}>
              {Object.keys(rebookData).length ? (
                <Table noPadding>
                  <TableHeaderWithMultiSort columns={NAVBalanceOverrideTableConfig} />
                  <TableBodyWithStates
                    loadingData={Boolean(false)}
                    hasContent={true}
                    noContentLabel={<EmptyRow>{'No Content'}</EmptyRow>}
                  >
                    {tableRows?.map((key, index) => (
                      <TableRow data-qa-id={'data-point-row'} key={key}>
                        <TableCell data-qa-id={key}>
                          <Typography>{OverrideModalRowsLabels[key as OverrideFields]}</Typography>
                        </TableCell>
                        <TableCell data-qa-id={'old-value-row'}>
                          <Typography>{getOldValue(key as OverrideFields) ?? '-'} </Typography>
                        </TableCell>
                        <TableCell data-qa-id={'new-value-row'}>
                          <Typography>{overrideBalance[key as OverrideFields] ?? '-'} </Typography>
                        </TableCell>
                        <TableCell data-qa-id={'comments'}>
                          <InputComponent
                            placeholder="Add comment"
                            size="small"
                            value={rebookData[`${key}Reason` as keyof RebookDto]}
                            onChange={(val) => {
                              setRebookData({
                                ...rebookData,
                                [`${key}Reason`]: val.target.value,
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBodyWithStates>
                </Table>
              ) : (
                <Box display={'grid'} gap={2}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography>NAV</Typography>
                    <Typography>{navReviewItem.ledgerBalance ?? '-'} </Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography>Difference</Typography>
                    <Typography>{navReviewItem.differences.closingBalance ?? '-'} </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        }
        footer={Footer}
      />
    </CustomModal>
  );
};
