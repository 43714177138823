import { generatePath } from 'react-router-dom';
import { omit } from 'lodash';

import { StepIndicatorType } from 'components/ProgressIndicator/ProgressIndicator.types';
import {
  etpDetailsStepsList,
  indexEtpDetailsStepsList,
  EtpDetailsStepType,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  EXCHANGE_STEP,
  REVIEW_STEP,
  SUMMARY_STEP_LABEL,
  FACTSHEET_STEP,
  DOCUMENTS_FINAL_TERMS_STEP,
  DOCUMENTS_PCF_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import {
  ETPPartnerDetailsStepType,
  partnerStepsList,
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';
import { StepsListType, StepsType } from 'pages/Instruments/components/Form/types';
import {
  InstrumentFormDataStepType,
  PartialInstrumentFormProps,
} from 'pages/Instruments/Instruments.types';
import { privateRoutesUrls } from 'router/constants';

type StepsStatusType<T extends StepsType> = Partial<
  Record<
    T,
    Pick<StepIndicatorType<T>, 'isActive' | 'isComplete' | 'isDisabled' | 'isInvalid' | 'isDirty'>
  >
>;

export function getSteps<T extends StepsType>(
  activeStep: T,
  stepList: StepsListType<T>[],
  stepsStatus: StepsStatusType<T> = {}
): StepIndicatorType<T>[] {
  const mappedSteps = stepList.map((step) => ({
    ...step,
    isActive:
      stepsStatus[step.id]?.isActive ||
      step.subSteps?.includes(activeStep) ||
      step.id === activeStep,
    isComplete:
      stepsStatus[step.id]?.isComplete &&
      (step.subSteps?.every((subStep) => stepsStatus[subStep]?.isComplete) ?? true),
    isDisabled:
      stepsStatus[step.id]?.isDisabled ||
      (step.subSteps?.some((subStep) => stepsStatus[subStep]?.isDisabled) ?? false),
    isInvalid:
      stepsStatus[step.id]?.isInvalid ||
      (step.subSteps?.some((subStep) => stepsStatus[subStep]?.isInvalid) ?? false),
    isDirty:
      stepsStatus[step.id]?.isDirty ||
      (step.subSteps?.some((subStep) => stepsStatus[subStep]?.isDirty) ?? false),
  }));
  return mappedSteps;
}

export function getStep(stepId: InstrumentFormDataStepType, isIndex?: boolean) {
  const etpDetailsSteps = isIndex ? indexEtpDetailsStepsList : etpDetailsStepsList;
  const steps = [...etpDetailsSteps, ...partnerStepsList];

  return steps.find((step) => step.id === stepId) || steps[0];
}

export function getEtpDetailsStep(stepId: EtpDetailsStepType, isIndex: boolean) {
  return getStep(stepId, isIndex);
}

export function getPartnerDetailsStep(stepId: ETPPartnerDetailsStepType) {
  return partnerStepsList.find((step) => step.id === stepId);
}

export const getEtpDetailsMainSteps = (
  activeStep: EtpDetailsStepType,
  isIndex: boolean,
  stepsStatus: StepsStatusType<EtpDetailsStepType>
): StepIndicatorType<EtpDetailsStepType>[] => {
  const allStepsList = getFormatedSteps(isIndex ? indexEtpDetailsStepsList : etpDetailsStepsList);
  const subSteps = allStepsList.flatMap((step) => step.subSteps ?? []);
  const stepsList = allStepsList.filter((step) => !subSteps.includes(step.id));
  return getSteps(activeStep, stepsList, stepsStatus);
};

function isMaskedValue(value?: string) {
  return value && value.match(/^#+.{5}$/);
}

export function getEtpDetailsFormDataFormatted(formData: PartialInstrumentFormProps) {
  return {
    general: formData[GENERAL_DETAILS_STEP]
      ? omit(formData[GENERAL_DETAILS_STEP], ['fundIcon'])
      : undefined,
    market: formData[MARKET_STEP],
    benchmark: formData[BENCHMARK_STEP],
    constituents: formData[CONSTITUENT_STEP],
    exchanges: formData[EXCHANGE_STEP],
    factsheet: formData[FACTSHEET_STEP],
    partners: formData[PARTNERS_STEP],
    custodianAccounts: formData[CUSTODIAN_INFO_STEP]?.map((custodian) => ({
      ...omit(custodian, ['apiKey', 'apiPassphrase', 'signatureKey']),
      ...(!isMaskedValue(custodian.apiKey) && { apiKey: custodian.apiKey }),
      ...(!isMaskedValue(custodian.apiPassphrase) && { apiPassphrase: custodian.apiPassphrase }),
      ...(!isMaskedValue(custodian.signatureKey) && { signatureKey: custodian.signatureKey }),
    })),
    wallets: formData[WALLETS_INFO_STEP]?.unifiedWallets,
    custodianWallets: formData[WALLETS_INFO_STEP]?.custodianWallets,
    legalDocuments: formData[DOCUMENTS_FINAL_TERMS_STEP],
    pcf: formData[DOCUMENTS_PCF_STEP],
  };
}

export const getPartnerDetailsSteps = (
  activeStep: ETPPartnerDetailsStepType,
  stepsStatus: StepsStatusType<ETPPartnerDetailsStepType>
): StepIndicatorType<ETPPartnerDetailsStepType>[] => {
  const stepsList = getFormatedSteps(partnerStepsList);
  return getSteps(activeStep, stepsList, stepsStatus);
};

const stepsToFormProps = {
  [GENERAL_DETAILS_STEP]: 'general',
  [MARKET_STEP]: 'market',
  [BENCHMARK_STEP]: 'benchmark',
  [CONSTITUENT_STEP]: 'constituents',
  [EXCHANGE_STEP]: 'exchanges',
  [FACTSHEET_STEP]: 'factsheet',
  [DOCUMENTS_FINAL_TERMS_STEP]: 'documentsFinalTerms',
  [DOCUMENTS_PCF_STEP]: 'documentsPcf',
  [REVIEW_STEP]: 'review',
};

export function getFormPropFromStep(stepLable: EtpDetailsStepType) {
  return stepsToFormProps[stepLable];
}

function getTabUrl(createUrl: string, editUrl: string, id?: string) {
  return id
    ? generatePath(editUrl, {
        id,
      })
    : createUrl;
}

export function getEtpDetailsUrl(id?: string) {
  return getTabUrl(
    privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails,
    privateRoutesUrls.dashboardRoutes.editEtpProductsEtpDetails,
    id
  );
}

export function getPartnerDetailsUrl(id?: string) {
  return getTabUrl(
    privateRoutesUrls.dashboardRoutes.newEtpProductsPartnerDetails,
    privateRoutesUrls.dashboardRoutes.editEtpProductsPartnerDetails,
    id
  );
}

const getFormatedSteps = <T>(partnerStepsList: StepsListType<T>[]) => {
  return partnerStepsList.map((step) => {
    if (step.id === REVIEW_STEP) return { ...step, label: SUMMARY_STEP_LABEL };
    return step;
  });
};

export const CUSTODIAN_WALLETS = 'custodianWallets';
export const UNIFIED_WALLETS = 'unifiedWallets';
