import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Card } from 'components/Card/Card';
import {
  NAVCardInterface,
  NAVCardRows,
  RebookDto,
} from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { NAVDetailsTable } from 'pages/Ledger/NAV/NAVDetailsPage/Cards/NAVDetailsCard/NAVDetailsTable/NAVDetailsTable';
import useAppDispatch from 'hooks/useAppDispatch';
import { createEntityApiCallWithCustomResponse } from 'utils/api/crudActions';
import { NAVReviewItem, TransactionLedger } from 'utils/types/nav';
import { useMutation } from 'react-query';
import { createNotification } from 'store/notifications/actions';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { isEmpty } from 'lodash';
import { NAVBalanceOverrideModal } from './NAVBalanceOverrideModal/NAVBalanceOverrideModal';
import { NAVTransactionsModal } from 'pages/Ledger/NAV/NAVDetailsPage/Cards/NAVDetailsCard/NAVTransactionsModal/NAVTransactionsModal';

export const NAVDetailsCard = ({
  reviewNAVItemOldValue,
  navTransactions,
  navReviewItem,
  updateDeltaNavReviewItem,
  userPermissions,
  refreshData,
}: NAVCardInterface) => {
  const dispatch = useAppDispatch();

  const [showTransactions, setShowTransactions] = useState<{
    title: NAVCardRows;
    transactions: TransactionLedger[];
    NAVTransactionData: { onyxValue: string | number; FAValue: string | number };
  }>();
  const [overrideBalance, setOverrideBalance] = useState<RebookDto>({});
  const [rebookModal, setShowRebookModal] = useState(false);

  const rebookEstimationMutation = useMutation({
    mutationFn: (val: { uri: string; newOverrideBalance: RebookDto }) => {
      return createEntityApiCallWithCustomResponse<RebookDto, NAVReviewItem>(
        val.uri,
        val.newOverrideBalance
      );
    },
    onSuccess: (res, data) => {
      updateDeltaNavReviewItem(res.data);
      setOverrideBalance(data.newOverrideBalance);
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'NAV Rebook Estimation error',
          type: 'error',
        })
      );
    },
  });

  const hasOpenOrders =
    navReviewItem?.openOrders !== undefined && !isEmpty(navReviewItem?.openOrders);
  const rebalanceNotApproved = navReviewItem?.isRebalanceApproved === false;

  return (
    <>
      <Card
        noPadding
        header={
          <Box p={3} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Typography variant="subheadingMedium">NAV</Typography>
            <Typography variant="subheadingMedium">{navReviewItem.ledgerBalance}</Typography>
          </Box>
        }
        body={
          <Box p={3}>
            <NAVDetailsTable
              navTransactions={navTransactions}
              navReviewItem={navReviewItem}
              hasError={
                navReviewItem.status === PortfolioCompositionStatus.PENDING || rebalanceNotApproved
              }
              hasOpenOrders={hasOpenOrders}
              loading={rebookEstimationMutation.isLoading}
              onRebook={() => {
                setShowRebookModal(true);
              }}
              overrideBalance={overrideBalance}
              onRebookEstimation={(newOverrideBalance) => {
                rebookEstimationMutation.mutate({
                  uri: navReviewItem._actions?.rebookEstimation?.uri ?? '',
                  newOverrideBalance,
                });
              }}
              openTransactions={setShowTransactions}
              userPermissions={userPermissions}
            />
          </Box>
        }
      />
      {showTransactions && (
        <NAVTransactionsModal
          closeModal={() => {
            setShowTransactions(undefined);
          }}
          label="NAV"
          transactionData={showTransactions}
        />
      )}
      {rebookModal && (
        <NAVBalanceOverrideModal
          closeModal={() => {
            setShowRebookModal(false);
          }}
          onCloseModalAction={() => {
            refreshData();
          }}
          overrideData={{
            reviewNAVItemOldValue,
            overrideBalance,
            navReviewItem,
          }}
        />
      )}
    </>
  );
};
