import Box from '@mui/material/Box';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { Typography } from '@mui/material';
import { renderOrderValue } from 'shared/Tables/table.utils';
import Link from 'components/Link';

export interface RegisterInformationProps {
  register?: SecuritiesRegister;
}

const RegisterInformationColumnsConfig = [
  { label: 'Date', propName: 'date', propType: 'string', sort: false },
  { label: 'Event', propName: 'orderType', propType: 'string', sort: false },
  { label: 'Order Id', propName: 'orderExternalId', propType: 'string', sort: false },
  { label: 'Counter Party', propName: 'counterParty', propType: 'string', sort: false },
  {
    label: '# Securities',
    propName: 'numberOfSecurities',
    propType: 'string',
    sort: false,
  },
  {
    label: '# Adjusted Securities',
    propName: 'adjustedChangeInSecuritiesAmount',
    propType: 'string',
    sort: false,
  },
  {
    label: 'Total Securities Outstanding',
    propName: 'totalSecuritiesOutstanding',
    propType: 'string',
    sort: false,
  },
];

export const RegisterInformation = ({ register }: RegisterInformationProps) => {
  return (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Orders</Typography>
      </MuiStyledSectionHeader>
      <Table>
        <TableHeaderWithMultiSort
          columns={RegisterInformationColumnsConfig}
          columnAlignment={{
            date: 'left',
            numberOfSecurities: 'right',
            totalSecuritiesOutstanding: 'right',
            adjustedChangeInSecuritiesAmount: 'right',
          }}
        />
        <TableBodyWithStates
          hasContent={Boolean(register?.entries?.length)}
          noContentLabel="No entries at the moment."
        >
          {register?.entries?.map((entry, index) => (
            <TableRow key={entry.dealDate + index}>
              <TableCell align="left">
                {renderOrderValue(Boolean(entry.dealDate), entry.dealDate)}
              </TableCell>
              <TableCell align="left">
                {String(renderOrderValue(Boolean(entry.orderType), entry.orderType) || '')}
              </TableCell>
              <TableCell align="left">
                {entry.orderId && (
                  <Link
                    href={`/orders/edit/${entry.orderId}`}
                    text={entry.orderExternalId}
                    type="block"
                    target="_blank"
                    variant="primary"
                  />
                )}{' '}
              </TableCell>
              <TableCell align="left">
                {renderOrderValue(Boolean(entry.counterParty), entry.counterParty)}
              </TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(Number(entry.changeInSecuritiesAmount)),
                  entry.changeInSecuritiesAmount
                )}
              </TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(Number(entry.adjustedChangeInSecuritiesAmount)),
                  entry.adjustedChangeInSecuritiesAmount
                )}
              </TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(entry.accumulatedAmount), entry.accumulatedAmount)}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
    </Box>
  );
};
