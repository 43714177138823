export const NAVTransactionsTableConfig = [
  { label: 'Name', propName: 'name', propType: 'string', minWidth: '140px', sort: false },
  {
    label: 'Onyx',
    propName: 'onyx',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  // TODO: update this once BE starts returning FA values in transactions
  {
    label: 'Fund Accountant',
    propName: 'fundAccountant',
    propType: 'string',
    minWidth: '180px',
    sort: false,
    showInfoIcon: true,
  },
];
