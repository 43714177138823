import {
  MuiStyledIndicator,
  MuiStyledIndicatorContainer,
} from 'components/ProgressIndicator/ProgressIndicator.styles';
import {
  VariantType,
  StepIndicatorType,
} from 'components/ProgressIndicator/ProgressIndicator.types';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import { ReactComponent as CircleIcon } from 'assets/circle.svg';
import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg';
import { ReactComponent as PencilCircleIcon } from 'assets/pencil-orange-circle.svg';
import { useTheme } from '@mui/material';

export interface ProgressIndicatorProps<StepType extends string> {
  variant?: VariantType;
  steps: StepIndicatorType<StepType>[];
  onClickStep: (stepLabel: StepType) => void;
  noIcon?: boolean;
  fitContent?: boolean;
}

function renderIcon(
  themeVariant: any,
  isActive?: boolean,
  isComplete?: boolean,
  isDisabled?: boolean,
  isInvalid?: boolean,
  isDirty?: boolean
) {
  if (isDirty) {
    return <PencilCircleIcon title="complete step" />;
  }
  if (isActive && isComplete) {
    if (isInvalid)
      return (
        <ExclamationCircleIcon
          style={{ fill: themeVariant.interactive.fill.primary.enabled }}
          title="invalid step"
        />
      );
    return (
      <CheckCircleIcon
        style={{ fill: themeVariant.interactive.fill.primary.enabled }}
        title="complete step"
      />
    );
  }

  if (isActive || isDisabled || (!isInvalid && !isComplete)) return <CircleIcon />;

  return isInvalid ? (
    <ExclamationCircleIcon
      style={{ fill: themeVariant.interactive.fill.primary.enabled }}
      title="invalid step"
    />
  ) : (
    <CheckCircleIcon
      style={{ fill: themeVariant.interactive.fill.primary.enabled }}
      title="complete step"
    />
  );
}

function ProgressIndicator<StepType extends string>({
  onClickStep,
  steps,
  noIcon,
  fitContent,
}: ProgressIndicatorProps<StepType>) {
  const theme = useTheme();

  return (
    <MuiStyledIndicatorContainer width={fitContent ? 'fit-content' : undefined}>
      {steps.map((step) => (
        <MuiStyledIndicator
          tabIndex={0}
          key={step.label}
          disabled={step.isDisabled}
          active={step.isActive ? 'true' : 'false'}
          onClick={() => !step.isDisabled && onClickStep(step.id)}
          onKeyDown={(event) => {
            event.key === 'Enter' && !step.isDisabled && onClickStep(step.id);
          }}
        >
          {!noIcon &&
            renderIcon(
              theme.customPalette,
              step.isActive,
              step.isComplete,
              step.isDisabled,
              step.isInvalid,
              step.isDirty
            )}
          {step.label}
        </MuiStyledIndicator>
      ))}
    </MuiStyledIndicatorContainer>
  );
}

export default ProgressIndicator;
