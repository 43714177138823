import { Box, Typography } from '@mui/material';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { CustodyCardTableRow } from './CustodyCardTableRow';
import { Card } from 'components/Card/Card';
import { NAVDetailsWithProduct } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { CustodyCardRowsEnum } from './utils';

export interface CustodyCardInterface {
  _id: string;
  name: string;
  closingBalance: string;
  netTransactions: string;
  openingBalance: string;
}

export const CustodyCard = ({ navReviewItem }: { navReviewItem: NAVDetailsWithProduct }) => {
  const custodyCardData = navReviewItem?.custodianBalances?.map((custodianBalance) => ({
    name: custodianBalance.custodian,
    closingBalance: custodianBalance.balance,
    netTransactions: custodianBalance.totals.netTransactions,
    openingBalance: custodianBalance.totals.openBalance,
  }));

  const hasContent = Boolean(custodyCardData?.length);

  const mapCardDataToTableRows = (
    custodyCardData: {
      name: string;
      closingBalance: string;
      netTransactions: string;
      openingBalance: string;
    }[]
  ) => {
    return (
      custodyCardData?.map((item) => ({
        label: item.name,
        propName: item.name,
        propType: 'string',
        minWidth: '140px',
        sort: false,
      })) ?? []
    );
  };

  const columnConfig = [
    {
      label: 'Name',
      propName: 'name',
      propType: 'string',
      minWidth: '140px',
      sort: false,
    },
    ...mapCardDataToTableRows(custodyCardData),
  ];
  return (
    <Card
      noPadding
      header={
        <Box p={3} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Typography variant="subheadingMedium">Custody</Typography>
          <Typography variant="subheadingMedium">
            {navReviewItem?.custodianTotals?.balance}
          </Typography>
        </Box>
      }
      body={
        <Box p={3}>
          <Table noPadding>
            <TableHeaderWithMultiSort columns={columnConfig} />
            <TableBodyWithStates loadingData={Boolean(false)} hasContent={hasContent}>
              {hasContent &&
                Object.values(CustodyCardRowsEnum)?.map((custody, index) => (
                  <CustodyCardTableRow
                    key={index}
                    custodyData={custodyCardData}
                    columns={columnConfig}
                    rowName={custody}
                  />
                ))}
            </TableBodyWithStates>
          </Table>
        </Box>
      }
    />
  );
};
