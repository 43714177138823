import { TransactionLedger, TransactionType } from './nav';

export interface UnrealizedAccruedFee {
  product: {
    ticker: string;
    fundIcon?: string;
    _id: string;
  };
  constituentAsset: {
    ticker: string;
    _id: string;
  };
  transactions: { data: TransactionLedger[]; page: number; pageSize: number; total: number };
  totalUnrealizedAccruedFee: string;
  lastWithdrawTransaction: TransactionLedger | null;
}

export interface WithdrawUnrealizedAccruedFee {
  productId: string;
  constituentTicker: string;
  amount?: string;
}

export const unrealizedAccruedFeeTransactionsTypes = [
  { label: 'All Transactions', value: undefined },
  { label: 'Management Fee', value: TransactionType.MANAGEMENT_FEE },
  { label: 'Earnings Fee', value: TransactionType.EARN_FEE },
  { label: 'MEV Fee', value: TransactionType.MEV_FEE },
  { label: 'Withdraw', value: TransactionType.WITHDRAW },
];
