import { Actions, BaseEntry, Instrument, Links, Order } from 'utils/types';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';

export const NavReviewStateLabel: Record<string, string> = {
  APPROVED: 'Approved',
  PENDING: 'Discrepancies',
};

export interface PortfolioCompositionProduct {
  _id: string;
  issuer: {
    id: string;
  };
  fundIcon?: string;
  baseCurrency: string;
  isin: string;
  name: string;
  ticker: string;
  unitSize: number;
}

export interface NAVRevieItemDifferences {
  closingBalance: string;
  netOrders: string;
  stakingRewards: string;
}

export interface CustodyBalances {
  name: string; // column
  balance: string; // closingBalance in Custodian table
  custodian: string;
  createdAt: string;
  createdBy: string;
  ticker: string;
  transactions: string;
  updatedAt: string;
  _id: string;
  totals: {
    netTransactions: string;
    openBalance: string;
    netUnsettledDeferredOrders: string;
    accuredUnrealizedFees: string;
    difference: string;
    _id: string;
  };
}

export interface NAVReviewItem {
  readonly _id: string;
  readonly break?: string;
  readonly portfolioId: string;
  readonly openOrders?: Order[];
  readonly ticker: string;
  readonly ledgerBalance: number | string;
  readonly fundAccountantBalance: number | string;
  readonly differences: NAVRevieItemDifferences;
  readonly status: PortfolioCompositionStatus;
  readonly transactions?: TransactionLedger[];
  readonly totals?: NetAssetValueTotals;
  readonly fundAccountantTotals?: NetAssetValueTotals;
  readonly _links?: Links<'self'>;
  readonly _actions?: Actions<'approve' | 'calculateNetAssetValue' | 'rebook' | 'rebookEstimation'>;
  isRebalanceApproved?: boolean;
  custodianTotals: {
    balance: string; // closingBalance in top right corner of Custodians table
    netTransactions: string;
    openBalance: string;
    netUnsettledDeferredOrders: string; // data to be used in Reconciliation table
    accuredUnrealizedFees: string; // data to be used in Reconciliation table
    difference: string; // data to be used in Reconciliation table
  };
  custodianBalances: CustodyBalances[];
  product?: Pick<Instrument, 'name' | 'ticker' | '_id' | 'constituentAssets' | 'fundIcon'>;
  isSignificantBreak?: Boolean;
}

export interface NAVReviewResponseDto {
  netAssetValueComparison: NAVReviewItem[];
  product: PortfolioCompositionProduct;
  _id: string;
}

export interface NAVReviewItemWithProduct extends NAVReviewItem {
  readonly product: PortfolioCompositionProduct;
  readonly portfolioId: string;
}

export interface CompositionBalance extends BaseEntry {
  ticker: string;
  balance: number;
  readonly approvedAt: string;
}

export enum TransactionLedgerType {
  STAKE = 'STAKE',
  ORDER_CREATION = 'ORDER_CREATION',
  ORDER_REDEMPTION = 'ORDER_REDEMPTION',
  FEE = 'FEE',
}

export enum TransactionSource {
  ONYX_ORDERS = 'onyx-orders',
  ONYX_STAKING = 'onyx-staking',
  ONYX_FEES = 'onyx-fees',
  REBALANCE = 'rebalance',
  REBOOK = 'rebook',
}

export interface OnyxMetadata {
  orderId?: string;
  tradeId?: string;
  productId?: string;
  rewardId?: string;
  cancelledAt?: Date;
  cancels?: string;
  reason?: string;
  rewardCustodian?: string;
}

export interface TransactionLedger extends BaseEntry {
  accountId: string;
  amount: string;
  createdBy?: string;
  createdAt: string;
  currency: string;
  externalId?: string;
  orderId?: string;
  source: TransactionSource;
  type: TransactionType;
  onyx: OnyxMetadata;
  _id: string;
  isProjected?: boolean;
}

export interface NetAssetValueTotals {
  readonly deltaNetAssetValue?: number | string;
  readonly managementFee?: number | string;
  readonly netOrders?: number | string;
  readonly openBalance?: number | string;
  readonly tradeValue?: number | string;
  readonly stakingFees?: number | string;
  readonly stakingRewards?: number | string;
  readonly netIncome?: number | string;
  unrealizedAccruedFee: string;
  unsettledDeferredOrders: string;
}

export enum TransactionType {
  ETP_CREATION = 'ETP_CREATION',
  ETP_REDEMPTION = 'ETP_REDEMPTION',
  ETP_TRADE = 'ETP_TRADE',
  ETP_TRADE_SLIPPAGE = 'ETP_TRADE_SLIPPAGE',
  ETP_REMAINDER = 'ETP_REMAINDER',
  ORDER_DELIVERY = 'ORDER_DELIVERY',
  GAS_FEE = 'GAS_FEE',
  EARN_REWARD = 'EARN_REWARD',
  EARN_FEE = 'EARN_FEE',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  TRADING_FEE = 'TRADING_FEE',
  REBALANCE = 'REBALANCE',
  ADJUST_EARN_FEE = 'ADJUST_EARN_FEE',
  REBOOK_BALANCE_ADJUST = 'REBOOK_BALANCE_ADJUST',
  REBOOK_STAKING_REWARD_ADJUST = 'REBOOK_STAKING_REWARD_ADJUST',
  REBOOK_NET_ORDERS_ADJUST = 'REBOOK_NET_ORDERS_ADJUST',
  REBOOK = 'REBOOK',
  MEV_REWARD = 'MEV_REWARD',
  MEV_FEE = 'MEV_FEE',
  TOKEN_MINT = 'TOKEN_MINT',
  TOKEN_BURN = 'TOKEN_BURN',
  WITHDRAW = 'WITHDRAW',
  FEE_ADJUST = 'FEE_ADJUST',
}

export enum NAVReviewItemsRows {
  OPENING_NAV = 'Opening NAV',
  MANAGEMENT_FEE = 'Management Fees',
  NET_ORDERS = 'Net Orders',
  STAKING_FEES = 'Staking Fees',
  STAKING_REWARDS = 'Staking Rewards',
  REBALANCE_TRADES = 'Rebalance Trades',
  REBOOK_BALANCE_ADJUST = 'NAV Review Adjustment',
}

export interface NavStatusBarInfo {
  total: number;
  totalApproved: number;
  totalWithError: number;
}
