/* eslint-disable complexity */
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { Fragment, useMemo } from 'react';
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';
import { getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';
import { Row } from 'components/Grid';
import { StyledColumn } from './ReviewStep.styles';
import { Button } from 'components/Button/Button';
import {
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  DOCUMENTS_FINAL_TERMS_STEP,
  DOCUMENTS_PCF_STEP,
  EXCHANGE_STEP,
  EtpDetailsStepType,
  FACTSHEET_STEP,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import Table from 'components/Table';
import formatFieldName from './utils/formatFieldName';
import renderFieldValue from './utils/renderFieldValue';
import renderConstituentValue from './utils/renderConstituentValue';
import yesOrNo from 'utils/decorators/yesOrNo';
import renderLocalTickersValue from './utils/renderLocalTickersValue';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { ReviewStepColumnsConfig } from './ReviewStepColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { ReactComponent as DocumentIcon } from 'assets/document.svg';

export interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: EtpDetailsStepType) => void;
}

const ProductReviewHeader = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        height: '64px',
        width: '100%',
        padding: theme.spacing(2),
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
};

function ReviewStep({ goBack, goToStep }: ReviewStepProps) {
  const navigate = useNavigate();
  const params = useParams();
  const { instrument } = useCurrentInstrument();
  const instrumentFormsData = useMemo(() => parseInstrumentToFormData(instrument), [instrument]);
  const goToPartners = () => navigate(getPartnerDetailsUrl(params.id));

  return (
    <>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="General Details" />
          <IconButton onClick={() => goToStep(GENERAL_DETAILS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>

      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(
            Object.entries(instrumentFormsData[GENERAL_DETAILS_STEP] || {}).length
          )}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[GENERAL_DETAILS_STEP] || {}).map(([key, value]) => (
            <TableRow data-qa-id={key} key={key}>
              <TableCell>{formatFieldName(key)}</TableCell>
              <TableCell data-qa-id={value}>
                {renderFieldValue(value, key, instrument?.ticker)}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Market Details" />
          <IconButton onClick={() => goToStep(MARKET_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(Object.entries(instrumentFormsData[MARKET_STEP] || {}).length)}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[MARKET_STEP] || {}).map(([key, value]) => (
            <TableRow data-qa-id={key} key={key}>
              <TableCell>{formatFieldName(key)}</TableCell>
              <TableCell data-qa-id={value}>{renderFieldValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      {instrument?.type !== 'Single Asset' && (
        <>
          <ProductReviewHeader>
            <Stack alignItems="center" spacing={1} direction="row">
              <ProductConfigHeader title="Benchmark Details" />
              <IconButton onClick={() => goToStep(BENCHMARK_STEP)}>
                <PencilIcon />
              </IconButton>
            </Stack>
          </ProductReviewHeader>
          <Table>
            {Boolean(Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).length) && (
              <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
            )}
            <TableBodyWithStates
              hasContent={Boolean(Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).length)}
              noContentLabel="No data at the moment."
            >
              {Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).map(([key, value]) => (
                <TableRow data-qa-id={key} key={key}>
                  <TableCell>{formatFieldName(key)}</TableCell>
                  <TableCell data-qa-id={value}>{renderFieldValue(value)}</TableCell>
                </TableRow>
              ))}
            </TableBodyWithStates>
          </Table>
        </>
      )}
      <ProductReviewHeader>
        <Stack direction="row">
          <ProductConfigHeader
            title={
              (instrumentFormsData[CONSTITUENT_STEP] || []).length > 1
                ? 'Constituents'
                : 'Constituent'
            }
          />
          <IconButton onClick={() => goToStep(CONSTITUENT_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean((instrumentFormsData[CONSTITUENT_STEP] || []).length)}
          noContentLabel="No data at the moment."
        >
          {(instrumentFormsData[CONSTITUENT_STEP] || []).map((constituent) => (
            <TableRow data-qa-id={constituent.name} key={constituent.name}>
              <TableCell>{formatFieldName(constituent.ticker)}</TableCell>
              <TableCell>{renderConstituentValue(constituent)}</TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Exchanges" />
          <IconButton onClick={() => goToStep(EXCHANGE_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean((instrumentFormsData[EXCHANGE_STEP] || []).length)}
          noContentLabel="No data at the moment."
        >
          {(instrumentFormsData[EXCHANGE_STEP] || []).map((exchange) => (
            <Fragment key={exchange.name}>
              <Stack height="54px" display="flex" justifyContent="center" width="100%" padding={2}>
                <Typography variant="labelLargeStrong">{exchange.name}</Typography>
              </Stack>
              <TableRow>
                <TableCell>Primary Exchange</TableCell>
                <TableCell>{yesOrNo(exchange.isPrimary).toUpperCase()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Local Tickers</TableCell>
                <TableCell>{renderLocalTickersValue(exchange.localTickers)}</TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Final Terms" icon={<DocumentIcon />} />
          <IconButton onClick={() => goToStep(DOCUMENTS_FINAL_TERMS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(
            Object.entries(instrumentFormsData[DOCUMENTS_FINAL_TERMS_STEP] || {}).length
          )}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[DOCUMENTS_FINAL_TERMS_STEP] || {}).map(
            ([key, value]) => (
              <TableRow data-qa-id={key} key={key}>
                <TableCell>{formatFieldName(key)}</TableCell>
                <TableCell data-qa-id={value}>
                  {renderFieldValue(value, key, instrument?.ticker)}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Factsheet Details" />
          <IconButton onClick={() => goToStep(FACTSHEET_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        {Boolean(Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).length) && (
          <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        )}
        <TableBodyWithStates
          hasContent={Boolean(Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).length)}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).map(([key, value]) => (
            <TableRow data-qa-id={key} key={key}>
              <TableCell>{formatFieldName(key)}</TableCell>
              <TableCell data-qa-id={value}>{renderFieldValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <ProductReviewHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="PCF Details" icon={<DocumentIcon />} />
          <IconButton onClick={() => goToStep(DOCUMENTS_PCF_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </ProductReviewHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(Object.entries(instrumentFormsData[DOCUMENTS_PCF_STEP] || {}).length)}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[DOCUMENTS_PCF_STEP] || {}).map(([key, value]) => (
            <TableRow data-qa-id={key} key={key}>
              <TableCell>{formatFieldName(key)}</TableCell>
              <TableCell data-qa-id={value}>
                {renderFieldValue(value, key, instrument?.ticker)}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <Row>
        <StyledColumn offset={8} cols={2}>
          <Button
            data-qa-id="backButton"
            fullWidth
            type="button"
            variant="secondary"
            onClick={goBack}
          >
            Back
          </Button>
        </StyledColumn>
        <StyledColumn cols={2}>
          <Button
            data-qa-id="nextButton"
            fullWidth
            type="button"
            variant="primary"
            onClick={goToPartners}
          >
            Next
          </Button>
        </StyledColumn>
      </Row>
    </>
  );
}

export default ReviewStep;
