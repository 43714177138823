import Product from 'components/Product';
import { TableCell } from 'components/Table/TableCell';
import { CustodyBalances, NAVReviewItemWithProduct, NavReviewStateLabel } from 'utils/types/nav';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { TableRow } from 'components/Table/TableRow';
import { useUserPermissions } from 'store/user/selectors';
import Tag from 'components/Tag/Tag';
import { Box, Stack, Typography } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { common } from 'theme/utils/paletteColors';

type Props = {
  navReviewItem: NAVReviewItemWithProduct;
  editAction: () => void;
};

const getTagLabel = (custodiansNumber: number) => {
  if (custodiansNumber === 1) return `${custodiansNumber} custodian`;
  return `${custodiansNumber} custodians`;
};

const renderCustodyBalances = (custodyBalances: CustodyBalances[]) => {
  return (
    <>
      <Tooltip
        title={
          <Box p={1}>
            {custodyBalances?.length ? (
              custodyBalances.map((entry, index) => (
                <Stack direction="row" spacing={1} key={`${index}-${entry.custodian}`}>
                  <Typography color={common.white} variant="bodyMedium">
                    {entry.custodian}:
                  </Typography>
                  <Typography color={common.white} variant="bodyMedium">
                    {entry.balance ?? '-'}
                  </Typography>
                </Stack>
              ))
            ) : (
              <Stack direction="row" spacing={1} key={'empty-custody-balances'}>
                <Typography color={common.white} variant="bodyMedium">
                  Content Unavailable
                </Typography>
              </Stack>
            )}
          </Box>
        }
        bgColor="black"
        placement="bottom"
        arrow
      >
        {custodyBalances?.length ? (
          <Tag label={getTagLabel(custodyBalances?.length)} variant="blue" />
        ) : (
          '-'
        )}
      </Tooltip>
    </>
  );
};

export const NAVTableRow = ({ navReviewItem, editAction }: Props) => {
  const user = useUserPermissions();

  return (
    <TableRow
      data-qa-id={`row-${navReviewItem._id}`}
      key={navReviewItem._id}
      onClick={() => editAction()}
    >
      {/* Product */}
      <TableCell data-qa-id={`product-${navReviewItem.product.name}`} nowrap={'true'}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Product instrument={navReviewItem.product} />
        </Box>
      </TableCell>
      {/* Constituent */}
      <TableCell data-qa-id={`constituent-${navReviewItem.ticker}`}>
        {navReviewItem.ticker}
      </TableCell>
      {/* Onyx Balance */}
      {!user?.isFundAccount && (
        <TableCell data-qa-id={`onyx-nav-${navReviewItem.ledgerBalance}`} align="right">
          {navReviewItem.ledgerBalance}
        </TableCell>
      )}
      {/* Fund Accountant Balance*/}
      <TableCell data-qa-id={`accountant-nav-${navReviewItem.fundAccountantBalance}`} align="right">
        {navReviewItem.fundAccountantBalance}
      </TableCell>
      {/* Custody */}
      {!user?.isFundAccount && (
        <TableCell data-qa-id={`difference-${navReviewItem.differences}`} align="right">
          {renderCustodyBalances(navReviewItem?.custodianBalances) ?? '-'}
        </TableCell>
      )}
      {/* State */}
      <TableCell data-qa-id={`state-${navReviewItem.status}`}>
        <Tag
          label={NavReviewStateLabel[navReviewItem.status]}
          variant={navReviewItem.status === PortfolioCompositionStatus.APPROVED ? 'green' : 'red'}
        />
      </TableCell>
    </TableRow>
  );
};
