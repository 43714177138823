import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import { Row, Column } from 'components/Grid';
import ProgressIndicator, { ProgressIndicatorProps } from 'components/ProgressIndicator';
import FinalTermsStep from './FinalTerms';
import {
  DOCUMENTS_FINAL_TERMS_STEP,
  EtpDetailsStepType,
  FACTSHEET_STEP,
  DOCUMENTS_PCF_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { useActiveStep } from 'store/instruments/selectors';
import FactsheetStep from 'pages/Instruments/components/Form/EtpDetails/components/FactsheetStep';
import PcfStep from 'pages/Instruments/components/Form/EtpDetails/components/DocumentsStep/Pcf';
import { ReactComponent as DocumentIcon } from 'assets/document.svg';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

interface DocumentsStepProps {
  goBack: () => void;
  onSubmit: () => void;
  goToStep: (step: EtpDetailsStepType) => void;
}

const tab = [
  { id: DOCUMENTS_FINAL_TERMS_STEP, label: DOCUMENTS_FINAL_TERMS_STEP, component: FinalTermsStep },
  { id: FACTSHEET_STEP, label: FACTSHEET_STEP, component: FactsheetStep },
  { id: DOCUMENTS_PCF_STEP, label: DOCUMENTS_PCF_STEP, component: PcfStep },
] as const;

type TabType = (typeof tab)[number]['id'];

function DocumentsStep({ goBack, onSubmit, goToStep }: DocumentsStepProps) {
  const activeStep = useActiveStep('ETPDetails') as EtpDetailsStepType;

  const progress: ProgressIndicatorProps<TabType>['steps'] = tab.map(({ id, label }) => ({
    id,
    label,
    isActive: id === activeStep,
  }));

  const Tab = tab.find(({ id }) => id === activeStep)!.component;

  return (
    <>
      <FormGridContainer paddingBottom={0}>
        <FormGridItem>
          <ProductConfigHeader title="Documents" icon={<DocumentIcon />} />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <Row>
          <Column>
            <ProgressIndicator fitContent noIcon onClickStep={goToStep} steps={progress} />
          </Column>
        </Row>
      </FormGridContainer>
      <Tab key={activeStep} onSubmit={onSubmit} goBack={goBack} />
    </>
  );
}

export default DocumentsStep;
