import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';

const HistoryContext = createContext<Location[]>([]);

const MAX_HISTORY_LENGTH = 30;

export const useHistoryContext = () => {
  return useContext(HistoryContext);
};

export const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [history, setHistory] = useState<Location[]>([]);

  useEffect(() => {
    setHistory((prevHistory) => {
      if (
        prevHistory.length &&
        prevHistory[prevHistory.length - 1].pathname === location.pathname &&
        prevHistory[prevHistory.length - 1].search === location.search
      ) {
        return prevHistory; // Avoid duplicate entries
      }

      const updatedHistory =
        prevHistory.length >= MAX_HISTORY_LENGTH
          ? [...prevHistory.slice(1), location]
          : [...prevHistory, location];

      return updatedHistory;
    });
  }, [location]);

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
};
