import { TableCell } from 'components/Table';
import { useState } from 'react';
import { InputWithCustomButtons } from './components/InputWithCustomButtons';
import { Box, Typography } from '@mui/material';
import Link from 'components/Link';
import { red } from 'theme/colors/baseColors';

interface EditableTableCellProps {
  value: string | number;
  initialOverrideValue?: string | number;
  editable?: Boolean;
  onConfirm: (value: string | number) => void;
  onDecline?: () => void;
  formatedValue?: string;
  tooltip?: React.ReactNode;
  hasDifference?: Boolean;
}

export const OverrideInput = ({
  value,
  initialOverrideValue = 0,
  editable,
  onConfirm,
  onDecline,
  formatedValue,
  tooltip,
  hasDifference = false,
}: EditableTableCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const editTableCell = editMode ? (
    <TableCell sx={{ padding: '0', alignSelf: 'left' }}>
      <InputWithCustomButtons
        value={initialOverrideValue}
        onConfirm={(val) => {
          setEditMode(false);
          val && onConfirm(val);
        }}
        onDecline={() => {
          setEditMode(false);
          onDecline && onDecline();
        }}
      />
    </TableCell>
  ) : (
    <TableCell sx={{ padding: '0', alignSelf: 'left' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Typography style={{ color: hasDifference ? red[70] : 'inherit' }}>{value}</Typography>
        <Link
          text={'Override'}
          onClick={() => {
            setEditMode(true);
          }}
          type="block"
          variant="primary"
        />
      </Box>
    </TableCell>
  );

  return editable ? (
    editTableCell
  ) : (
    <TableCell align="left">
      {formatedValue ?? value} {tooltip}
    </TableCell>
  );
};
