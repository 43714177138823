/* eslint-disable complexity */

import { Button } from 'components/Button/Button';
import Input from 'components/Input';
import Notification from 'components/Notification';
import useToggle from 'hooks/useToggle';
import {
  ETPType,
  GeneralConstituentAssetFormType,
  isEtpConstituent,
  ProductConstituentAssetType,
} from 'utils/types/product';
import { ConstituentModalData } from 'shared/Modals/Constituents/types';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductStatus, RebalanceStrategy } from 'utils/types/product';
import { Toggle } from 'components/BooleanInputs';
import Select from 'components/Select';
import { useAssetsOptions } from 'hooks/useAssetsQuery';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Column, Row } from 'components/Grid';
import { Box, Divider, Stack, styled } from '@mui/material';
import {
  instrumentConstituentAssetsSchemaUrl,
  tokenConstituentAssetsSchemaUrl,
} from 'components/Form/formSchemas';
import Form from 'components/Form/Form';
import { useState } from 'react';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { useProductData } from 'hooks/useProductData';
import { useMutation } from 'react-query';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { PartialInstrumentFormProps } from 'pages/Instruments/Instruments.types';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';

export const CustomEditConstituentModal = ({
  closeModal,
  data,
  onCloseModalAction,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { constituent } = data?.data as ConstituentModalData;
  const { constituents } = data?.data as ConstituentModalData;
  const productId = data?.custom?.productId as string;
  const { data: product, isLoading: isLoadingProduct } = useProductData(productId);
  const { assetsOptions, isLoadingAssets, setFilterText } = useAssetsOptions();
  const [isLeveraged, toggleIsLeveraged] = useToggle(Boolean(constituent?.isLeveraged));
  const [values, setValues] = useState((constituent as GeneralConstituentAssetFormType) ?? {});

  const etpMutation = useMutation({
    mutationFn: (instrumentProps: PartialInstrumentFormProps) => {
      return saveInstrumentApiCall(product?._actions?.update?.uri ?? '', instrumentProps);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Product ${res.ticker} has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(
        createNotification(
          {
            message: `Error - other mandatory fields missing (${err.message ?? ''})`,
            title: 'Asset not added',
            type: 'error',
          },
          err
        )
      );
    },
  });

  if (!productId) return null;

  const isStaking = values?.isStaking;
  const isCustodyReconciliationEligible = values?.isCustodyReconciliationEligible;
  const isSingleAssetStrategy =
    product &&
    'rebalancingStrategy' in product &&
    (product?.rebalancingStrategy?.type === RebalanceStrategy.SHORT_STRATEGY ||
      product?.rebalancingStrategy?.type === RebalanceStrategy.LEVERAGED_STRATEGY);

  const handleSubmit = (constituent: GeneralConstituentAssetFormType) => {
    const updatedConstituents = constituents?.map((con) => {
      if (con._id === String(constituent._id)) return constituent;
      return con;
    });
    etpMutation?.mutate({
      [CONSTITUENT_STEP]: updatedConstituents as ProductConstituentAssetType[],
    });
  };

  const Footer = (
    <>
      <MuiStyledModalFooterButtons>
        <Button variant="secondary" onClick={closeModal} type="button">
          Cancel
        </Button>
        <Button type="submit" isLoading={etpMutation?.isLoading} variant="primary">
          {product?.status === ProductStatus.ACTIVE ? 'Save Changes' : 'Save'}
        </Button>
      </MuiStyledModalFooterButtons>
      {isEtpConstituent(constituent) && constituent?.updatedBy && constituent?.updatedAt && (
        <StyledUpdateNote>
          Last update by: {constituent?.updatedBy!} at {constituent?.updatedAt}
        </StyledUpdateNote>
      )}
    </>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <CardHeaderTitleWithLabel label="CONSTITUENT" title={`Configure ${constituent?.name}`} />
        }
        body={
          <Form
            initialValues={{ ...constituent }}
            schemaUrl={
              isEtpConstituent(constituent)
                ? instrumentConstituentAssetsSchemaUrl
                : tokenConstituentAssetsSchemaUrl
            }
            onSubmit={handleSubmit}
            onChange={setValues}
            loading={isLoadingProduct}
          >
            <Stack padding={2} gap={1}>
              <Row>
                {isEtpConstituent(constituent) && (
                  <Column gutter={'noGutter'}>
                    <Toggle name="isActive" />
                  </Column>
                )}
              </Row>
              <Column gutter={'noGutter'}>
                <Input name="rounding" />
              </Column>
              <Row>
                <Column gutter={'noGutter'}>
                  <Toggle name="isStaking" />
                </Column>
              </Row>
              {isStaking && (
                <>
                  <Column gutter={'noGutter'}>
                    <Input name="stakingFee" step="any" />
                  </Column>
                </>
              )}
              {isStaking && (
                <>
                  <Column gutter={'noGutter'}>
                    <Toggle name="isMev" />
                  </Column>
                </>
              )}
              {isEtpConstituent(constituent) && (
                <>
                  <Row>
                    <Column gutter={'noGutter'}>
                      <Toggle name="isCustodyReconciliationEligible" />
                    </Column>
                  </Row>
                  {isCustodyReconciliationEligible && (
                    <>
                      <Column gutter={'noGutter'}>
                        <Input name="custodyReconciliationThreshold" step="any" />
                      </Column>
                    </>
                  )}
                </>
              )}
              <Column gutter={'noGutter'}>
                <Toggle name="interestGenerating" />
              </Column>
              {isSingleAssetStrategy && (
                <>
                  <Column gutter={'noGutter'}>
                    <Toggle name="isLeveraged" onChange={toggleIsLeveraged} />
                  </Column>
                  {isLeveraged && (
                    <Row>
                      <Column gutter={'noGutter'}>
                        <Input name="leverageRatio" />
                      </Column>
                      <Column gutter={'onlyRight'}>
                        <Select
                          filterable
                          name="pairAsset"
                          options={assetsOptions}
                          placeholder="USD"
                          onFilter={setFilterText}
                          disabled={isLoadingAssets}
                        />
                      </Column>
                    </Row>
                  )}
                </>
              )}
              {product?.type !== ETPType.SINGLE_ASSET && (
                <>
                  <Column gutter={'noGutter'}>
                    <Input name="rebalanceFeeBuyBps" />
                  </Column>
                  <Column gutter={'noGutter'}>
                    <Input name="rebalanceFeeSellBps" />
                  </Column>
                </>
              )}
              <Notification
                message={`Updating rounding will impact all Onyx generated amounts for ${constituent?.name}`}
                title="Changing Rounding"
                variant="warning"
              />
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>{Footer}</Box>
          </Form>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};

export const StyledUpdateNote = styled('div')(({ theme }) => ({
  marginInline: 16,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.palette.neutral[50],
  marginTop: 16,
  textAlign: 'center',
}));
