import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const primaryButton = (theme: Theme) => {
  const primaryButton = theme.customComponents.buttonPalette.primary;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'primary' && (!props.emphasis || props.emphasis === 'high'),
      style: {
        color: primaryButton.highEmphasis.color,
        backgroundColor: primaryButton.highEmphasis.backgroundColor,
        borderRadius: primaryButton.highEmphasis.borderRadius,
        '&:hover': {
          backgroundColor: primaryButton.highEmphasis.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: primaryButton.highEmphasis.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: primaryButton.highEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: primaryButton.highEmphasis.disabled?.color,
          backgroundColor: primaryButton.highEmphasis.disabled?.backgroundColor,
          '& svg': {
            fill: primaryButton.highEmphasis.disabled?.color,
          },
        },
        span: { color: primaryButton.highEmphasis.color },
        '& svg': {
          fill: primaryButton.highEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'primary' && props.emphasis === 'medium',
      style: {
        color: primaryButton.mediumEmphasis.color,
        border: primaryButton.mediumEmphasis.border,
        borderRadius: primaryButton.mediumEmphasis.borderRadius,
        '&:hover': {
          color: primaryButton.mediumEmphasis.hover?.color,
          border: primaryButton.mediumEmphasis.hover?.border,
          '& svg': {
            fill: primaryButton.mediumEmphasis.hover?.color,
          },
        },
        '&:active': {
          color: primaryButton.mediumEmphasis.active?.color,
          border: primaryButton.mediumEmphasis.active?.border,
          '& svg': {
            fill: primaryButton.mediumEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          border: primaryButton.mediumEmphasis.focusVisible?.border,
          boxShadow: primaryButton.mediumEmphasis.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: primaryButton.mediumEmphasis.disabled?.color,
          border: primaryButton.mediumEmphasis.disabled?.border,
          '& svg': {
            fill: primaryButton.mediumEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: primaryButton.mediumEmphasis.color,
        },
      },
    },
    {
      props: (props: { variant: string; emphasis: Emphasis }) =>
        props.variant === 'primary' && props.emphasis === 'low',
      style: {
        color: primaryButton.lowEmphasis.color,
        borderRadius: primaryButton.lowEmphasis.borderRadius,
        '&:hover': {
          backgroundColor: primaryButton.lowEmphasis.hover?.backgroundColor,
        },
        '&:active': {
          color: primaryButton.lowEmphasis.active?.color,
          backgroundColor: primaryButton.lowEmphasis.active?.backgroundColor,
          '& svg': {
            fill: primaryButton.lowEmphasis.active?.color,
          },
        },
        '&:focus-visible': {
          boxShadow: primaryButton.lowEmphasis.focusVisible?.boxShadow,
          backgroundColor: primaryButton.lowEmphasis.focusVisible?.backgroundColor,
        },
        '&.Mui-disabled': {
          color: primaryButton.lowEmphasis.disabled?.color,
          backgroundColor: primaryButton.lowEmphasis.disabled?.backgroundColor,
          '& svg': {
            fill: primaryButton.lowEmphasis.disabled?.color,
          },
        },
        '& svg': {
          fill: primaryButton.lowEmphasis.color,
        },
      },
    },
  ];
};
