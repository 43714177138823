export const ReconciliationCardTableConfig = [
  { label: 'Custody', propName: 'total', propType: 'string', minWidth: '140px', sort: false },
  {
    label: 'NAV',
    propName: 'nav',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  {
    label: 'Deferred Orders',
    propName: 'deferredOrders',
    propType: 'string',
    minWidth: '180px',
    sort: false,
    showInfoIcon: true,
  },
  {
    label: 'Accrued Unrealized Fee',
    propName: 'accruedUnrealizedFee',
    propType: 'date',
    width: '18%',
    sort: false,
  },
  { label: 'Break', propName: 'break', propType: 'string', width: '10%', sort: false },
];
