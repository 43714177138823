import { UnrealizedAccruedFee } from 'utils/types/fees';

export function downloadFeesCsv(fee: UnrealizedAccruedFee | undefined) {
  let csvData = [fee?.product.ticker] + '\n\n';
  csvData =
    csvData +
    ['Unrealized Accrued Fees', `${fee?.totalUnrealizedAccruedFee} OP`].join(',') +
    '\n\n';

  csvData = csvData + ['Type', 'Date', 'Amount', 'Currency'].join(',') + '\n';
  fee?.transactions.data.forEach((item) => {
    const rowData = [item.type, item.createdAt, item.amount, item.currency];
    csvData += rowData.join(',') + '\n';
  });
  const csvContentArray = csvData.split('\n').map((row) => row.split(','));

  const csvContent =
    'data:text/csv;charset=utf-8,' + csvContentArray.map((row) => row.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fee?.product.ticker}.csv`);
  document.body.appendChild(link);

  link.click();
}
