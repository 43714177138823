export const FeesDetailsTableColumnsConfig = [
  {
    label: 'Transaction',
    propName: 'type',
    propType: 'string',
    width: '60%',
    sort: true,
  },
  {
    label: 'Date',
    propName: 'createdAt',
    propType: 'date',
    sort: true,
    width: 'auto',
  },
  {
    label: 'Amount',
    propName: 'amount',
    propType: 'string',
    width: 'auto',
    sort: false,
  },
];
