import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { omitBy, isNil } from 'lodash';

export type ParamsType = Record<string, string | null | string[] | number> & {
  sort?: string | string[] | null;
  page?: number | null;
  pageSize?: number | null;
  search?: string | null;
};

export type SetURLParams = React.Dispatch<React.SetStateAction<ParamsType>>;

const getParamFromQuery = (param: string | null) => {
  if (param) {
    if (param.includes(',')) {
      return param.split(',');
    } else if (isNaN(Number(param))) {
      return param;
    } else {
      return Number(param);
    }
  }

  return null;
};

const getQueryParam = (key: string, value: string) =>
  value ? `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}` : '';

export const getQueryString = (filters: Record<string, string | null | Array<string> | number>) =>
  Object.keys(filters)
    .filter(
      (param) =>
        filters[param] !== undefined &&
        filters[param] !== null &&
        filters[param] !== 'undefined' &&
        filters[param] !== 'null'
    )
    .map((key) => {
      const value = Array.isArray(filters[key])
        ? getQueryParam(key, (filters[key] as string[]).join(','))
        : getQueryParam(key, filters[key] as string);

      return value;
    })
    .filter((obj) => obj !== '')
    .join('&');

const extractSearchParams = (
  searchParams: URLSearchParams,
  defaultParams: ParamsType
): ParamsType => {
  return Object.keys(defaultParams).reduce(
    (acc, param) => ({
      ...acc,
      [param]: getParamFromQuery(searchParams.get(param)),
    }),
    defaultParams
  );
};

export const useUrlParams = (
  defaultParams: ParamsType,
  routeUrl: string,
  shouldUseDefaultsWithExtractedParams = false
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const extractedParams = useMemo(
    () => extractSearchParams(searchParams, defaultParams),
    [defaultParams, searchParams]
  );

  const prepareDefaultUrlParams = useCallback(() => {
    if (shouldUseDefaultsWithExtractedParams) {
      return { ...defaultParams, ...omitBy(extractedParams, isNil) };
    }

    return extractedParams.tab ? extractedParams : defaultParams;
  }, [defaultParams, extractedParams, shouldUseDefaultsWithExtractedParams]);

  const [urlParams, setUrlParams] = useState<ParamsType>(prepareDefaultUrlParams());

  const handleParamsChange = useCallback(() => {
    const filters = urlParams as Record<string, string>;
    const queryParams = getQueryString(filters);
    navigate(`${routeUrl}?${queryParams}`);
  }, [urlParams, navigate, routeUrl]);

  const setUrlParamsWithDefaults = useCallback(
    (params: ParamsType) => {
      setUrlParams(() => ({ ...defaultParams, ...params }));
    },
    [defaultParams]
  );

  useEffect(() => {
    handleParamsChange();
  }, [urlParams, handleParamsChange]);

  return { urlParams, setUrlParams, setUrlParamsWithDefaults };
};
