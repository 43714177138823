import { Button } from 'components/Button/Button';
import { ReactComponent as TwoFAIcon } from 'assets/2fa-line-icon.svg';
import Input from 'components/Input';
import { Box, styled as MuiStyled, Stack } from '@mui/material';

export const StyledTwoFAIcon = MuiStyled(TwoFAIcon)(({ theme }) => ({
  marginLeft: 0,
}));

export const StyledRightBottomButton = MuiStyled(Button)(({ theme }) => ({
  width: '50%',
  marginLeft: 'auto',
}));

export const StyledFooterWrapper = MuiStyled(Box)(({ theme }) => ({
  marginTop: theme.spacers['spacing-16'],
  display: 'flex',
  flex: 1,
  gap: '8px',
}));

export const StyledQRCodeWrapper = MuiStyled(Box)(({ theme }) => ({
  width: '160px',
  height: '160px',
  margin: `${theme.spacers['spacing-16']} auto`,
  padding: 0,
  canvas: {
    padding: '8px',
    border: '1px solid black',
  },
}));

export const StyledQRTextWrapper = MuiStyled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.tertiary.dark,
  // width: '352px',
  height: '40px',
  p: {
    margin: 'auto',
    marginLeft: theme.spacers['spacing-16'],
  },
  svg: {
    margin: 'auto',
    marginRight: theme.spacers['spacing-12'],
    ':hover': {
      cursor: 'pointer',
    },
  },
}));

export const StyledTextWithTooltip = MuiStyled('span')(({ theme }) => ({
  paddingTop: 0,
  marginBottom: `-${theme.spacers['spacing-16']}`,
  div: {
    display: 'inline',
  },
}));

export const StyledTextWithTopBorder = MuiStyled(Box)(({ theme }) => ({
  marginBottom: `-${theme.spacers['spacing-16']}`,
  paddingTop: theme.spacers['spacing-24'],
  borderTop: `1px solid ${theme.palette.tertiary.dark}`,
}));

export const StyledPasscodeInput = MuiStyled(Input)(({ theme }) => ({
  '&::-webkit-calendar-picker-indicator': {
    display: 'none',
  },
  '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}));

export const MuiStyledFooterWrapper = MuiStyled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'row',
  width: '100%',
  boxSizing: 'border-box',
  justifyContent: 'end',
}));
