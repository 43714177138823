import { Button } from 'components/Button/Button';
import { StyledInputTableCell } from './EditableTableCell.styles';
import { TableCell } from 'components/Table';
import { useMemo, useState } from 'react';
import { InputWithCustomButtons } from './components/InputWithCustomButtons';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { styled as MuiStyled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface EditableTableCellProps {
  value: string | number;
  editable?: Boolean;
  updateValue: (value: string | number) => void;
  formatedValue?: string;
  initialEditModeState?: Boolean;
  showFullValueOnHover?: Boolean;
}

export const EditableTableCell = ({
  value,
  editable,
  initialEditModeState,
  updateValue,
  formatedValue,
  showFullValueOnHover = false,
  ...props
}: EditableTableCellProps) => {
  const [editMode, setEditMode] = useState(initialEditModeState);

  const StyledEditTableCell = MuiStyled(TableCell)`
    display: flex;
    alignItems: center;
    height: inherit;
    gap: 8px;
    justify-content: space-between;
    button {
      visibility: hidden;
      align-self: center;
    }
    span {
      align-self: center;
      padding-right: 16px;
    }
    &:hover {
      button {
        visibility: visible;
      }
    }
  `;

  const editTableCell = useMemo(
    () =>
      editMode ? (
        <StyledInputTableCell>
          <InputWithCustomButtons
            value={value}
            onConfirm={(val) => {
              val && updateValue(val);
              setEditMode(false);
            }}
            onDecline={() => setEditMode(false)}
          />
        </StyledInputTableCell>
      ) : (
        <StyledEditTableCell {...props}>
          <Button size="small" variant="secondary" onClick={() => setEditMode(true)}>
            Edit
          </Button>
          <Box display="flex" alignItems="center">
            <Tooltip
              placement="top"
              title={<TooltipContent description={value.toString()}></TooltipContent>}
              disabled={!showFullValueOnHover}
            >
              {formatedValue || value}
            </Tooltip>
          </Box>
        </StyledEditTableCell>
      ),
    [editMode, formatedValue, props, updateValue, value]
  );

  return editable ? (
    editTableCell
  ) : (
    <TableCell disabled align="right">
      <Tooltip
        placement="top"
        title={<TooltipContent description={value.toString()}></TooltipContent>}
        disabled={!showFullValueOnHover}
      >
        {formatedValue || value}
      </Tooltip>
    </TableCell>
  );
};
