import { Box, TableBody } from '@mui/material';
import Table, { TableCell, TableRow } from 'components/Table';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import TableHeader from 'components/Table/TableHeader';
import { formatToCurrency } from 'utils/formatting';
import { Order } from 'utils/types/orders';

interface TotalAmountToSettleTableProps {
  order: Order;
}

const TotalAmountToSettleTable: React.FC<TotalAmountToSettleTableProps> = ({ order }) => (
  <Table noPadding>
    <TableHeader
      columns={[
        {
          label: 'Total amount to settle',
          propName: 'totalAmountToSettle',
          propType: 'string',
          sort: false,
          tooltip: <TooltipContent description="Total amount including fees and slippage" />,
        },
      ]}
    />
    <TableBody>
      <TableRow>
        <TableCell>Order Amount</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalFromPriceProviderInDeliveryCurrency)
            ? formatToCurrency(
                order?.tradesTotalFromPriceProviderInDeliveryCurrency,
                order?.deliveryCurrency
              )
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Slippage</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalSlippageFromTotalAndPriceProviderInDeliveryCurrency)
            ? formatToCurrency(
                order?.tradesTotalSlippageFromTotalAndPriceProviderInDeliveryCurrency,
                order?.deliveryCurrency
              )
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Box display="flex" gap={'4px'}>
            Trade amount
            <Tooltip
              title={
                <TooltipContent
                  description={
                    'The order amount that was executed including slippage, excluding fees'
                  }
                ></TooltipContent>
              }
            />
          </Box>
        </TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalInDeliveryCurrency)
            ? formatToCurrency(order?.tradesTotalInDeliveryCurrency, order?.deliveryCurrency)
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total fees</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalFeesInDeliveryCurrency)
            ? formatToCurrency(order?.tradesTotalFeesInDeliveryCurrency, order?.deliveryCurrency)
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total amount to settle</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalWithTotalFeeInDeliveryCurrency)
            ? formatToCurrency(
                order?.tradesTotalWithTotalFeeInDeliveryCurrency,
                order?.deliveryCurrency
              )
            : '-'}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default TotalAmountToSettleTable;
