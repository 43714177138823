import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { NAVReviewItem, NAVReviewResponseDto } from 'utils/types/nav';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { PaginationWithDataResponse } from 'utils/types';
import { PortfolioComposition } from 'utils/types/pcfs';
import { NAVDetailsWithProduct, RebookDto } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';

export const getPortfolioComposition = async () => {
  try {
    const { data: assets } = await axiosInstance.get<any[]>('/portfolio-composition');
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading PCFs');
  }
};

export async function getNavReviews(queryParams: ParamsType) {
  try {
    const { data: assets } = await axiosInstance.get<
      PaginationWithDataResponse<NAVReviewResponseDto[]>
    >(`/portfolio-composition/nav-review?${getQueryString(queryParams)}`);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading NAV reviews');
  }
}

export async function getNavReview(portfolioId: string, navId: string) {
  try {
    const { data: assets } = await axiosInstance.get<NAVReviewItem | NAVDetailsWithProduct>(
      `portfolio-composition/id=${portfolioId}/nav-review/id=${navId}`
    );
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading NAV');
  }
}

export async function getNavReviewForValuationDate(
  productId: string,
  constituentTicker: string,
  valuationDate: string
) {
  try {
    const { data: assets } = await axiosInstance.get<NAVReviewItem | NAVDetailsWithProduct>(
      `portfolio-composition/productId=${productId}/valuationDate=${valuationDate}/nav-review/constituent=${constituentTicker}`
    );
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading NAV');
  }
}

export async function postRebook(url: string, overrideDto: RebookDto | null) {
  try {
    const { data: assets } = await axiosInstance.post(`${url}/rebook`, overrideDto);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading NAV');
  }
}

export async function refreshSyntheticCalculation(portfolioId: string) {
  try {
    const { data: portfolioComposition } = await axiosInstance.post<PortfolioComposition>(
      `/portfolio-composition/id=${portfolioId}/synthetic`
    );

    return portfolioComposition;
  } catch (err) {
    return errorHandler(err, 'Error while refreshing the synthetic pcf');
  }
}

export async function approvePCF(uri: string) {
  try {
    const { data: portfolioComposition } = await axiosInstance.post<PortfolioComposition>(
      `/${uri}`
    );

    return portfolioComposition;
  } catch (err) {
    return errorHandler(err, 'Error while approving the pcf');
  }
}
