import { NAVCardRows, NavDetailsTransactions } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { NAVReviewItem } from 'utils/types/nav';
import { UserPermissions } from 'utils/types/permissions';
import { get } from 'lodash';
import { filterTableRows } from 'pages/Ledger/NAV/NAVDetailsPage/utils/filterTableRows';
import { renderZeroValueOrDash } from 'utils/formatting';
import {
  CustodyCardRowsEnum,
  CustodyCardRowsLabels,
} from 'pages/Ledger/NAV/NAVDetailsPage/Cards/CustodyCard/utils';

const reconciliationCardData = (navReviewItem: NAVReviewItem | undefined) => {
  let reconciliationData =
    ['Custody', 'Nav', 'Deferred Orders', 'Accrued Unrealized Fee', 'Break'].join(',') + '\n';
  reconciliationData =
    reconciliationData +
    [
      navReviewItem?.custodianTotals?.balance,
      String(navReviewItem?.ledgerBalance ?? '-'),
      navReviewItem?.totals?.unsettledDeferredOrders,
      navReviewItem?.totals?.unrealizedAccruedFee,
      navReviewItem?.break,
    ].join(',') +
    '\n\n';

  return reconciliationData;
};

const navDetailsCard = (
  navReviewItem: NAVReviewItem | undefined,
  navTransactions: NavDetailsTransactions,
  userPermissions?: {
    [key in UserPermissions]: boolean;
  }
) => {
  const tableRows = filterTableRows(navReviewItem);

  let csvNavCard = ['Name', 'Onyx', 'Fund Accountant', 'Difference'].join(',') + '\n';
  const openingNavTableRow = !userPermissions?.isFundAccount
    ? [
        NAVCardRows.OPENING_BALANCE,
        navReviewItem?.totals?.openBalance,
        navReviewItem?.fundAccountantTotals?.openBalance,
        '-',
      ]
    : [NAVCardRows.OPENING_BALANCE, navReviewItem?.fundAccountantTotals?.openBalance];

  const netOrderTableRow = [
    NAVCardRows.NET_ORDERS,
    navReviewItem?.totals?.netOrders ?? '',
    get(navTransactions, NAVCardRows.NET_ORDERS).accountantValue,
    navReviewItem?.differences?.netOrders,
  ];
  const stakingRewardsRow = [
    NAVCardRows.STAKING_REWARDS,
    navReviewItem?.totals?.stakingRewards ?? '',
    get(navTransactions, NAVCardRows.STAKING_REWARDS).accountantValue,
    navReviewItem?.differences?.stakingRewards,
  ];

  let mappedNAVCardTableRows = '';

  tableRows.map((navReviewRow) => {
    const differenceItem = get(navTransactions, navReviewRow);
    const talbeRow = !userPermissions?.isFundAccount
      ? [
          navReviewRow,
          renderZeroValueOrDash(differenceItem?.onyxValue),
          renderZeroValueOrDash(differenceItem?.accountantValue),
          renderZeroValueOrDash(differenceItem?.difference),
        ]
      : [navReviewRow, renderZeroValueOrDash(differenceItem?.accountantValue)];
    mappedNAVCardTableRows = mappedNAVCardTableRows + talbeRow.join(',') + '\n';
  });

  const closingBalanceTableRow = !userPermissions?.isFundAccount
    ? [
        NAVCardRows.CLOSING_BALANCE,
        navReviewItem?.ledgerBalance ?? '',
        navReviewItem?.fundAccountantBalance,
        navReviewItem?.differences?.closingBalance,
      ]
    : [NAVCardRows.CLOSING_BALANCE, navReviewItem?.fundAccountantBalance];

  csvNavCard = csvNavCard + openingNavTableRow.join(',') + '\n';
  csvNavCard = csvNavCard + netOrderTableRow.join(',') + '\n';
  csvNavCard = csvNavCard + stakingRewardsRow.join(',') + '\n';
  csvNavCard = csvNavCard + mappedNAVCardTableRows;
  csvNavCard = csvNavCard + closingBalanceTableRow.join(',') + '\n\n';

  return csvNavCard + '\n\n';
};

const custodiansCardData = (navReviewItem: NAVReviewItem | undefined) => {
  let custodiansListData = navReviewItem?.custodianBalances?.map((custodianBalance) => ({
    name: custodianBalance.custodian,
    closingBalance: custodianBalance.balance,
    netTransactions: custodianBalance.totals.netTransactions,
    openingBalance: custodianBalance.totals.openBalance,
  }));

  let custodyTableHeaderData =
    ['Name', ...[custodiansListData?.map((el) => el.name)]].join(',') + '\n';

  let data = custodyTableHeaderData;
  let rowsData = '';
  Object.values(CustodyCardRowsEnum)?.forEach((custody) => {
    const rowData = [
      CustodyCardRowsLabels[custody] ?? '-',
      ...[
        custodiansListData?.map((item, index) => {
          return item[custody] ?? '-';
        }),
      ],
    ];
    rowsData = rowsData + rowData.join(',') + '\n';
  });

  return data + rowsData;
};

export function downloadNAVCsv(
  navReviewItem: NAVReviewItem | undefined,
  valuationDate: string,
  navTransactions: NavDetailsTransactions,
  userPermissions?: {
    [key in UserPermissions]: boolean;
  }
) {
  let csvData = [navReviewItem?.product?.ticker, valuationDate].join(',') + '\n\n';

  let reconciliationData = reconciliationCardData(navReviewItem);

  let csvNavCard = navDetailsCard(navReviewItem, navTransactions, userPermissions);

  let custodyData = custodiansCardData(navReviewItem);

  csvData = csvData + reconciliationData + csvNavCard + custodyData;

  const csvContentArray = csvData.split('\n').map((row) => row.split(','));

  const csvContent =
    'data:text/csv;charset=utf-8,' + csvContentArray.map((row) => row.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${navReviewItem?.product?.name}-NAV-${valuationDate}.csv`);
  document.body.appendChild(link);

  link.click();
}
