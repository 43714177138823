import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Typography } from '@mui/material';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { CustodyCardRowsEnum, CustodyCardRowsLabels } from './utils';

type CustodyCardRowProps = {
  custodyData: {
    name: string;
    closingBalance: string;
    netTransactions: string;
    openingBalance: string;
  }[];
  editAction?: () => void;
  columns: TableHeaderColumn[];
  rowName: CustodyCardRowsEnum;
};

export const CustodyCardTableRow = ({ custodyData, editAction, rowName }: CustodyCardRowProps) => {
  return (
    <TableRow data-qa-id={`${rowName}-row`} key={rowName} onClick={editAction}>
      <TableCell data-qa-id={'row-name'} key={rowName}>
        <Typography>{CustodyCardRowsLabels[rowName] ?? '-'} </Typography>
      </TableCell>
      {custodyData?.map((item, index) => {
        return (
          <TableCell data-qa-id={'card-data-name-' + index} key={index}>
            <Typography>{item[rowName] ?? '-'} </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
