import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { Instrument, ProductStatus } from 'utils/types/product';
import { useLocation, useNavigate } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';
import { getQueryString } from 'hooks/useUrlParams';
import { INSTRUMENTS_TAB_STATUS } from 'pages/Instruments/Instruments.constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { hideModal } from 'store/modals/slice';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Typography } from '@mui/material';

export const CreateETPSeedOrderModal = ({
  onCloseModalAction,
  closeModal,
  data,
}: IModalWithData) => {
  const location = useLocation();
  const currentRoute = location.pathname + location.search;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instrument = data.data as Instrument;
  const handleClose = () => {
    navigate(
      privateRoutesUrls.dashboardRoutes.etps +
        `?${getQueryString({
          tab: INSTRUMENTS_TAB_STATUS.IN_REVIEW,
          page: DEFAULT_PAGE,
          pageSize: DEFAULT_ITEMS_PER_PAGE,
          status: ProductStatus.IN_REVIEW,
        })}`
    );
    dispatch(hideModal());
    if (onCloseModalAction) onCloseModalAction();
  };

  const handleCreateSeedOrder = () => {
    navigate(privateRoutesUrls.dashboardRoutes.ordersDelegated, {
      state: {
        seedProductId: instrument._id,
        previousRoute: currentRoute,
      },
    });
    dispatch(hideModal());
    if (onCloseModalAction) onCloseModalAction();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={handleClose}
        type="button"
        data-qa-id="cancelButton"
      >
        Close
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleCreateSeedOrder}
        type="submit"
        data-qa-id="createButton"
      >
        Create seed order
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="ETP" title="Create Seed Order" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Product ${instrument.ticker} created and ready for seed
            </Typography>
            <Typography variant="bodyMedium">
              This product has been created and is pending a seed order. To activate this product
              you will need to create and settle a seed order.
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
