import { NAVReviewItem, NAVReviewItemsRows, TransactionType } from 'utils/types/nav';
import { NAVCardRows, NavDetailsTransactions } from './types';

export const mapNavReviewDetailsTransactions = (
  navReviewItemWithTransactions?: NAVReviewItem
): NavDetailsTransactions => {
  const mappedTransactions: NavDetailsTransactions = {
    [NAVCardRows.OPENING_BALANCE]: {
      name: NAVCardRows.OPENING_BALANCE,
      onyxValue: navReviewItemWithTransactions?.totals?.openBalance,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.openBalance,
      difference: navReviewItemWithTransactions?.differences?.closingBalance, // TODO: check which prop is this
      _id: NAVReviewItemsRows.OPENING_NAV,
    },
    [NAVCardRows.MANAGEMENT_FEE]: {
      onyxValue: navReviewItemWithTransactions?.totals?.managementFee,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.managementFee,
      transactions: [],
      _id: NAVReviewItemsRows.MANAGEMENT_FEE,
    },
    [NAVCardRows.NET_ORDERS]: {
      onyxValue: navReviewItemWithTransactions?.totals?.netOrders,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.netOrders,
      transactions: [],
      _id: NAVReviewItemsRows.NET_ORDERS,
    },
    [NAVCardRows.STAKING_FEES]: {
      onyxValue: navReviewItemWithTransactions?.totals?.stakingFees,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.stakingFees,
      transactions: [],
      _id: NAVReviewItemsRows.STAKING_FEES,
    },
    [NAVCardRows.STAKING_REWARDS]: {
      onyxValue: navReviewItemWithTransactions?.totals?.stakingRewards,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.stakingRewards,
      transactions: [],
      _id: NAVReviewItemsRows.STAKING_REWARDS,
    },
    [NAVCardRows.CLOSING_BALANCE]: {
      onyxValue: navReviewItemWithTransactions?.totals?.stakingRewards,
      accountantValue: navReviewItemWithTransactions?.fundAccountantTotals?.stakingRewards,
      transactions: [],
      _id: NAVReviewItemsRows.STAKING_REWARDS,
    },
    [NAVCardRows.REBOOK_BALANCE_ADJUST]: {
      onyxValue: Number(
        navReviewItemWithTransactions?.transactions?.find(
          (trx) => trx.type === TransactionType.REBOOK_BALANCE_ADJUST
        )?.amount
      ),
      transactions: [],
      _id: NAVReviewItemsRows.REBOOK_BALANCE_ADJUST,
    },
    [NAVCardRows.REBALANCE_TRADES]: {
      onyxValue: navReviewItemWithTransactions?.totals?.tradeValue,
      transactions: [],
      _id: NAVReviewItemsRows.REBALANCE_TRADES,
    },
  };
  navReviewItemWithTransactions?.transactions?.forEach((transaction) => {
    switch (transaction.type) {
      case TransactionType.ETP_CREATION:
        mappedTransactions[NAVReviewItemsRows.NET_ORDERS]?.transactions?.push(transaction);
        return;
      case TransactionType.ETP_REDEMPTION:
        mappedTransactions[NAVReviewItemsRows.NET_ORDERS]?.transactions?.push(transaction);
        return;
      case TransactionType.EARN_FEE:
        mappedTransactions[NAVReviewItemsRows.STAKING_FEES]?.transactions?.push(transaction);
        return;
      case TransactionType.EARN_REWARD:
        mappedTransactions[NAVReviewItemsRows.STAKING_REWARDS]?.transactions?.push(transaction);
        return;
      case TransactionType.REBOOK_BALANCE_ADJUST:
        mappedTransactions[NAVReviewItemsRows.REBOOK_BALANCE_ADJUST]?.transactions?.push(
          transaction
        );
        return;
      default:
        return;
    }
  });
  return mappedTransactions;
};
