import { Box, Card, styled as MuiStyled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export const OrderFormContainer = MuiStyled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  flex: '1 auto',
  minWidth: '362px',
  top: 0,
  // Order form is "sticky" on medium and larger screens
  position: useMediaQuery(theme.breakpoints.up('md')) ? 'sticky' : 'static',
}));

export const StyledCutoffContainer = MuiStyled(Box)`
  padding: ${({ theme }) => `${theme.spacers['spacing-16']} 0;`};
`;

export const StyledOrderForm = MuiStyled(Card)`
  form {
    gap: 32px;
  }
`;
