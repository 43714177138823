import WalletsTable from 'shared/Tables/WalletsTable/WalletsTable';
import useAppModal from 'hooks/useAppModal';
import { CompanyCustodianWallet, CompanyOwnWallet, WalletType } from 'utils/types/wallets';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { ParamsType } from 'hooks/useUrlParams';
import { StyledDoubleTableWrapper } from 'pages/Partners/components/PartnerRelatedEntitiesTabs/PartnerTabs.styles';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { getWalletsPerCompanyQuery } from 'utils/constants/reactQueries';
import { queryClient } from 'utils/api/queries/queryClient';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useState } from 'react';
import { useWalletsStepDataHook } from 'pages/Partners/hooks/useWalletsStepDataHook';

interface Props {
  walletsStatus: WALLETS_TABS_STATUS;
}
export const WalletsStepTables = ({ walletsStatus = WALLETS_TABS_STATUS.ACTIVE }: Props) => {
  const openModal = useAppModal();

  const [walletModalType, setModalWalletType] = useState('OWN');

  const [custodianWalletsParams, setCustodianWalletsParams] = useState<ParamsType>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  });
  const [companyWalletsParams, setCompanyWalletsParams] = useState<ParamsType>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  });

  const {
    companyID,
    partnerData,
    companyWalletsListsData,
    custodianWalletsListsDataWithProductName,
    loadingCustodianWallets,
    loadingProducts,
    loadingOwnWallets,
  } = useWalletsStepDataHook(custodianWalletsParams, companyWalletsParams, walletsStatus);

  const custodianWalletsPagination = useBackendPagination(
    custodianWalletsListsDataWithProductName?.pagination ?? null,
    setCustodianWalletsParams
  );

  const companyWalletsPagination = useBackendPagination(
    companyWalletsListsData?.pagination ?? null,
    setCompanyWalletsParams
  );

  const canViewCustodianWallet = partnerData?._links?.walletsCustodian;
  const canViewOwnWallet = partnerData?._links?.walletsOwned;

  const openWalletModal = (
    type: MODAL_ACTIONS,
    walletType: WalletType,
    wallet?: CompanyCustodianWallet | CompanyOwnWallet
  ) => {
    setModalWalletType(walletType);
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: { wallet, type: walletType },
          companyData: {
            id: companyID,
            name: partnerData?.name,
          },
          type: type,
          custom: {
            isToken: partnerData?.type === 'AUTHORIZED_MERCHANT',
          },
        },
      },
      {
        onCloseModalAction: () =>
          queryClient.invalidateQueries({
            queryKey: [getWalletsPerCompanyQuery + walletModalType],
          }),
      }
    );
  };

  const companyWallets = partnerData?.name ? `${partnerData?.name} Wallets` : 'Company Wallets';

  return (
    <>
      {canViewOwnWallet && (
        <StyledDoubleTableWrapper>
          <WalletsTable
            productType={partnerData?.type === 'AUTHORIZED_MERCHANT' ? 'Token' : 'ETP'}
            urlParams={companyWalletsParams}
            setUrlParams={setCompanyWalletsParams}
            tableProperties={{
              title: companyWallets,
              pagination: companyWalletsPagination,
            }}
            deleteAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.DELETE, WalletType.OWN_WALLET, wallet)
            }
            editAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.EDIT, WalletType.OWN_WALLET, wallet)
            }
            activateAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.CUSTOM, WalletType.OWN_WALLET, wallet)
            }
            loadingData={loadingOwnWallets}
            wallets={companyWalletsListsData?.data?.length ? companyWalletsListsData?.data : []}
          />
        </StyledDoubleTableWrapper>
      )}
      {canViewCustodianWallet && (
        <StyledDoubleTableWrapper>
          <WalletsTable
            productType={partnerData?.type === 'AUTHORIZED_MERCHANT' ? 'Token' : 'ETP'}
            urlParams={custodianWalletsParams}
            setUrlParams={setCustodianWalletsParams}
            tableProperties={{
              title: 'Issuer Wallets',
              pagination: custodianWalletsPagination,
            }}
            deleteAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.DELETE, WalletType.CUSTODIAN_WALLET, wallet)
            }
            editAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.EDIT, WalletType.CUSTODIAN_WALLET, wallet)
            }
            activateAction={(wallet) =>
              openWalletModal(MODAL_ACTIONS.CUSTOM, WalletType.CUSTODIAN_WALLET, wallet)
            }
            loadingData={loadingCustodianWallets || loadingProducts}
            populateProducts={true}
            wallets={custodianWalletsListsDataWithProductName.data}
          />
        </StyledDoubleTableWrapper>
      )}
    </>
  );
};
