import Table from 'components/Table';
import { Order, ProductType } from 'utils/types';
import { renderOrderValue } from 'shared/Tables/table.utils';
import Box from '@mui/material/Box';
import { TableBody } from '@mui/material';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import ExplorerLink from 'components/ExplorerLink';
import TableHeader from 'components/Table/TableHeader';

interface FundInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

export const productInformationColumnsConfig = [
  { label: 'Name', propName: 'name', propType: 'string', width: '50%' },
];

const ProductInformation = ({ productType, className, order }: FundInformationProps) => {
  return (
    <Box className={className} component="section">
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeader columns={productInformationColumnsConfig} />
          <TableBody>
            <TableRow>
              <TableCell>Product name</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order?.product.name), order?.product.name)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Series Name</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order?.product.seriesName), order?.product.seriesName)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Series Letter</TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(order?.product.seriesLetter),
                  order?.product.seriesLetter
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product ISIN</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order?.product.isin), order?.product.isin)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding>
          <TableHeader columns={productInformationColumnsConfig} />
          <TableBody>
            <TableRow>
              <TableCell>Token name</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order?.product?.name), order?.product?.name)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Source Chain</TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(order?.product?.sourceChain),
                  order?.product?.sourceChain
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Destination Chain</TableCell>
              <TableCell align="right">
                {' '}
                {renderOrderValue(
                  Boolean(order?.product?.destinationChain),
                  order?.product?.destinationChain
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mint/Burn Contract ID</TableCell>
              <TableCell align="right">
                {(order?.product?.destinationChain && order?.product?.contractAddress && (
                  <ExplorerLink
                    destinationChain={order?.product?.destinationChain}
                    data={order?.product?.contractAddress}
                    type="address"
                  />
                )) ||
                  '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default ProductInformation;
