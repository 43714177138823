import { ParamsType } from 'hooks/useUrlParams';
import { WALLETS_TABS_STATUS } from 'utils/constants/wallets';
import { CompanyCustodianWallet, WalletStatus } from 'utils/types/wallets';
import { useCompanyQuery } from './useCompanyQuery';
import { useInstrumentsQuery } from 'hooks/useInstrumentsQuery';
import { useParams } from 'react-router-dom';
import { useWalletsPerCompanyQuery } from './useWalletsPerCompany';
import { useGetTokensQuery } from 'hooks/useTokens';
import { Instrument, Token } from 'utils/types/product';

export const useWalletsStepDataHook = (
  custodianWalletsParams: ParamsType,
  companyWalletsParams: ParamsType,
  walletsStatus: WALLETS_TABS_STATUS
) => {
  const paramsStatus =
    walletsStatus === WALLETS_TABS_STATUS.ACTIVE ? WalletStatus.ACTIVE : WalletStatus.DELETED;
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const companyID = partnerData?._id ?? '';
  const { data: custodianWalletsListsData, isLoading: loadingCustodianWallets } =
    useWalletsPerCompanyQuery(companyID, 'CUSTODIAN', {
      ...custodianWalletsParams,
      status: paramsStatus,
    });
  const isAuthorizedMerchant = partnerData?.type === 'AUTHORIZED_MERCHANT';

  const { data: tokens, isLoading: loadingTokens } = useGetTokensQuery(
    {},
    companyID,
    isAuthorizedMerchant
  );

  const { data: instruments, isLoading: loadingInstruments } = useInstrumentsQuery(
    companyID,
    undefined,
    !isAuthorizedMerchant
  );

  const { data: companyWalletsListsData, isLoading: loadingOwnWallets } = useWalletsPerCompanyQuery(
    companyID,
    'OWN',
    { ...companyWalletsParams, status: paramsStatus }
  );

  const loadingData = loadingCustodianWallets || loadingInstruments || loadingOwnWallets;

  return {
    companyID,
    partnerData,
    products: isAuthorizedMerchant ? tokens : instruments?.data,
    custodianWalletsListsData,
    custodianWalletsListsDataWithProductName: {
      ...custodianWalletsListsData,
      data: (custodianWalletsListsData?.data as CompanyCustodianWallet[])?.map((wallet) => {
        const foundProduct = (
          (isAuthorizedMerchant ? tokens : instruments?.data) as (Instrument | Token)[]
        )?.find((product) => product._id === wallet.product)!;
        return { ...wallet, productName: foundProduct?.name };
      }),
    },
    companyWalletsListsData,
    loadingCustodianWallets,
    loadingProducts: loadingTokens || loadingInstruments,
    loadingOwnWallets,
    loadingData,
  };
};
