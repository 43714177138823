import { Box } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { styled as MuiStyled } from '@mui/material/styles';

interface ActionButtonsWrapperProps {
  onConfirm: () => void;
  onDecline: () => void;
}

const StyledActionsWrapper = MuiStyled(Box)`
  display: flex;
  alignItems: center;
  gap: 2px;
  svg {
    cursor: pointer;
    overflow: unset;
    &:hover {
      background-color: ${({ theme }) => `${theme.palette.tertiary.dark}`};
    }
  }
`;

export const ActionButtonsWrapper = ({
  onConfirm,
  onDecline,
  ...props
}: ActionButtonsWrapperProps) => {
  return (
    <StyledActionsWrapper {...props}>
      <Box>
        <CheckIcon onClick={onConfirm} />
      </Box>
      <Box>
        <CloseIcon onClick={onDecline} />
      </Box>
    </StyledActionsWrapper>
  );
};
