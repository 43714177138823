import { Box, styled as MuiStyled } from '@mui/material';

export const StyledContainer = MuiStyled('section')`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  overflow-y: auto;
`;

export const StyledCustomHeader = MuiStyled(Box)`
  display: flex;
  gap: 24px;
  h4 {
    align-self: center;
  }
  .product-icon {
    align-self: center;
    span {
      font-size: 16px;
      height: 32px;
      width: 32px;
      line-height: 32px;
    }
    img {
      min-height: 32px;
      min-width: 32px;
    }
  }
`;
