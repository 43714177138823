// types, constants
import { COMPANY_CONTACT_TYPE, ContactList } from 'utils/types/contacts';

import { useEffect, useState } from 'react';
import Search from 'components/Search';

import { ParamsType } from 'hooks/useUrlParams';

// assets
import { OptionType } from 'components/Select/Select.types';
import { Button } from 'components/Button/Button';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { Box } from '@mui/material';

type Props = {
  contactList?: ContactList;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
  partnersOptions: OptionType<string>[];
  onBatchDelete?: () => void;
};

const EditContactsListViewToolbar = ({
  contactList,
  setUrlParams,
  urlParams,
  partnersOptions,
  onBatchDelete,
}: Props) => {
  const [search, setSearch] = useState(urlParams.search ?? '');
  const [searchInputValue, setSearchInputValue] = useState(urlParams.search ?? '');

  useEffect(() => {
    setUrlParams((prevState) => ({
      ...prevState,
      search: search,
    }));
  }, [search, setUrlParams]);
  return (
    <Box display="flex" gap={1}>
      <Search
        variant="short"
        placeholder="Search"
        value={searchInputValue}
        onChange={(event) => setSearchInputValue(event.target.value)}
        onBlur={() => setSearch(searchInputValue)}
        onKeyDown={(event) => event.key === 'Enter' && setSearch(searchInputValue)}
        onClear={() => setSearch('')}
        style={{ width: '240px' }}
      />
      <MultiSelect
        name="pcfUploaders"
        data-qa-id="partnersFilter"
        data-qa-options-id="partnersFilter"
        options={[
          { label: 'Unaffiliated', value: COMPANY_CONTACT_TYPE.UNAFFILIATED },
          ...partnersOptions,
        ]}
        placeholder="All partners"
        onChange={(_, values) =>
          setUrlParams((prevState) => ({ ...prevState, partner: (values as string[]) ?? [] }))
        }
        value={urlParams.partner as string[]}
        size="small"
        style={{ width: '200px' }}
      />
      {onBatchDelete && (
        <Button
          data-qa-id="deleteContacts"
          variant="secondary"
          onClick={() => onBatchDelete()}
          size="small"
        >
          Delete Contacts
        </Button>
      )}
    </Box>
  );
};

export default EditContactsListViewToolbar;
