import { ProductType } from 'utils/types/product';

export const getWalletsTableColumnsConfig = (
  productType?: ProductType,
  populateProducts?: boolean
) => [
  { label: 'Wallet', propName: 'address', propType: 'string', width: '25%' },
  { label: 'Type', propName: 'chain', propType: 'string', width: '25%' },
  {
    label: 'Description',
    propName: 'description',
    propType: 'string',
    width: populateProducts ? '25%' : '42%',
    colSpan: populateProducts ? 1 : 2,
  },
  ...(populateProducts
    ? [
        {
          label: productType === 'ETP' ? 'ETP' : 'Token',
          propName: 'productName',
          propType: 'string',
          width: '17%',
        },
      ]
    : []),
];
