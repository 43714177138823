import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import OrderForm from './OrderForm';
import { Deliverable, Order, OrderFlow, ProductType } from 'utils/types';
import { EditOrderProps } from 'pages/Orders/Orders/types';
import OrderTokenForm from 'pages/Orders/Tokens/components/OrderTokenForm';
import { Box, useMediaQuery, useTheme } from '@mui/material';

interface SingleOrderViewProps extends Omit<EditOrderProps, 'order'> {
  customBasketDeliverables?: Deliverable[];
  productType: ProductType;
  orderFlow: OrderFlow;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

export const SingleOrderView = ({
  customBasketDeliverables,
  productType,
  changeOrder,
  isSubmitting,
  loading,
  onChangeSeedDeliverable,
  onChangeCustomBasketDeliverable,
  onSubmit,
  order,
  orderFlow,
  pcf,
  seedDeliverables,
  user,
}: SingleOrderViewProps) => {
  const theme = useTheme();
  // used to stack the order form on top of order details on small screens
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box display="flex" gap={4} flexWrap={mediumScreen ? 'nowrap' : 'wrap-reverse'}>
      <Box
        width={'100%'}
        sx={{
          overflowY: 'auto',
        }}
      >
        <OrderDetails
          customBasketDeliverables={customBasketDeliverables}
          productType={productType}
          onChangeSeedDeliverable={onChangeSeedDeliverable}
          onChangeCustomBasketDeliverable={onChangeCustomBasketDeliverable}
          loading={loading}
          user={user}
          orderFlow={orderFlow}
          order={order}
          seedDeliverables={seedDeliverables}
        />
      </Box>
      {productType === 'ETP' && (
        <OrderForm
          handleOnSubmit={onSubmit}
          isSubmitting={isSubmitting}
          loading={loading}
          pcf={pcf}
          user={user}
          changeOrder={changeOrder}
          orderFlow={orderFlow}
          orderDetails={order}
          seedDeliverables={seedDeliverables}
        />
      )}
      {productType === 'Token' && (
        <OrderTokenForm
          handleOnSubmit={onSubmit}
          isSubmitting={isSubmitting}
          loading={loading}
          user={user}
          changeOrder={changeOrder}
          orderFlow={orderFlow}
          orderDetails={order}
        />
      )}
    </Box>
  );
};
