export const NAVBalanceOverrideTableConfig = [
  {
    label: 'Data point',
    propName: 'fieldName',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  {
    label: 'Old value',
    propName: 'onyx',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  {
    label: 'New value',
    propName: 'onyx',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
  {
    label: 'Comments',
    propName: 'onyx',
    propType: 'string',
    minWidth: '140px',
    sort: false,
  },
];
