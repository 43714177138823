import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { CompanyOwnWallet } from 'utils/types/wallets';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { ProductStatus, Token } from 'utils/types/product';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { createNotification } from 'store/notifications/actions';
import {
  createOwnWalletForCompanyRequest,
  editOwnWalletForCompanyRequest,
} from 'utils/api/wallets';
import { createOwnWalletSchemaUrl } from 'components/Form/formSchemas';
import { isEmpty } from 'lodash';
import { loadAssets } from 'store/assets/actions';
import { useAssets } from 'store/assets/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
  walletCreatedNotifications,
  walletEditNotifications,
} from 'shared/Notifications/wallets.notifications';
import { getAssetsOptions, getProductOptions } from './utils';
import { useInstrumentsQuery } from 'hooks/useInstrumentsQuery';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { useGetTokensQuery } from 'hooks/useTokens';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Divider, Stack } from '@mui/material';

export const UpdateOwnWalletModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const isToken = data.custom?.isToken;
  const dispatch = useAppDispatch();
  const { assets, loadingExistingAssets, error: loadingAssetsError } = useAssets();
  const walletModalData = data?.data as WalletModalData;
  const wallet = walletModalData.wallet;

  const schemaUrl = createOwnWalletSchemaUrl;
  const companyData = data?.companyData;
  const isNewWallet = Boolean(data?.type === MODAL_ACTIONS.CREATE);
  const actionButtonText = isNewWallet ? `Add Wallet` : 'Save Changes';
  const assetsOptions = getAssetsOptions(assets);
  const [selectedChain, setSelectedChain] = useState(''); // used to filter "Token" Products dropdown options

  useEffect(() => {
    if (isEmpty(assets?.existing) && !loadingAssetsError) {
      dispatch(loadAssets());
    }
  }, [assets, dispatch, loadingAssetsError]);

  const { data: ETPsResponse, isLoading: loadingETPs } = useInstrumentsQuery(
    companyData?.id ?? '',
    [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW],
    !Boolean(isToken)
  );

  const { data: tokens, isLoading: loadingTokens } = useGetTokensQuery(
    { status: [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW] },
    companyData?.id ?? '',
    Boolean(isToken)
  );

  const products = isToken ? (tokens as Token[]) : ETPsResponse?.data;

  const productsOptions = useMemo(() => {
    if (isToken && selectedChain) {
      const filteredProducts = (products as Token[]).filter(
        ({ destinationChain, sourceChain }) =>
          destinationChain === selectedChain || sourceChain === selectedChain
      );

      return getProductOptions(filteredProducts, true);
    } else {
      return getProductOptions(products, true);
    }
  }, [isToken, products, selectedChain]);

  const updateWalletQueryMutation = useMutation({
    mutationFn: (data: { companyId: string; wallet: CompanyOwnWallet }) =>
      isNewWallet
        ? createOwnWalletForCompanyRequest(data.companyId, data.wallet)
        : editOwnWalletForCompanyRequest(wallet?._actions?.update?.uri ?? '', data.wallet),
    onSuccess: (_, initData) => {
      dispatch(
        createNotification(
          isNewWallet
            ? walletCreatedNotifications.success(initData.wallet.address, companyData?.name)
            : walletEditNotifications.success(initData.wallet.address)
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification(
          isNewWallet
            ? walletCreatedNotifications.error(error.message)
            : walletEditNotifications.error(error.message),
          error
        )
      );
    },
  });

  const handleUpdate = useCallback(
    (data: CompanyOwnWallet) => {
      updateWalletQueryMutation.mutate({
        wallet: data,
        companyId: companyData?.id ?? '',
      });
    },
    [companyData?.id, updateWalletQueryMutation]
  );

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        variant="primary"
        fullWidth
        isLoading={updateWalletQueryMutation.isLoading}
        type="submit"
      >
        {actionButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        onClose={closeModal}
        header={
          <CardHeaderTitleWithLabel
            label={'Wallet'}
            title={isNewWallet ? `Add ${companyData?.name} Wallet` : `Update  Wallet`}
          />
        }
        body={
          <Form
            schemaUrl={schemaUrl}
            onSubmit={handleUpdate}
            initialValues={wallet ?? {}}
            loading={loadingExistingAssets || loadingETPs || loadingTokens}
          >
            <Stack padding={2} gap={1}>
              <Select
                data-qa-id="chain"
                data-qa-options-id="chain"
                name="chain"
                onChange={(val: string | undefined) => {
                  if (val) {
                    setSelectedChain(val);
                  }
                }}
              />
              <MultiSelect
                name="products"
                data-qa-id="products"
                data-qa-options-id="products"
                options={productsOptions}
                size="large"
                {...(productsOptions.length === 0 && {
                  label: 'Product (No product assigned to this partner)',
                })}
                {...(productsOptions.length === 0 && { disabled: true })}
              />
              <MultiSelect
                name="assets"
                data-qa-id="assets"
                data-qa-options-id="assets"
                options={assetsOptions}
                size="large"
              />
              <Input name="address" data-qa-id="address" />
              <Input name="description" data-qa-id="description" />
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>{Footer}</Box>
          </Form>
        }
      />
    </CustomModal>
  );
};
