import ProjectVersion, { getOnyxVersion } from 'components/ProjectVersion';
import {
  NavItem,
  NavSubItem,
} from 'layouts/DashboardContainer/components/SideNav/SideNavigation.types';
import {
  StyledLogo,
  StyledTagAndMenuIconWrapper,
  SideNavWrapper,
  StyledLogoAndToggleWrapper,
  StyledSidenavDivider,
  StyledSidenavCopyright,
} from 'layouts/DashboardContainer/SideNavigationMenu.style';
import {
  findNavItemBySubPath,
  getAllowedNavList,
} from 'layouts/DashboardContainer/components/SideNav/SideNavigation.utils';
import { Stack } from '@mui/material';
import { ToggleMenuIcon } from 'layouts/DashboardContainer/components/SideNav/components/ToggleMenuIcon';
import { environment, isProductionEnvironment } from 'utils/env';
import { profileItem } from 'layouts/DashboardContainer/components/SideNav/sideNavigationMap';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, Fragment, useEffect, useCallback, useMemo } from 'react';
import { useValidateNavigation } from 'hooks/useValidateNavigation';
import { SideNavigationMenuItem } from './components/SideNavigationMenuItem/SideNavigationMenuItem';
import Tag from 'components/Tag/Tag';

const SideNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { validToNavigate, openInvalidNavigationModal } = useValidateNavigation();
  const { pathname } = location;
  const { user } = useAuthenticatedUser();
  const [sideMenuRevealed, setSideMenuRevealed] = useState(true);
  const [expandedItem, setExpandedItem] = useState<string>(findNavItemBySubPath(pathname) ?? '');
  const [allowedSideNavItems, setAllowedSideNavItems] = useState<NavItem[]>(
    getAllowedNavList(user) ?? []
  );
  const profileSideNavItemData = profileItem(`${user?.firstName ?? ''} ${user?.lastName ?? ''}`);
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const canNavigate = useCallback(
    (navigationPath: string) => {
      if (validToNavigate) {
        navigate(navigationPath, { state: { prevPath: pathname } });
      } else {
        openInvalidNavigationModal(navigationPath);
      }
    },
    [navigate, openInvalidNavigationModal, validToNavigate]
  );

  useEffect(() => {
    if (user?.permissions) {
      const allowedRoutes = getAllowedNavList(user);
      setAllowedSideNavItems(allowedRoutes);
    }
  }, [user?.permissions]);

  const selectNavItem = (item: NavItem) => {
    if (item.subnav) {
      setExpandedItem(item.title);
      canNavigate(item.subnav[0].path);
    } else if (item.path) {
      setExpandedItem('');
      canNavigate(item.path);
    }
  };

  const selectSubItem = (item: NavSubItem) => {
    canNavigate(item.path);
  };

  const navItems = allowedSideNavItems.map((item) => (
    <Fragment key={item.title}>
      <SideNavigationMenuItem
        key={item.title}
        sideMenuRevealed={sideMenuRevealed}
        item={item}
        onClick={selectNavItem}
        selected={pathname === item.path}
        iconRotated={expandedItem === item.title ? 'true' : 'false'}
      />
      {sideMenuRevealed &&
        expandedItem === item.title &&
        item.subnav?.map((subitem: NavSubItem) => (
          <SideNavigationMenuItem
            key={subitem.title}
            sideMenuRevealed={true}
            item={subitem}
            onClick={() => selectSubItem(subitem)}
            selected={pathname === subitem.path}
          />
        ))}
    </Fragment>
  ));

  const logoAndToggleItem = (
    <StyledLogoAndToggleWrapper>
      {sideMenuRevealed && <StyledLogo />}
      <StyledTagAndMenuIconWrapper>
        {!isProductionEnvironment && sideMenuRevealed && <Tag label={environment} variant="red" />}
        <ToggleMenuIcon
          rotateIcon={sideMenuRevealed}
          onClick={() => setSideMenuRevealed((prevState) => !prevState)}
        />
      </StyledTagAndMenuIconWrapper>
    </StyledLogoAndToggleWrapper>
  );

  const profileSideNavItem = (
    <Stack marginTop="auto">
      {
        <SideNavigationMenuItem
          sideMenuRevealed={sideMenuRevealed}
          item={profileSideNavItemData}
          onClick={() => selectNavItem(profileSideNavItemData)}
          selected={pathname.includes('user-settings')}
          iconRotated={expandedItem === profileSideNavItemData.title ? 'true' : 'false'}
        />
      }
    </Stack>
  );

  return (
    <SideNavWrapper justifyContent="flex-start">
      {logoAndToggleItem}
      {navItems}
      {profileSideNavItem}
      {sideMenuRevealed && (
        <>
          <StyledSidenavDivider />
          <StyledSidenavCopyright>
            &copy; {currentYear} 21.co, Onyx {getOnyxVersion()}
          </StyledSidenavCopyright>
          <ProjectVersion />
        </>
      )}
    </SideNavWrapper>
  );
};

export default SideNavigation;
