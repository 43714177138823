import { FC } from 'react';
import { ReactComponent as IconSuccess } from 'assets/success.svg';
import Column from 'components/Grid/Column';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import copyToClipboard from 'utils/copyToClipboard';
import { StyledQRTextWrapper, StyledRightBottomButton } from './TwoFaSetup.styles';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const SuccessfullSetup: FC<{
  handleFinishSetup: () => void;
  twoFAOTPBackupCode: string;
}> = ({ handleFinishSetup, twoFAOTPBackupCode }) => {
  return (
    <Card
      header={
        <CardHeaderTitleWithLabel
          label="2FA Setup"
          title="You have successfully enrolled authentication device"
          icon={<IconSuccess />}
        />
      }
      body={
        <Stack padding={2}>
          <Column>
            <Typography variant="bodyMedium">2FA Backup Code for this device:</Typography>
            <StyledQRTextWrapper>
              <Typography variant="bodyMedium">{twoFAOTPBackupCode}</Typography>
              <CopyIcon onClick={() => copyToClipboard(twoFAOTPBackupCode)} />
            </StyledQRTextWrapper>
          </Column>
        </Stack>
      }
      footer={
        <Box padding={2}>
          <MuiStyledModalFooterButtons>
            <StyledRightBottomButton variant="primary" type="submit" onClick={handleFinishSetup}>
              Finish
            </StyledRightBottomButton>
          </MuiStyledModalFooterButtons>
        </Box>
      }
    ></Card>
  );
};
