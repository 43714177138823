/* eslint-disable complexity */
import CircularProgress from 'components/CircularProgress';
import CutoffInfo from 'pages/Orders/Orders/Order/components/CutoffInfo';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import ProductSelector from 'components/ProductSelector';
import Select from 'components/Select';
import { Deliverable, Order, OrderFlow, OrderFormProps } from 'utils/types/orders';
import { NavOptionsTitles } from 'pages/Orders/Orders/types';
import { ORDER_IN_KIND } from 'utils/constants';
import { OrderFormContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { Pcf } from 'utils/types/pcfs';
import { ProductStatus } from 'utils/types/product';
import { User } from 'utils/types';
import { isFormReadyForSubmission } from 'pages/Orders/Orders/utils/isFormReadyForSubmission';
import { orderDelegatedSchemaUrl, orderSchemaUrl } from 'components/Form/formSchemas';
import { useAuthorizedParticipants } from 'pages/Orders/Orders/Order/hooks/useAuthorizedParticipants';
import { useEffect, useMemo, useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { useUsersPerCompany } from 'pages/Partners/hooks/useUsersPerCompany';
import { useLocation } from 'react-router-dom';
import { Card } from 'components/Card/Card';
import { Divider, Stack, Typography } from '@mui/material';
import { Button } from 'components/Button/Button';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { hideCustomBasketOrders } from 'utils/env';

interface Props {
  changeOrder: (data: OrderFormProps | undefined) => void;
  handleOnSubmit: (data: OrderFormProps) => void;
  isSubmitting?: boolean;
  loading: boolean;
  orderDetails?: Order;
  orderFlow?: OrderFlow;
  pcf?: Pcf;
  seedDeliverables?: Deliverable[];
  user: User | null;
}

const OrderForm = ({
  handleOnSubmit,
  isSubmitting,
  changeOrder,
  orderFlow = 'create',
  orderDetails,
  seedDeliverables,
  loading,
}: Props) => {
  const location = useLocation();
  const permissions = useUserPermissions();
  const [settlementTypeOptions, setSettlementTypeOptions] = useState<
    { label: string; value: string }[]
  >(
    orderDetails?.settlementTypeOptions?.length
      ? orderDetails?.settlementTypeOptions?.map((option) => ({
          label: option,
          value: option,
        }))
      : []
  );

  const [pricingTypeoptions, setPricingTypeOptions] = useState<{ label: string; value: string }[]>(
    orderDetails?.pricingTypeOptions?.map((option) => {
      return { value: option, label: NavOptionsTitles[option] };
    }) ?? []
  );

  const seedProductId = location?.state?.seedProductId; // Passed to form as initial value to improve "Seed Product" flow
  const isDelegatedOrder =
    (permissions?.canCreateOrderDelegated || permissions?.canCreateTokenOrderDelegated) &&
    orderFlow === 'delegate';

  const [orderFormData, setOrderFormData] = useState<OrderFormProps>();

  const [companyId, setCompanyId] = useState<string>();
  const { users } = useUsersPerCompany(companyId ?? '');
  const { instrumentAPs, loading: loadingAPs } = useAuthorizedParticipants(
    seedProductId ?? orderDetails?.product?._id
  );

  const usersOptions = useMemo(
    () =>
      users?.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user._id })) ??
      [],
    [users]
  );
  const deliveryCurrencyOptions = [
    {
      label: 'USDC',
      value: 'USDC',
    },
  ];

  useEffect(() => {
    if (!orderDetails) return;
    const newSettlementOptions = orderDetails?.settlementTypeOptions?.length
      ? orderDetails?.settlementTypeOptions?.map((option) => ({
          label: option,
          value: option,
        }))
      : [];
    orderDetails?.settlementTypeOptions?.length && setSettlementTypeOptions(newSettlementOptions);

    const newPricingTypeoptions =
      orderDetails?.pricingTypeOptions?.map((option) => {
        return { value: option, label: NavOptionsTitles[option] };
      }) ?? [];
    orderDetails?.pricingTypeOptions && setPricingTypeOptions(newPricingTypeoptions);
  }, [orderDetails?.settlementTypeOptions, orderDetails?.pricingTypeOptions]);

  const formSubmissionEnabled = useMemo(
    () =>
      orderFormData &&
      orderDetails &&
      isFormReadyForSubmission(
        orderFlow === 'delegate',
        companyId,
        orderDetails,
        seedDeliverables,
        Boolean(orderFormData.pricingType && orderFormData.deliveryCurrency)
      ),
    /* eslint-disable react-hooks/exhaustive-deps */
    [seedDeliverables, orderDetails, orderFlow]
  );

  useEffect(() => {
    if (orderFormData) {
      orderFormData.companyId && setCompanyId(orderFormData.companyId);
      const order = structuredClone(orderFormData);
      if (order.pricingType && order?.deliveryType !== 'CASH') {
        delete order.pricingType;
      }
      changeOrder(order);
    }
  }, [orderFormData]);

  return (
    <OrderFormContainer>
      <Card
        noPadding
        isLoading={loading}
        header={<CardHeaderTitleWithLabel label={'CREATE ORDER'} title={'Order Details'} />}
        body={
          <Form
            schemaUrl={isDelegatedOrder ? orderDelegatedSchemaUrl : orderSchemaUrl}
            onBlur={(formData) => {
              setOrderFormData(formData as OrderFormProps);
            }}
            onSubmit={(val) => {
              handleOnSubmit(val);
            }}
            loading={loading || isSubmitting}
            initialValues={seedProductId && { productId: seedProductId }}
          >
            <Stack padding={2}>
              <Stack gap={2}>
                <ProductSelector
                  label="Product"
                  clearable
                  name="productId"
                  data-qa-id="orderIndex"
                  data-qa-options-id="orderIndex"
                  permissionFilter={
                    // If user is allowed to create delegated Order,
                    // we should use createDelegatedOrder action from product _actions object
                    isDelegatedOrder ? 'createDelegatedOrder' : 'createOrder'
                  }
                  resetOnChange={['companyId', 'userId', 'deliveryType', 'settlementType']}
                  disabled={Boolean(seedProductId)}
                  status={
                    isDelegatedOrder
                      ? [ProductStatus.ACTIVE, ProductStatus.IN_REVIEW]
                      : [ProductStatus.ACTIVE]
                  }
                  fullWidth
                />
                {orderFlow === 'delegate' && (
                  <>
                    <Select
                      data-qa-id="company"
                      data-qa-options-id="company"
                      name="companyId"
                      options={instrumentAPs}
                      disabled={loading || loadingAPs}
                      resetOnChange={['userId']}
                      capitalize
                    />
                    <Select
                      data-qa-id="user"
                      data-qa-options-id="user"
                      name="userId"
                      options={usersOptions}
                      disabled={!companyId || loading}
                      capitalize
                    />
                  </>
                )}
                <Select
                  data-qa-id="creationRedemption"
                  data-qa-options-id="creationRedemption"
                  name="type"
                  disabled={loading}
                />
                {!hideCustomBasketOrders &&
                  orderFlow === 'delegate' &&
                  orderDetails?.deliveries?.expected &&
                  orderDetails.deliveries.expected.length > 1 && (
                    <Select
                      data-qa-id="orderScope"
                      data-qa-options-id="orderScope"
                      name="orderScope"
                      disabled={loading}
                    />
                  )}
                <Input data-qa-id="creationUnits" name="numberOfUnits" disabled={loading} />
                <Typography variant="labelLargeStrong">Delivery</Typography>
                <Stack sx={{ background: '#F0F4F7' }} gap={2} padding={2} borderRadius={1}>
                  <Select
                    backgroundColor="white"
                    placeholder="Select"
                    data-qa-id="deliveryType"
                    data-qa-options-id="deliveryType"
                    name="deliveryType"
                    mapOptions={(options) =>
                      options.filter((option) => !seedProductId || option.value === ORDER_IN_KIND)
                    }
                    disabled={loading}
                    resetOnChange={['deliveryCurrency', 'pricingType', 'cashExecutionFeeBps']}
                  />
                  {orderFormData?.deliveryType === 'CASH' && (
                    <>
                      <Select
                        backgroundColor="white"
                        data-qa-id="navOptions"
                        data-qa-options-id="navOptions"
                        name="pricingType"
                        options={pricingTypeoptions}
                        disabled={loading}
                      />
                      <Select
                        backgroundColor="white"
                        placeholder="Select"
                        data-qa-id="deliveryCurrency"
                        data-qa-options-id="deliveryCurrency"
                        name="deliveryCurrency"
                        options={deliveryCurrencyOptions}
                        disabled={loading}
                        capitalize
                      />
                      {orderFlow === 'delegate' && (
                        <Input
                          data-qa-id="cashExecutionFeeBps"
                          name="cashExecutionFeeBps"
                          disabled={loading}
                        />
                      )}
                    </>
                  )}
                  {!isDelegatedOrder && orderDetails?.product && (
                    <CutoffInfo
                      deliveryType={orderDetails?.deliveryType}
                      product={orderDetails?.product}
                    />
                  )}
                </Stack>
                <Select
                  backgroundColor="white"
                  data-qa-id="settlementType"
                  data-qa-options-id="settlementType"
                  name="settlementType"
                  value={orderDetails?.product?.standardSettlement}
                  options={settlementTypeOptions}
                  disabled={loading}
                />
                {!isDelegatedOrder && orderDetails?.product && (
                  <CutoffInfo
                    deliveryType={orderDetails?.deliveryType}
                    product={orderDetails?.product}
                  />
                )}
              </Stack>
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Stack padding={2}>
              <Button
                fullWidth={true}
                disabled={!formSubmissionEnabled}
                size="large"
                type="submit"
                variant="primary"
              >
                {orderFlow === 'delegate' ? 'Delegate Order' : 'Place Order'}
                {isSubmitting && <CircularProgress size={24} />}
              </Button>
            </Stack>
          </Form>
        }
      />
    </OrderFormContainer>
  );
};

export default OrderForm;
