import { TableCell as MuiTableCell } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

interface CustomTableCellProps {
  align?: 'left' | 'center' | 'right';
  nowrap?: 'true' | 'false';
  withActions?: boolean;
  pointer?: boolean;
  minWidth?: string;
  maxWidth?: string;
  disabled?: Boolean;
  error?: boolean;
  sizeprop?: 'small' | 'medium' | 'large';
  hideOnSmallScreen?: boolean;
}

export const FULL_SPAN = 9999;

export const TableCell = MuiStyled(MuiTableCell)<CustomTableCellProps>(
  ({
    theme,
    nowrap = false.toString(),
    error,
    sizeprop = 'medium',
    withActions,
    minWidth,
    maxWidth,
    pointer,
    disabled,
    align,
    hideOnSmallScreen,
  }) => ({
    whiteSpace: nowrap === 'false' ? 'nowrap' : 'normal',
    color: error ? theme.palette.error.main : theme.palette.text.primary,
    minWidth: minWidth ?? 'unset',
    maxWidth: maxWidth ?? 'unset',
    textAlign: align || 'left',
    cursor: pointer ? 'pointer' : 'default',
    display: withActions ? 'flex' : 'table-cell',
    padding: `0 ${theme.spacers['spacing-16']}`,
    verticalAlign: 'middle',
    '&:hover': disabled
      ? {
          border: `1px solid ${theme.palette.blue[300]}`,
          background: '#F7F7F7',
          cursor: 'not-allowed',
        }
      : undefined,
    '&.MuiTableCell-root': {
      padding: '0 24px',
      lineHeight: 'unset',
      height: {
        small: '32px',
        medium: '48px',
        large: '68px',
      }[sizeprop],
    },
    '& > :first-child': align === 'right' ? { marginLeft: 'auto' } : undefined,
    '.MuiCircularProgress-svg': {
      padding: 0,
    },
    ...(hideOnSmallScreen && {
      '@media (max-width: 1600px)': {
        display: 'none',
      },
    }),
  })
);

export default TableCell;
