import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { Typography } from '@mui/material';
import { NavReconviliationInterface } from './ReconciliationCard';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { NAVDetailsWithProduct } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { red } from 'theme/colors/baseColors';

type ReconciliationTableRowProps = {
  navReconciliation: NavReconviliationInterface;
  editAction?: () => void;
  columns: TableHeaderColumn[];
  navReviewItem: NAVDetailsWithProduct;
};

const renderCell = (
  navReconciliation: NavReconviliationInterface,
  column: TableHeaderColumn,
  navReviewItem: NAVDetailsWithProduct
) => {
  switch (column.propName) {
    case 'total':
      return (
        <TableCell nowrap="true" key={'total'}>
          <Typography alignContent={'center'}>{navReconciliation.custody ?? '-'} </Typography>
        </TableCell>
      );
    case 'nav':
      return (
        <TableCell data-qa-id={`${navReconciliation.nav}`} key={'nav'}>
          <Typography>{navReconciliation.nav ?? '-'} </Typography>
        </TableCell>
      );
    case 'deferredOrders':
      return (
        <TableCell data-qa-id={`${navReconciliation.deferredOrders}`} key={'deferredOrders'}>
          <Typography>{navReconciliation.deferredOrders ?? '-'} </Typography>
        </TableCell>
      );
    case 'accruedUnrealizedFee':
      return (
        <TableCell
          data-qa-id={`${navReconciliation.accruedUnrealizedFee}`}
          key={'accruedUnrealizedFee'}
        >
          <Typography>{navReconciliation.accruedUnrealizedFee ?? '-'} </Typography>
        </TableCell>
      );
    case 'break':
      return (
        <TableCell data-qa-id={`${navReconciliation.break}`} key={'break'}>
          <Typography sx={{ color: navReviewItem?.isSignificantBreak ? red[70] : 'inherit' }}>
            {navReconciliation.break ?? '-'}{' '}
          </Typography>
        </TableCell>
      );
    default:
      return null;
  }
};

export const ReconciliationCardTableRow = ({
  navReconciliation,
  editAction,
  columns,
  navReviewItem,
}: ReconciliationTableRowProps) => {
  return (
    <TableRow
      data-qa-id={'navReconciliation-row'}
      key={'navReconciliation-row'}
      onClick={editAction}
    >
      {columns
        .filter((col) => !col.hideColumn)
        .map((col) => renderCell(navReconciliation, col, navReviewItem))}
    </TableRow>
  );
};
