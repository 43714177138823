import { NAVReviewItem, TransactionType } from 'utils/types/nav';
import { NAVCardRows } from './types';

export const filterTableRows = (navItem?: NAVReviewItem) => {
  const tableRows: NAVCardRows[] = [
    NAVCardRows.NET_ORDERS,
    NAVCardRows.STAKING_REWARDS,
    NAVCardRows.STAKING_FEES,
    NAVCardRows.MANAGEMENT_FEE,
    NAVCardRows.REBOOK_BALANCE_ADJUST,
    NAVCardRows.REBALANCE_TRADES,
  ];

  const hideStaking = !navItem?.product?.constituentAssets?.find(
    (ca) => navItem.ticker === ca.ticker && ca.isStaking === true
  );
  const hideRebookAdjustment = !navItem?.transactions?.find(
    (tr) => tr.type === TransactionType.REBOOK_BALANCE_ADJUST
  );

  return tableRows.filter((row) => {
    if (hideStaking && [NAVCardRows.STAKING_REWARDS, NAVCardRows.STAKING_FEES].includes(row))
      return false;
    if (hideRebookAdjustment && NAVCardRows.REBOOK_BALANCE_ADJUST === row) return false;
    if (row === NAVCardRows.REBALANCE_TRADES && navItem?.totals?.tradeValue === undefined)
      return false;
    return row;
  });
};
