import { NAVReviewItem, TransactionLedger } from 'utils/types/nav';
import { UserPermissions } from 'utils/types/permissions';
import { Instrument } from 'utils/types/product';

export type NavDifferenceRow = {
  name?: string;
  onyxValue?: number | string;
  accountantValue?: number | string;
  difference?: number | string;
  transactions?: TransactionLedger[];
  _id: string;
};

export type NavDetailsTransactions = {
  [key in NAVCardRows]: NavDifferenceRow;
};

export interface NAVDetailsWithProduct extends NAVReviewItem {
  product?: Instrument;
}

export interface NAVNotificationDataInterface {
  action?: () => void;
  actionText?: string;
  title: string;
  message: string;
  variant: string;
}

export interface NAVCardInterface {
  reviewNAVItemOldValue?: NAVReviewItem;
  navTransactions: NavDetailsTransactions;
  navReviewItem: NAVDetailsWithProduct;
  updateDeltaNavReviewItem: (item: NAVReviewItem) => void;
  userPermissions?: {
    [key in UserPermissions]: boolean;
  };
  refreshData: () => void;
}

export enum NAVCardRows {
  OPENING_BALANCE = 'Opening Balance',
  MANAGEMENT_FEE = 'Management Fees',
  NET_ORDERS = 'Net Orders',
  STAKING_FEES = 'Staking Fees',
  STAKING_REWARDS = 'Staking Rewards',
  REBALANCE_TRADES = 'Rebalance Trades',
  REBOOK_BALANCE_ADJUST = 'NAV Review Adjustment',
  CLOSING_BALANCE = 'Closing balance',
}

export type OverrideFields = 'overrideBalance' | 'overrideNetOrders' | 'overrideStakingRewards';

export class RebookDto {
  overrideBalance?: string;
  overrideNetOrders?: string;
  overrideStakingRewards?: string;
  overrideBalanceReason?: string;
  overrideNetOrdersReason?: string;
  overrideStakingRewardsReason?: string;
}
