import { Fragment, ReactNode } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactComponent as ChartArrow } from 'assets/chart-arrow.svg';
import IconBox from 'components/IconBox';

interface TableTitleWithActionsProps {
  icon?: ReactNode;
  title?: string | ReactNode;
  titleComponent?: ReactNode;
  actions?: ReactNode[];
  titleComponentWidth?: string;
  borderBottom?: boolean;
}

const TableTitleWithActions = ({
  icon,
  title,
  actions,
  titleComponent,
  titleComponentWidth,
  borderBottom = false,
}: TableTitleWithActionsProps) => {
  return (
    <Box
      paddingBottom={2}
      display={'flex'}
      flexWrap={'wrap'}
      gap={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      // @TODO useTheme add theme colors
      borderBottom={borderBottom ? '1.25px solid #98ABB833' : 'none'}
      className={'table-title-wrapper'}
      {...(titleComponentWidth && { width: titleComponentWidth })}
    >
      {title && (
        <Box display={'flex'} alignItems={'center'} gap={'8px'} minHeight={'40px'}>
          <IconBox>{icon || <ChartArrow />}</IconBox>
          {/* @TODO useTheme Add theme colors */}
          <Typography color="#3B4853" variant="subheadingSmall" width={'max-content'}>
            {title}
          </Typography>
        </Box>
      )}
      {titleComponent && (
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          minHeight={'40px'}
          {...(titleComponentWidth && { width: titleComponentWidth })}
        >
          <IconBox>
            <ChartArrow />
          </IconBox>
          {/* @TODO useTheme Add theme colors */}
          <Typography
            color="#3B4853"
            variant="subheadingSmall"
            width={titleComponentWidth || 'max-content'}
          >
            {titleComponent}
          </Typography>
        </Box>
      )}
      {actions?.map((action, index) => (
        <Fragment key={index}>{action}</Fragment>
      ))}
    </Box>
  );
};

export default TableTitleWithActions;
