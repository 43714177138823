import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactComponent as ChartArrow } from 'assets/chart-arrow.svg';
import IconBox from 'components/IconBox';

export default function ProductConfigHeader({
  title,
  icon = <ChartArrow />,
}: {
  title: string;
  icon?: React.ReactNode;
}) {
  return (
    <Box display={'flex'} alignItems={'center'} gap={1}>
      <IconBox>{icon}</IconBox>
      {/* @TODO useTheme Add theme colors */}
      <Typography color="#3B4853" variant="subheadingMedium">
        {title}
      </Typography>
    </Box>
  );
}
