import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { useState } from 'react';
import { useUserPermissions } from 'store/user/selectors';
import { renderZeroValueOrDash } from 'utils/formatting';
import { NavDifferenceRow } from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';

type NAVDetailsTableRowProps = {
  differenceItem: NavDifferenceRow;
  rowKey: string | number;
};

export const NAVDetailsTableRow = ({ differenceItem, rowKey }: NAVDetailsTableRowProps) => {
  const user = useUserPermissions();
  const [expanded, setExpanded] = useState(false);
  const onRowClick = () => {
    setExpanded(!expanded);
  };
  const navReviewDifferencesTableRow = (
    <TableRow data-qa-id={`${rowKey}-row`} key={differenceItem._id} onClick={onRowClick}>
      <TableCell data-qa-id="name">{rowKey}</TableCell>
      <TableCell data-qa-id="onyx-ledger-balance">
        {renderZeroValueOrDash(differenceItem?.onyxValue)}
      </TableCell>
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {renderZeroValueOrDash(differenceItem?.accountantValue)}
      </TableCell>
      <TableCell data-qa-id="onyx-difference">
        {renderZeroValueOrDash(differenceItem?.difference)}
      </TableCell>
    </TableRow>
  );

  const navReviewDifferencesTableRowFA = (
    <TableRow data-qa-id={`${rowKey}-row`} key={differenceItem._id} onClick={onRowClick}>
      <TableCell data-qa-id="name">{rowKey}</TableCell>
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {renderZeroValueOrDash(differenceItem?.accountantValue)}
      </TableCell>
    </TableRow>
  );

  return (
    <>{!user?.isFundAccount ? navReviewDifferencesTableRow : navReviewDifferencesTableRowFA}</>
  );
};
